import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import InformationColum from './components/InformationColum';
import InformationRepresentative from './components/InformationRepresentative';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from '../../styles/GerenciarScrows.styles';
import Button from '../../../../components/Button/Button';
import { CheckCircle, DeniCircle } from '../../../../assets/icon/index';
import Accordion from '../../../../components/Accordion/index';
import { approveScrowFetchAction } from '../../../../store/fetchActions/approveScrowActions';
import * as S from './ScrowsPendentesPj.styles';
import verifyArray from '../../../../utils/verifyArray';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';

const DetalhesPendendes = ({ open, close, info, deny, index, infoByCnpj }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isPending = index === 0;

  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.white);
  const [expanded, setExpanded] = useState('panel1');
  const [statusRep, setStatusRep] = useState([]);
  const [comite, setComite] = useState([]);
  const [radio, setRadio] = useState([]);

  const { handleSubmit: handleSubmitApproval, control: controlAproval } = useForm({});

  const { loadingScrow } = useSelector((state) => state.scrow);

  const submitApprovation = () => approveScrow();

  const approveScrow = () => {
    const param = { CNPJ_EMPRESA: infoByCnpj?.CPFCNPJ, USUARIOS_SCROWS: info?.REPRESENTANTES };

    dispatch(approveScrowFetchAction(param, close));
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const reset = () => {
    setStatusRep([]);
    setComite([]);
    setRadio([]);
    setExpanded('panel1');
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <Modal
      open={open}
      close={() => {
        close(), reset();
      }}
      title="Dados do Escrow"
      width={'96.222vw'}
    >
      {loadingScrow ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmitApproval(submitApprovation)}>
          <S.InfoContainer>
            <InformationColum title="Informações empresariais" data={infoByCnpj} />
          </S.InfoContainer>

          <S.TitleRepresentative>
            <SectionTitle>Detalhes Representantes</SectionTitle>
          </S.TitleRepresentative>

          {info?.REPRESENTANTES?.map((val, indx) => (
            <S.ContainerAccordion key={indx + '-AccordionsRepresentantes'}>
              <Accordion
                expanded={expanded}
                handleChange={handleChange}
                mycolor={theme.colors.black}
                backcolor={theme.colors.white}
                open={'panel' + (indx + 1)}
                title={
                  <S.TitleAccordion>
                    <h2>{'Representante ' + (indx + 1)}</h2>
                    {statusRep[indx]?.val && (
                      <S.ContainerStatus background={statusRep[indx]?.colorBack} color={statusRep[indx]?.colorText}>
                        {statusRep[indx]?.status}
                      </S.ContainerStatus>
                    )}
                  </S.TitleAccordion>
                }
              >
                <S.SectionsBody>
                  <InformationRepresentative data={val} />
                </S.SectionsBody>
              </Accordion>
            </S.ContainerAccordion>
          ))}

          {isPending ? (
            <S.Footer>
              <Button
                backgroundcolor={theme.colors.buttonNegaded}
                width="35.111vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonNegadedHover}
                hovercolor={theme.colors.buttonNegadedHoverText}
                mycolor={theme.colors.buttonNegadedText}
                onClick={deny}
                onMouseOver={() => setHoverColorDeni(theme.colors.white)}
                onMouseOut={() => setHoverColorDeni(theme.colors.white)}
              >
                <DeniCircle color={hoverColorDeni} />
                <span>Negar</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonApproved}
                width="35.111vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonApprovedHover}
                hovercolor={theme.colors.buttonApprovedHoverText}
                mycolor={theme.colors.buttonApprovedText}
                type="submit"
              >
                <CheckCircle />
                <span>Aprovar</span>
              </Button>
            </S.Footer>
          ) : null}
        </form>
      )}
    </Modal>
  );
};

export default DetalhesPendendes;
