import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Filter from '../../../../components/Filter/Filter';
import { FormControlLabel, Checkbox } from '@mui/material';
import * as S from './CreatGroup.styles';
import moment from 'moment';
import TextField from '../../../../components/TextField';
import DateInput from '../../../../components/DateInput';
import SelectOptions from '../../../../components/SelectOptions';
import Button from '../../../../components/Button/Button';
import { useTheme } from 'styled-components';
import { ImSearch } from 'react-icons/im';
import { createTopic, searchTopicSendMensagem } from '../../../../store/fetchActions/notification';

const status = [
  { id: '', nome: 'Todos' },
  { id: '1', nome: 'È PDV' },
  { id: '0', nome: 'Não PDV' },
];

const CreateGroup = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [select, setSelect] = useState(false);
  const [mensagemSearch, setMensagemSearch] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA: '',
    LIMIT: 2147483646,
    PAGE: 1,
    IS_PDV: '',
  });
  const [dateInicial, setDateInicial] = useState(null);

  const { handleSubmit, control } = useForm({});
  const { handleSubmit: groupSubmit, control: groupControl } = useForm({});

  const searchTopicP = () => {
    dispatch(
      searchTopicSendMensagem(filter, (res) => {
        const updatedOptions = res?.map((p) => {
          return {
            title: p.NOME + ' - ' + p.CPFCNPJ,
            CPFCNPJ: p.CPFCNPJ,
            NOME: p.NOME,
            PERFIL: p.NOME_COBAN,
          };
        });

        setItems(updatedOptions);
        setMensagemSearch(true);
      }),
    );
  };

  const changeCheck = (index, checked) => {
    if (select && !checked) setSelect(false);

    const itemRef = [...items];
    itemRef[index].selected = checked;
    setItems([...itemRef]);
  };

  const changeCheckAll = (checked) => {
    const itemRef = [...items];

    itemRef.map((_item, i) => {
      itemRef[i].selected = checked;
    });

    setSelect(checked);
    setItems([...itemRef]);
  };

  const saveGroup = (data) => {
    const selectGroup = items?.filter((e) => e.selected === true);

    const array = [];
    selectGroup?.map((val) => {
      array.push(val.CPFCNPJ);
      return array;
    });

    dispatch(
      createTopic(
        { TOPICO: { ID: null, TITULO: data.titulo, DESCRICAO: data.descricao }, USERS: array },
        props?.closeModal,
      ),
    );
  };

  const validateGroup = () => {
    const selectGroup = items.filter((e) => e.selected === true);
    return selectGroup?.length > 0 ? false : true;
  };

  const filterTopic = (data) => {
    data.DATA = dateInicial ?? '';
    setFilter({ ...data, LIMIT: 2147483646, PAGE: 1 });
    searchTopicP();
  };

  return (
    <Modal open={props?.openModal} close={props?.closeModal} width={'800vh'} title={'Criação de grupos'}>
      <Filter>
        <form onSubmit={handleSubmit(filterTopic)}>
          <S.FirstFilterLine>
            <TextField label="Título" name={'SEARCH'} width={'28.042vw'} margin={'0 0 0 1.369vw'} control={control} />
            <DateInput
              label="Data Nascimento"
              name={'DATA'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <SelectOptions
              label={'PDV'}
              width={'9.528vw'}
              name={'IS_PDV'}
              control={control}
              options={status}
              initialValue={''}
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </S.FirstFilterLine>
        </form>
      </Filter>
      <S.myHr />
      <form onSubmit={groupSubmit(saveGroup)}>
        <S.DescribeSection>
          <S.RowSection>
            <TextField
              width={'30vw'}
              label="Título"
              name={'titulo'}
              required
              control={groupControl}
              variant={'filled'}
            />
          </S.RowSection>
          <S.RowSection>
            <TextField
              width={'30vw'}
              label="Descrição"
              name={'descricao'}
              required
              control={groupControl}
              variant={'filled'}
            />
          </S.RowSection>
        </S.DescribeSection>
        <div>
          {items?.length > 0 ? (
            <>
              <S.CheckContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={select}
                      onChange={(_e, checked) => changeCheckAll(checked)}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.colors.darkModerateLimeGreen,
                        },
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                      }}
                    />
                  }
                  label="Todos"
                />
                <S.CheckGrid>
                  {items?.map((item, i) => (
                    <FormControlLabel
                      key={i + '-formLabel'}
                      control={
                        <Checkbox
                          key={item?.CPFCNPJ}
                          checked={item?.selected === true ? true : false}
                          onChange={(_e, checked) => changeCheck(i, checked)}
                          sx={{
                            '&.Mui-checked': {
                              color: theme.colors.darkModerateLimeGreen,
                            },
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                          }}
                        />
                      }
                      label={item?.title}
                    />
                  ))}
                </S.CheckGrid>
              </S.CheckContainer>
              <hr />
              <S.ConteinerBottom>
                <Button
                  backgroundcolor={theme.colors.buttonConfirm}
                  width="12.153vw"
                  margin={'0 1.389vw 0 0'}
                  hoverbgcolor={theme.colors.buttonConfirmHover}
                  hovercolor={theme.colors.buttonConfirmHoverText}
                  mycolor={theme.colors.buttonConfirmText}
                  type="submit"
                  disabled={validateGroup()}
                >
                  <span>Salvar</span>
                </Button>
                <Button
                  backgroundcolor={theme.colors.buttonBack}
                  width="12.153vw"
                  margin={'0 1.389vw 0 0'}
                  hoverbgcolor={theme.colors.buttonBackHover}
                  hovercolor={theme.colors.buttonBackHoverText}
                  mycolor={theme.colors.buttonBackText}
                  type="submit"
                  onClick={() => {
                    props?.closeModal();
                  }}
                >
                  <span>Cancelar</span>
                </Button>
              </S.ConteinerBottom>
            </>
          ) : (
            <S.CheckContainer>
              {mensagemSearch ? (
                <S.Menssagen>Não houve resultados nesta busca</S.Menssagen>
              ) : (
                <S.Menssagen>Faça sua busca de clientes</S.Menssagen>
              )}
            </S.CheckContainer>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default CreateGroup;
