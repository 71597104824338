import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as Styled from './InputMask.styles';
import InputMask from 'react-input-mask';
import { NoSpecialCharacter } from '../../utils/regex';

const Mask = ({
  name,
  value,
  control,
  width,
  label,
  mask,
  onChange,
  error,
  disabled,
  required,
  type,
  fullWidth,
  variant,
  color,
  backcolor,
  labelcolor,
  labelfocuscolor,
  borderfocuscolor,
  InputProps,
  onInput,
  onKeyPress,
  spacelabelinput,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <InputMask
            {...field}
            mask={mask}
            maskChar=""
            onChange={(e) => {
              onChange && onChange(NoSpecialCharacter(e.target.value));
            }}
            value={value}
            disabled={disabled}
          >
            {(inputProps) => (
              <Styled.Input
                width={width}
                error={error}
                defaultValue={value}
                required={required}
                InputProps={InputProps}
                inputProps={inputProps}
                type={type}
                disabled={disabled}
                fullWidth={fullWidth}
                label={label}
                variant={variant}
                color={color}
                backcolor={backcolor}
                labelcolor={labelcolor}
                labelfocuscolor={labelfocuscolor}
                borderfocuscolor={borderfocuscolor}
                onInput={onInput}
                onKeyPress={onKeyPress}
                spacelabelinput={spacelabelinput}
                {...inputProps}
              />
            )}
          </InputMask>
        </>
      )}
    />
  );
};

export default Mask;

Mask.propTypes = {
  variant: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  backcolor: PropTypes.string,
  labelcolor: PropTypes.string,
  labelfocuscolor: PropTypes.string,
  borderfocuscolor: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onKeyPress: PropTypes.func,
};
