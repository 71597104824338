import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingSendingDocuments: false,
  loadingDocumentTypes: false,
  documentTypes: [],
};

export const documentTypesLoading = createAction('DOCUMENT_TYPES_LOADING');
export const documentTypesSuccess = createAction('DOCUMENT_TYPES_SUCCESS');
export const documentTypesError = createAction('DOCUMENT_TYPES_ERROR');
export const sendingDocumentsLoading = createAction('SENDING_DOCUMENTS_LOADING');
export const sendingDocumentsSuccess = createAction('SENDING_DOCUMENTS_SUCCESS');
export const sendingDocumentsError = createAction('SENDING_DOCUMENTS_ERROR');

export default createReducer(INITIAL_STATE, {
  [documentTypesLoading.type]: (state) => {
    return {
      ...state,
      loadingDocumentTypes: true,
      documentTypes: [],
    };
  },
  [documentTypesSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDocumentTypes: false,
      documentTypes: payload?.tipos_documentos,
    };
  },
  [documentTypesError.type]: (state) => {
    return {
      ...state,
      loadingDocumentTypes: false,
      documentTypes: [],
    };
  },
  [sendingDocumentsLoading.type]: (state) => {
    return {
      ...state,
      loadingSendingDocuments: true,
    };
  },
  [sendingDocumentsSuccess.type]: (state) => {
    return {
      ...state,
      loadingSendingDocuments: false,
    };
  },
  [sendingDocumentsError.type]: (state) => {
    return {
      ...state,
      loadingSendingDocuments: false,
    };
  },
});
