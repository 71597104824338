import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixTransactions: [],
  errorPixTransactions: false,
  errorLoadingPixTransactions: false,
  errorMessagePixTransactions: '',
  loadingPixTransactions: false,
  paginationPixTransactions: { totalPages: 0 },
  detailsPixTransaction: {},
  loadingDetailsPixTransaction: false,
  messageDetailsPixTransaction: '',
  successDetailsPixTransaction: false,
};

export const pixTransactionsActionLoading = createAction('PIX_TRANSACTIONS_ACTION_LOADING');
export const pixTransactionsActionSuccess = createAction('PIX_TRANSACTIONS_ACTION_SUCCESS');
export const pixTransactionsActionError = createAction('PIX_TRANSACTIONS_ACTION_ERROR');

export const detailsPixTransactionActionLoading = createAction('DETAILS_PIX_TRANSACTION_ACTION_LOADING');
export const detailsPixTransactionActionSuccess = createAction('DETAILS_PIX_TRANSACTION_ACTION_SUCCESS');
export const detailsPixTransactionActionError = createAction('DETAILS_PIX_TRANSACTION_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixTransactionsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixTransactions: true,
      loadingDetailsPixTransaction: false,
      errorPixTransactions: false,
    };
  },
  [pixTransactionsActionSuccess.type]: (state, payload) => {
    return {
      ...state,
      loadingPixTransactions: false,
      loadingDetailsPixTransaction: false,
      errorPixTransactions: false,
      dataPixTransactions: payload?.payload?.data?.data,
      paginationPixTransactions: payload?.payload?.data?.pagination,
    };
  },
  [pixTransactionsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixTransactions: false,
      loadingDetailsPixTransaction: false,
      errorPixTransactions: true,
      errorMessagePixTransactions: payload?.mensagem,
      dataPixTransactions: [],
      paginationPixTransactions: {},
    };
  },
  [detailsPixTransactionActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixTransaction: true,
    };
  },
  [detailsPixTransactionActionSuccess.type]: (state, payload) => {
    const { data, mensagem } = payload?.payload?.data;

    return {
      ...state,
      loadingDetailsPixTransaction: false,
      detailsPixTransaction: data,
      messageDetailsPixTransaction: mensagem,
      successDetailsPixTransaction: true,
    };
  },
  [detailsPixTransactionActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixTransaction: false,
      detailsPixTransaction: [],
      messageDetailsPixTransaction: payload?.mensagem,
      successDetailsPixTransaction: false,
    };
  },
});
