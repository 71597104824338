import api from '../../utils/services/api';
import dayjs from 'dayjs';

import {
  pixTransactionsActionLoading,
  pixTransactionsActionSuccess,
  pixTransactionsActionError,
  detailsPixTransactionActionLoading,
  detailsPixTransactionActionSuccess,
  detailsPixTransactionActionError,
} from '../ducks/pixTransactions';
import { showAlert } from '../ducks/sweetAlert';
import { pendingPixsActionError, pendingPixsActionLoading, pendingPixsActionSuccess } from '../ducks/pendingPix';

export const pixTransactionsFetchAction = (filter = { LIMIT: 10, PAGE: 1 }) => {
  return async (dispatch) => {
    dispatch(pixTransactionsActionLoading());

    try {
      const response = await api.post(`pix/relatorio_admin`, filter);
      dispatch(pixTransactionsActionSuccess(response));
    } catch (error) {
      dispatch(pixTransactionsActionError(error?.response?.data));
    }
  };
};

export const pixTransactionDebitDetailsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(detailsPixTransactionActionLoading());

    try {
      const response = await api.post(`pix/detalhes_debito`, id);
      dispatch(detailsPixTransactionActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixTransactionActionError(error?.response?.data));
    }
  };
};

export const pixTransactionCreditDetailsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(detailsPixTransactionActionLoading());
    try {
      const response = await api.post(`pix/detalhes_credito`, id);
      dispatch(detailsPixTransactionActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixTransactionActionError(error?.response?.data));
    }
  };
};

export const pixTransactionAproveFetchAction = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(pixTransactionsActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(pixTransactionsFetchAction({ LIMIT: 10, PAGE: 1 }));
    };
    try {
      const response = await api.post(`/admin/pix/aprovar/pagamento`, params);
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Pix aprovado com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(pixTransactionsActionError());
      callback(error);

      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel aprovar o pix',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const pixTransactionNegadedFetchAction = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(pixTransactionsActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(pixTransactionsFetchAction({ LIMIT: 10, PAGE: 1 }));
    };
    try {
      const response = await api.post(`/admin/pix/recusar/pagamento`, params);
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Pix negado com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(pixTransactionsActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel negar o pix',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const pixTransactionAproveCelcoinFetchAction = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(pixTransactionsActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(pixTransactionsFetchAction({ LIMIT: 10, PAGE: 1 }));
    };
    try {
      const response = await api.post(`/admin/pix/aprovar/pagamento/status`, params);
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Pix aprovado com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(pixTransactionsActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel aprovar o pix',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const pixTransactionPendingPixFetchAction = () => {
  return async (dispatch) => {
    dispatch(pendingPixsActionLoading());
    try {
      const response = await api.get(`/pix/aguardando_aprovacao`);

      dispatch(pendingPixsActionSuccess(response.data));
    } catch (error) {
      dispatch(pendingPixsActionError(error?.response?.data));
    }
  };
};
