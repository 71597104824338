import React from 'react';
import * as Styled from './StatusContainer.style';
import PropTypes from 'prop-types';
export default function StatusContainerTed({ code, description }) {
  let background = '';
  let color = '';
  switch (code) {
    case '1':
      background = 'rgba(236, 114, 2, 0.1)';
      color = 'rgba(236, 114, 2, 1)';
      break;
    case '2':
      background = 'rgba(3, 145, 31, 0.1)';
      color = 'rgba(3, 145, 31, 1)';
      break;
    case '3':
      background = 'rgba(187, 0, 32, 0.1)';
      color = 'rgba(187, 0, 32, 1)';
      break;
    case '4':
      background = 'rgba(38, 36, 36, 0.1)';
      color = 'rgba(38, 36, 36, 1)';
      break;
    case '5':
      background = 'rgba(188, 139, 13, 0.1)';
      color = 'rgba(188, 139, 13, 1)';
      break;
    case '6':
      background = 'rgba(226, 163, 0, 0.1)';
      color = 'rgba(226, 163, 0, 1)';
      break;
    case '7':
      background = 'rgba(15, 82, 160, 0.1)';
      color = 'rgba(15, 82, 160, 1)';
      break;
    case '8':
      background = 'rgba(0, 146, 212, 0.1)';
      color = 'rgba(0, 146, 212, 1)';
      break;
    case '9':
      background = 'rgba(208, 112, 23, 0.1)';
      color = 'rgba(208, 112, 23, 1)';
      break;
    default:
      background = 'rgba(125, 125, 125, 0.15)';
      color = '#7D7D7D';
  }

  return (
    <div>
      <Styled.ContainerStatus background={background} color={color}>
        {description}
      </Styled.ContainerStatus>
    </div>
  );
}

StatusContainerTed.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
};
