import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import * as Styled from '../../PerfisUsuarios.styles';
import InputMask from '../../../../components/InputMask';
import TextField from '../../../../components/TextField';
import SelectOptions from '../../../../components/SelectOptions';
import { useForm } from 'react-hook-form';
import { editSystemUserFetchAction } from '../../../../store/fetchActions/systemUserActions';
import { showAlert } from '../../../../store/ducks/sweetAlert';
import { useState } from 'react';

const EditUser = ({ open, close, editUsuario, info }) => {
  const [fone, setFone] = useState();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, register } = useForm({});

  const callEditProfileAction = (data) => {
    data.SENHA === data.CONFIRMARSENHA
      ? dispatch(
          editSystemUserFetchAction(
            data.NOME,
            editUsuario.id,
            fone ?? editUsuario.FONECEL,
            data.EMAIL,
            editUsuario.CPFCNPJ,
            data.ID_PERFIL,
            close,
          ),
        )
      : dispatch(
          showAlert({
            title: 'Ops...',
            text: 'As senhas informadas não coincidem',
            icon: 'errorCobans',
          }),
        );
  };

  return (
    <Modal open={open} close={close} title="Editar Usuário">
      <form onSubmit={handleSubmit(callEditProfileAction)}>
        <Styled.SectionInputs>
          <InputMask
            label="CPF"
            name={'CPFCNPJCOBAN'}
            width={'29.306vw'}
            control={control}
            disabled={true}
            mask={'999.999.999-99'}
            value={editUsuario.CPFCNPJ}
            variant={'filled'}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <TextField
            control={control}
            label={'Nome'}
            disabled={false}
            width={'29.306vw'}
            name={'NOME'}
            variant={'filled'}
            required={true}
            value={editUsuario.name}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <TextField
            control={control}
            label={'E-mail'}
            disabled={false}
            width={'29.306vw'}
            name={'EMAIL'}
            variant={'filled'}
            type={'email'}
            required={true}
            value={editUsuario.EMAIL}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <InputMask
            label="Telefone"
            name={'FONECEL'}
            width={'29.306vw'}
            control={control}
            required={true}
            mask={'(99) 99999-9999'}
            value={fone ?? editUsuario.FONECEL}
            onChange={(e) => setFone(e)}
            variant={'filled'}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <SelectOptions
            control={control}
            options={info}
            name={'ID_PERFIL'}
            width={'29.306vw'}
            initialValue={1}
            required={true}
            variant={'filled'}
            label={'Nível Acesso'}
          />
        </Styled.SectionInputs>
        <Button
          backgroundcolor={theme.colors.buttonConfirm}
          width={'29.306vw'}
          height={'3.125vw'}
          margin={'1vw 0 0 0'}
          hoverbgcolor={theme.colors.buttonConfirmHover}
          hovercolor={theme.colors.buttonConfirmHoverText}
          mycolor={theme.colors.buttonConfirmText}
          type="submit"
        >
          <span>SALVAR</span>
        </Button>
      </form>
    </Modal>
  );
};

export default EditUser;
