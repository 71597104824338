import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationLoanDetail: { totalResult: 0 },
  loadingLoanDetail: false,
  messageLoanDetail: '',
  dataLoanDetail: [],
  errorLoanDetail: false,
};

export const loanDetailActionLoading = createAction('LOAN_DETAIL_ACTION_LOADING');
export const loanDetailActionSuccess = createAction('LOAN_DETAIL_ACTION_SUCCESS');
export const loanDetailActionError = createAction('LOAN_DETAIL_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [loanDetailActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingLoanDetail: true,
      errorLoanDetail: false,
    };
  },
  [loanDetailActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLoanDetail: false,
      errorLoanDetail: false,
      dataLoanDetail: payload,
    };
  },
  [loanDetailActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingLoanDetail: false,
      errorLoanDetail: true,
      messageLoanDetail: payload?.mensagem,
    };
  },
});
