import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { editPerfisFetchAction } from '../../../../store/fetchActions/perfisActions';
import * as Styled from '../../PerfisUsuarios.styles';
import { LoadingContainer } from '../../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@mui/material';
import InputFormatValue from '../../../../components/InputFormatValue';
import { editTaxesFetchAction } from '../../../../store/fetchActions/taxesActions';
import { cobansFetchAction } from '../../../../store/fetchActions/cobansActions';
import TextField from '../../../../components/TextField';

const EditTaxes = ({ open, close, id, profileName }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const { taxesData, loadingTaxes } = useSelector((state) => state.taxes);

  const callEditTaxesAction = (data) => {
    const taxas = {};
    Object.keys(data).forEach(function (key) {
       if (data[key] !== 0 && key !== 'NOME') {
         const removeDigits = data[key].toString().replace('R$\u00a0', '');
         taxas[key] = removeDigits;
       }
    });

    const edit = {
      ID_COBAN: id,
      NOME: data.NOME,
      TAXAS: taxas,
    };
    dispatch(editTaxesFetchAction(edit, close));
    dispatch(cobansFetchAction());
  };
  console.log(taxesData)
  return (
    <Modal open={open} close={close} title="Editar Taxas">
      {loadingTaxes ? (
        <LoadingContainer marginTop={'1vw'}>
          <CircularProgress size={'5vw'} color="inherit" />
        </LoadingContainer>
      ) : (
        <form onSubmit={handleSubmit(callEditTaxesAction)}>
         
          <Styled.ContainerCollumn>
          <div style={{ marginBottom: '1.5rem' }}>
           {profileName && <TextField control={control} label={'Nome do Perfil'} value={profileName}  disabled={false} width={'29.306vw'} name={'NOME'} required />}
          </div>  
            {taxesData?.map((tax) => {
              return (
                <Styled.SectionInputs key={tax.ID_TIPO_TAXA}>
                  <InputFormatValue
                    name={tax?.ID_TIPO_TAXA.toString()}
                    control={control}
                    label={tax?.DESCRICAO}
                    value={tax?.VALOR}
                    width={'29.306vw'}
                    formatType={tax.ID_TIPO_VALOR === 1 ? 'currency' : tax.ID_TIPO_VALOR === 2 ? 'percent' : undefined}
                    decimalPlaces={4}
                   
                  />
                </Styled.SectionInputs>
              );
            })}
          </Styled.ContainerCollumn>
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width={'29.306vw'}
            height={'3.125vw'}
            margin={'1vw 0'}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            type="submit"
          >
            <span>SALVAR</span>
          </Button>
        </form>
      )}
    </Modal>
  );
};

export default EditTaxes;
