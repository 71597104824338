import api from '../../utils/services/api';

import { saldoGeralActionError, saldoGeralActionLoading, saldoGeralActionSuccess } from '../ducks/saldoGeral';

export const getDataSaldoGeralFetchAction = (data) => {
  return async (dispatch) => {
    dispatch(saldoGeralActionLoading());
    try {
      const response = await api.get(`usuario/saldo-geral?datahora=${data}`);
      dispatch(saldoGeralActionSuccess(response.data));
    } catch (error) {
      dispatch(saldoGeralActionError(error?.response?.data));
    }
  };
};
