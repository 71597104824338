import { useState, useEffect } from 'react';
import api from '../services/api';

export function useIcon() {
  const [menu, setMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let jwt = localStorage.getItem('is_authenticate');

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get('/admin/usuario/listarMenu');
      setMenu(data.icon);
      setIsLoading(false);
    }

    if (jwt) fetchData();
  }, [jwt]);

  return { menu, isLoading };
}
