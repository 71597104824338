import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ImageElement.styles';
import { baseIMG } from '../../utils/services/api';
import Modal from '../Modal/Modal';

const ImageElement = ({ subtitle, image, maxWidth, maxHeight }) => {
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  const openModalImage = () => {
    setIsModalImageOpen(true);
  };
  const closeModalImage = () => {
    setIsModalImageOpen(false);
  };

  return (
    <Styled.ContainerImg>
      <>
        <Styled.Img
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          src={`${baseIMG}${image}`}
          onClick={() => openModalImage()}
        />
        <Modal open={isModalImageOpen} close={closeModalImage}>
          <>
            <h1>{subtitle}</h1>
            <img src={`${baseIMG}${image}`} />
          </>
        </Modal>
      </>
    </Styled.ContainerImg>
  );
};

export default ImageElement;

ImageElement.defaultProps = {
  image: '',
  subtitle: '',
  maxWidth: '20vw',
  maxHeight: '20vw',
};
ImageElement.propTypes = {
  subtitle: PropTypes.string,
  image: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
};
