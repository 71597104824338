import { configureStore } from '@reduxjs/toolkit';
import dailyMovementsReducer from './ducks/dailyMovements';
import pendingUsersReducer from './ducks/pendingUsers';
import pendingScrowsReducer from './ducks/pendingScrows';
import pendingPixsReducer from './ducks/pendingPix';
import pendingTedsReducer from './ducks/pendingTed';
import approvedScrowsReducer from './ducks/approvedScrows';
import deniedScrowsReducer from './ducks/deniedScrows';
import approvedUsersReducer from './ducks/approvedUsers';
import deniedUsersReducer from './ducks/deniedUsers';
import blockedUsersReducer from './ducks/blockedUsers';
import requestedUsersReducer from './ducks/requestedUsers';
import preapprovedUsersReducer from './ducks/preapprovedUsers';
import userReducer from './ducks/user';
import loginReducer from './ducks/login';
import perfisReducer from './ducks/perfis';
import approveUserReducer from './ducks/approveUser';
import denyUserReducer from './ducks/deniedUsers';
import sweetAlertReducer from './ducks/sweetAlert';
import retrievePasswordReducer from './ducks/retrievePassword';
import waitingBankUsersReducer from './ducks/waitingBankUsers';
import bankSlipsReducer from './ducks/bankSlips';
import reversedBillsReducer from './ducks/reversedBills';
import bankSlipDetailReducer from './ducks/bankSlipDetail';
import paymentsReducer from './ducks/payments';
import paymentDetailReducer from './ducks/paymentDetail';
import systemUserReducer from './ducks/systemUser';
import taxesReducer from './ducks/taxes';
import logsReducer from './ducks/logs';
import blockUserReducer from './ducks/blockUser';
import loanReducer from './ducks/loan';
import loanDetailReducer from './ducks/loanDetail';
import transfersReducer from './ducks/transfers';
import validatePasswordReducer from './ducks/validatePassword';
import listUsersReducer from './ducks/listUsers';
import transactionsReducer from './ducks/transactions';
import digitalServicesReducer from './ducks/digitalServices';
import cobansReducer from './ducks/cobans';
import reportsReducer from './ducks/reports';
import walletsReducer from './ducks/wallets';
import saldoAdminReducer from './ducks/saldoAdmin';
import saldoGeralReducer from './ducks/saldoGeral';
import userAccountsReducer from './ducks/userAccounts';
import accountReducer from './ducks/account';
import pixTransactionsReducer from './ducks/pixTransactions';
import pixKeysReducer from './ducks/pixKeys';
import sendingDocumentsReducer from './ducks/sendingDocuments';
import notificationReducer from './ducks/notification';
import representativesReducer from './ducks/representatives';
import tariffsReducer from './ducks/tariffs';
import limitPaymentsReducer from './ducks/limitPayments';
import EditUserReducer from './ducks/editUser';
import ocrServicesReducer from './ducks/ocrServices';
import faceMatchServicesReducer from './ducks/faceMatchServices';
import imageReducer from './ducks/image';
import scrowReducer from './ducks/scrow';
import spreadTypeReducer from './ducks/spreadType';
import spreadTransactionReducer from './ducks/spreadTransaction';
import spreadTransaction from './ducks/spreadTransaction';

export default configureStore({
  reducer: {
    dailyMovements: dailyMovementsReducer,
    pendingUsers: pendingUsersReducer,
    pendingScrows: pendingScrowsReducer,
    pendingTransactionsPixs: pendingPixsReducer,
    pendingTransactionsTeds: pendingTedsReducer,
    approvedScrows: approvedScrowsReducer,
    approvedUsers: approvedUsersReducer,
    deniedScrows: deniedScrowsReducer,
    deniedUsers: deniedUsersReducer,
    preapprovedUsers: preapprovedUsersReducer,
    requestedUsers: requestedUsersReducer,
    blockedUsers: blockedUsersReducer,
    bankSlips: bankSlipsReducer,
    reversedBills: reversedBillsReducer,
    bankSlipDetail: bankSlipDetailReducer,
    user: userReducer,
    scrow: scrowReducer,
    perfis: perfisReducer,
    approveUser: approveUserReducer,
    denyUser: denyUserReducer,
    sweetAlert: sweetAlertReducer,
    retrievePassword: retrievePasswordReducer,
    waitingBankUsers: waitingBankUsersReducer,
    systemUser: systemUserReducer,
    taxes: taxesReducer,
    login: loginReducer,
    payments: paymentsReducer,
    paymentDetail: paymentDetailReducer,
    logs: logsReducer,
    blockUser: blockUserReducer,
    loan: loanReducer,
    loanDetail: loanDetailReducer,
    transfers: transfersReducer,
    validatePassword: validatePasswordReducer,
    listUsers: listUsersReducer,
    saldoAdmin: saldoAdminReducer,
    saldoGeral: saldoGeralReducer,
    transactions: transactionsReducer,
    digitalServices: digitalServicesReducer,
    cobans: cobansReducer,
    reports: reportsReducer,
    wallets: walletsReducer,
    userAccounts: userAccountsReducer,
    account: accountReducer,
    pixTransactions: pixTransactionsReducer,
    pixKeys: pixKeysReducer,
    sendingDocuments: sendingDocumentsReducer,
    notification: notificationReducer,
    representatives: representativesReducer,
    tariffs: tariffsReducer,
    limitPayment: limitPaymentsReducer,
    editUser: EditUserReducer,
    faceMatch: faceMatchServicesReducer,
    ocr: ocrServicesReducer,
    image: imageReducer,
    spreadType: spreadTypeReducer,
    spreadTransaction: spreadTransactionReducer,
  },
});
