import styled from 'styled-components';
import { Button as ButtonMaterial } from '@material-ui/core/';

export const Button = styled(ButtonMaterial)`
  background-color: ${(props) => props.backgroundcolor || props.theme.colors.button};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-family: Inter;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.667vw;
  letter-spacing: 0.09vw;
  text-align: center;
  color: ${(props) => props.stylecolor};
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  margin: ${(props) => props.margin};

  &:hover {
    background-color: ${(props) => props.hoverbgcolor || props.theme.colors.button};
    color: ${(props) => props.hovercolor || props.theme.colors.buttonText};
  }
  &:disabled {
    color: ${(props) => props.theme.colors.white} !important;
  }
`;
