import api from '../../utils/services/api';

import {
  approveScrowActionError,
  approveScrowActionLoading,
  approveScrowActionSuccess,
} from '../ducks/approveScrow.js';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { pendingScrowsFetchAction } from './pendingScrowsActions';

export const approveScrowFetchAction = (param, callback, status = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(pendingScrowsFetchAction());
    };
    dispatch(approveScrowActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/admin/scrow/aprovar`, param);
      dispatch(approveScrowActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Escrow aprovado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
      status(true);
    } catch (error) {
      dispatch(approveScrowActionError(error?.response?.data));
      status(false);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};
