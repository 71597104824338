import React, { useState } from 'react';
import * as Styled from './InformationTed.styles';
import StatusContainerTed from '../../../components/StatusContainer/StatusContainerTed';
import { tedRejected, tedAproved, tedNegaded, tedDone, tedCancel } from '../../../store/fetchActions/transfersActions';
import { useDispatch } from 'react-redux';
import formatMoneyBRL from '../../../utils/functions/formatMoneyBRL';
import Accordion from '../../../components/Accordion';
import Table from '../../../components/Table/Table';
import Button from '../../../components/Button/Button';
import { CheckCircle, DeniCircle } from '../../../assets/icon';
import { useTheme } from 'styled-components';
import formatCpfCnpj from '../../../utils/functions/formatCPFCNPJ';
import { NoSpecialCharacter } from '../../../utils/regex';
import DropDown from '../../../components/DropDown/DropDown';

export default function Information({ title, data, color, bold, close }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('');
  const [disableButon, setDisableButon] = useState(false);
  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.buttonNegadedText);

  const dataTED = data.data;

  const deny = () => {
    dispatch(
      tedNegaded(
        {
          TRANSACAO: data.ID,
          CPFCNPJ_AGENTE: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
        },
        close(),
      ),
    );
  };

  const rejected = () => {
    dispatch(
      tedRejected(
        {
          TRANSACAO: data.ID,
          CPFCNPJ_AGENTE: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
        },
        close(),
      ),
    );
  };

  const aproved = () => {
    setDisableButon(true);
    dispatch(
      tedAproved(
        {
          TRANSACAO: data.ID,
          CPFCNPJ_AGENTE: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
        },
        close(),
      ),
    );
  };

  const done = () => {
    setDisableButon(true);
    dispatch(
      tedDone(
        {
          TRANSACAO: data.ID,
          CPFCNPJ_AGENTE: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
        },
        close(),
      ),
    );
  };

  const cancel = () => {
    setDisableButon(true);
    dispatch(
      tedCancel(
        {
          TRANSACAO: data.ID,
          CPFCNPJ_AGENTE: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
        },
        close(),
      ),
    );
  };

  const verifyAction = (action) => {
    if (action === 'cancel') {
      cancel();
      return;
    } else if (action === 'done') {
      done();
      return;
    }
  };

  const header = [
    { id: 'status', name: 'STATUS', align: 'center' },
    { id: 'descricao', name: 'DESCRIÇÃO', align: 'left' },
    {
      id: 'data e hora',
      name: 'DATA/HORA',
      align: 'left',
    },
  ];

  const body =
    dataTED?.HISTORICO &&
    dataTED?.HISTORICO?.map((transfer) => {
      return {
        data: [
          {
            info: transfer.STATUS_FINAL ? (
              <StatusContainerTed
                code={transfer.STATUSTRANSACAO}
                description={transfer.STATUS_FINAL}
              ></StatusContainerTed>
            ) : (
              '-----'
            ),
            align: 'center',
          },
          {
            info: transfer.RETORNO ?? '-----',
            align: 'left',
            width: '15rem',
          },
          {
            info: transfer.DATA || '-----',
            align: 'left',
          },
        ],
      };
    });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      {typeof data === 'string' ? (
        <Styled.ParagraphName>{data}</Styled.ParagraphName>
      ) : (
        <>
          <div>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Data/Hora'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.DATA + ' - ' + dataTED?.HORA ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'ID da Transferência'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.ID ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Tipo de Transferência'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.DESCRICAO ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Valor'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {'R$ ' + (formatMoneyBRL(dataTED?.VALOR) ?? '---')}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Taxa'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {'R$ ' + (formatMoneyBRL(dataTED?.SPREAD) ?? '---')}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Valor Total'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {'R$ ' + (formatMoneyBRL(dataTED?.VALORTOTAL) ?? '---')}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Status'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                <StatusContainerTed code={dataTED?.STATUS_ID} description={dataTED?.STATUS}></StatusContainerTed>
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Transação'}</Styled.ParagraphName>
              <Styled.ParagraphValue
                color={dataTED?.TIPOTRANSACAO != 'CRÉDITO' ? theme.colors.vividRed_2 : theme.colors.darklimeGreen}
                bold={bold}
              >
                {dataTED?.TIPOTRANSACAO ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>

            <Styled.TitleContainer role={'information-row'}>
              <Styled.TitleStyled>{'ORIGEM'}</Styled.TitleStyled>
            </Styled.TitleContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.NOME_ORIGEM ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'CPF/CNPJ'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {formatCpfCnpj(dataTED?.CPFCNPJ_ORIGEM) ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Agência/Conta'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {(dataTED?.AGENCIA_ORIGEM ?? '--') + ' - ' + (dataTED?.CONTA_ORIGEM ?? '----')}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Instituição'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.BANCO_ORIGEM ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>

            <Styled.TitleContainer role={'information-row'}>
              <Styled.TitleStyled>{'DESTINO'}</Styled.TitleStyled>
            </Styled.TitleContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.NOME_DESTINO ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'CPF/CNPJ'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {formatCpfCnpj(dataTED?.CPFCNPJ_DESTINO) ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Agência/Conta'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {(dataTED?.AGENCIA_DESTINO ?? '--') + ' - ' + (dataTED?.CONTA_DESTINO ?? '----')}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
            <Styled.RowContainer role={'information-row'}>
              <Styled.ParagraphName>{'Instituição'}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {dataTED?.BANCO_DESTINO ?? '---'}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
          </div>

          <Styled.SectionHistoric>
            <Accordion
              expanded={expanded}
              handleChange={handleChange}
              backcolor={theme.colors.veryDarkCyan_2}
              open={'panel1'}
              title={<h2>{'Histórico TED '}</h2>}
            >
              <Table header={header} body={body} />
            </Accordion>

            {dataTED?.STATUS_ID == '1' && (
              <Styled.ContainerButton>
                <Button
                  backgroundcolor={theme.colors.buttonNegaded}
                  width="20vw"
                  height="3.125vw"
                  hoverbgcolor={theme.colors.buttonNegadedHover}
                  hovercolor={theme.colors.buttonNegadedHoverText}
                  mycolor={theme.colors.buttonNegadedText}
                  onClick={() => {
                    deny();
                  }}
                  onMouseOver={() => setHoverColorDeni(theme.colors.buttonNegadedHoverText)}
                  onMouseOut={() => setHoverColorDeni(theme.colors.buttonNegadedText)}
                >
                  <DeniCircle color={hoverColorDeni} />
                  <span>Negar</span>
                </Button>
                <Button
                  backgroundcolor={theme.colors.buttonApproved}
                  width="20vw"
                  height="3.125vw"
                  hoverbgcolor={theme.colors.buttonApprovedHover}
                  hovercolor={theme.colors.buttonApprovedHoverText}
                  disable={disableButon}
                  mycolor={theme.colors.buttonApprovedText}
                  onClick={() => {
                    aproved();
                  }}
                  type="submit"
                >
                  <CheckCircle />
                  <span>Aprovar</span>
                </Button>
              </Styled.ContainerButton>
            )}

            {dataTED?.STATUS_ID == '6' && (
              <Styled.ContainerButton>
                <Button
                  backgroundcolor={theme.colors.buttonNegaded}
                  width="15vw"
                  height="3.125vw"
                  hoverbgcolor={theme.colors.buttonNegadedHover}
                  hovercolor={theme.colors.buttonNegadedHoverText}
                  mycolor={theme.colors.buttonNegadedText}
                  onClick={() => {
                    rejected();
                  }}
                  onMouseOver={() => setHoverColorDeni(theme.colors.buttonNegadedHoverText)}
                  onMouseOut={() => setHoverColorDeni(theme.colors.buttonNegadedText)}
                >
                  <DeniCircle color={hoverColorDeni} />
                  <span>Reprovar</span>
                </Button>
                <DropDown
                  openModalPassword={() => setExpanded(true)}
                  name="Ações"
                  options={[
                    { label: 'Cancelar', action: 'cancel' },
                    { label: 'Concluir', action: 'done' },
                  ]}
                  setNameSelectedItem={(action) => verifyAction(action)}
              />
              </Styled.ContainerButton>
            )}
          </Styled.SectionHistoric>
        </>
      )}
    </Styled.ContainerStyled>
  );
}
