import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loadingRepresentates: true,
};

export const editRepresentativesActionLoading = createAction('REPRESENTATIVES_ACTION_LOADING');
export const editRepresentativesActionSuccess = createAction('REPRESENTATIVES_ACTION_SUCCESS');
export const editRepresentativesActionError = createAction('REPRESENTATIVES_ACTION_ERROR');

export const activeRepresentativesActionLoading = createAction('REPRESENTATIVES_ACTION_LOADING');
export const activeRepresentativesActionSuccess = createAction('REPRESENTATIVES_ACTION_SUCCESS');
export const activeRepresentativesActionError = createAction('REPRESENTATIVES_ACTION_ERROR');

export const inativeRepresentativesActionLoading = createAction('REPRESENTATIVES_ACTION_LOADING');
export const inativeRepresentativesActionSuccess = createAction('REPRESENTATIVES_ACTION_SUCCESS');
export const inativeRepresentativesActionError = createAction('REPRESENTATIVES_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
    [editRepresentativesActionLoading.type]: (state, payload) => {
        return {
            ...state,
            loadingEditRepresentatives: true,
        };
    },
    [editRepresentativesActionSuccess.type]: (state, payload) => {
        return {
            ...state,
            loadingEditRepresentatives: false,
        };
    },
    [editRepresentativesActionError.type]: (state, payload) => {
        return {
            ...state,
            loadingEditRepresentatives: false,
            error: true,
        };
    },
    [activeRepresentativesActionLoading.type]: (state, payload) => {
        return {
            ...state,
            loadingActiveRepresentatives: true,
        };
    },
    [activeRepresentativesActionSuccess.type]: (state, payload) => {
        return {
            ...state,
            loadingActiveRepresentatives: false,
        };
    },
    [activeRepresentativesActionError.type]: (state, payload) => {
        return {
            ...state,
            loadingActiveRepresentatives: false,
            error: true,
        };
    },
    [inativeRepresentativesActionLoading.type]: (state, payload) => {
        return {
            ...state,
            loadingInativeRepresentatives: true,
        };
    },
    [inativeRepresentativesActionSuccess.type]: (state, payload) => {
        return {
            ...state,
            loadingInativeRepresentatives: false,
        };
    },
    [inativeRepresentativesActionError.type]: (state, payload) => {
        return {
            ...state,
            loadingInativeRepresentatives: false,
            error: true,
        };
    },
});