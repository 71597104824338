import { memo, useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import { approvedUsersFetchAction } from '../../../store/fetchActions/approvedUsersActions';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter/Filter';
import DatePicker from '../../../components/DateInput';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { userFetchAction } from '../../../store/fetchActions/usersActions';
import { perfisFetchAction, profilesUsersFetchAction } from '../../../store/fetchActions/perfisActions';
import SelectOptions from '../../../components/SelectOptions';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import { useForm } from 'react-hook-form';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { listTypeSignatoriesAction } from '../../../store/fetchActions/approveUserActions';
import TextField from '../../../components/TextField';
import moment from 'moment';
import { useTheme } from 'styled-components';
import Loading from '../../../components/Loading';
import { pagesOptions } from '../../../utils/constant';
import ModalAprovados from '../components/Modals/ClientesAprovados';

function UsuariosAprovados({ value, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const {
    approvedUsersData,
    paginationApprovedUsers,
    loadingApprovedUsers,
    errorApprovedUsers,
    errorMessageApprovedUsers,
  } = useSelector((state) => state.approvedUsers);

  useEffect(() => {
    dispatch(listTypeSignatoriesAction());
    dispatch(profilesUsersFetchAction());
    dispatch(approvedUsersFetchAction(filter));
  }, []);

  useCallFilter(filterData, setFilter, approvedUsersFetchAction, value, index);

  const { handleSubmit, control } = useForm({});

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'center',
    },
    {
      id: 'CPFCNPJ',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'perfil ',
      name: 'PERFIL',
      align: 'left',
    },
  ];

  const body = approvedUsersData?.map((user) => {
    return {
      data: [
        {
          info: user.DATA_INSERT,
          align: 'center',
        },
        {
          info: user.CPFCNPJ?.length === 11 ? formatCPF(user.CPFCNPJ) : formatCNPJ(user.CPFCNPJ),
          align: 'left',
        },
        {
          info: user.NOME,
          align: 'left',
        },
        {
          info: user.NOME_COBAN,
          align: 'left',
        },
      ],
      onClick: () => inspectUser(user.CPFCNPJ),
    };
  });

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial ?? '';
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  function inspectUser(id, contas) {
    dispatch(userFetchAction(id, 'willBeLoaded'));
    dispatch(perfisFetchAction());
    setIsModalInfoOpen(true);
  }

  useEffect(() => {
    dispatch(approvedUsersFetchAction(filter));
  }, [filter.PAGE]);
  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'38.042vw'} control={control} />
            <DatePicker
              label="Data Inicial"
              value={dateInicial}
              name={'DATA_INICIAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DatePicker
              label="Data Final"
              value={dateFinal}
              name={'DATA_FINAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
              margin={'0 1.389vw 0 0'}
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5 style={{ padding: '0 0.4vw 0 0' }}>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationApprovedUsers?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingApprovedUsers ? (
        <Loading />
      ) : (
        <Table
          error={errorApprovedUsers}
          hasPagination={true}
          filter={filter}
          pagination={paginationApprovedUsers}
          setFilter={setFilter}
          errorMessage={errorMessageApprovedUsers}
          filterTable={filterTable}
          header={header}
          body={body}
        />
      )}

      {isModalInfoOpen && <ModalAprovados open={isModalInfoOpen} close={() => setIsModalInfoOpen(false)} />}
    </div>
  );
}

export default memo(UsuariosAprovados);
