import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  unlockuserData: [],
  paginationUnlockUsern: { totalResult: 0 },
  errorUnlockUser: false,
  loadingUnlockUser: true,
  errorMessageUnlockUser: '',
};

export const unlockUserActionLoading = createAction('UNLOCK_USER_ACTION_LOADING');
export const unlockUserActionSuccess = createAction('UNLOCK_USER_ACTION_SUCCESS');
export const unlockUserActionError = createAction('UNLOCK_USER_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [unlockUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingUnlockUser: true,
      errorUnlockUser: false,
      unlockUserData: [],
      paginationUnlockUser: {},
    };
  },
  [unlockUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUnlockUser: false,
      errorUnlockUser: false,
      unlockUserData: payload.DATA,
      paginationUnlockUser: payload.PAGINATION,
    };
  },
  [unlockUserActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUnlockUser: false,
      errorUnlockUser: true,
      unlockUserData: [],
      paginationUnlockUser: {},
      errorMessageUnlockUser: payload?.mensagem,
    };
  },
});
