import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import { MdAttachFile } from 'react-icons/md';
import Modal from '../../../../components/Modal/Modal';
import SelectOptions from '../../../../components/SelectOptions/SelectInputUncontrolled';
import {
  Label,
  Input,
  DocumentField,
  ContainerButtons,
  ContainerSendDocumentField,
  ContainerUploadFiles,
} from '../../styles/SendDocuments.styles';
import Button from '../../../../components/Button/Button';
import { getDocumentTypes, sendNewDocuments } from '../../../../store/fetchActions/sendingDocumentsActions';
import convertFileToBase64 from '../../../../utils/functions/convertFileToBase64';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';

const SendDocuments = ({ openModal, closeModal, accept, conta }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { loadingDocumentTypes, loadingSendingDocuments, documentTypes } = useSelector(
    (state) => state?.sendingDocuments,
  );
  const { CPFCNPJ } = useSelector((state) => state?.user?.usersData);

  const [codeDocument, setCodeDocument] = useState('');
  const [selectedFile, setSelectedFile] = useState({});

  useEffect(() => {
    if (openModal) dispatch(getDocumentTypes());
    setCodeDocument('');
    setSelectedFile({
      file: {},
      name: 'Escolha o documento',
      selected: false,
    });
  }, [openModal]);

  const documentTypesOptions = documentTypes?.map((documentType) => {
    return {
      ID: documentType?.COD,
      NOME: documentType?.DESCRICAO,
    };
  });

  const onChangeSelectedFile = (event) => {
    setSelectedFile({
      ...selectedFile,
      name: event?.target?.files[0]?.name,
      file: event?.target?.files[0],
      selected: true,
    });
  };

  const envioConta = useMemo(() => {
    return conta?.CONTA?.split('-');
  }, [conta?.CONTA]);

  const sendFile = async () => {
    if (codeDocument && selectedFile?.selected) {
      const convertedFile = await convertFileToBase64(selectedFile?.file)?.then((result) => result);

      dispatch(
        sendNewDocuments(
          {
            CPFCNPJ_USUARIO: CPFCNPJ,
            CONTA: envioConta[0],
            DOCUMENTOS: [
              {
                IMAGEM: convertedFile,
                TIPO: codeDocument,
              },
            ],
          },
          closeModal,
          conta?.ID_CONTA,
        ),
      );

      return;
    }

    return;
  };

  return (
    <Modal
      open={openModal}
      close={closeModal}
      title="Enviar novo documento"
      width="40vw"
      roleName="sendDocuments"
      padding="16px 24px 0px"
    >
      {!loadingSendingDocuments ? (
        <>
          {loadingDocumentTypes ? (
            <Loading />
          ) : (
            <ContainerSendDocumentField>
              <Label>Selecione abaixo o tipo de documento que será enviado</Label>
              <SelectOptions
                options={documentTypesOptions}
                label={'Tipo de Documento'}
                width={'100%'}
                borderRadius={'4px'}
                value={codeDocument}
                onChange={(value) => setCodeDocument(value)}
                height="2vw"
                initialValue={0}
                initialName="Tipo de documento"
              />
            </ContainerSendDocumentField>
          )}
          <ContainerSendDocumentField>
            <Label>Escolha o arquivo do documento</Label>
            <ContainerUploadFiles>
              <DocumentField
                margin="normal"
                disabled
                value={selectedFile?.name}
                InputProps={
                  selectedFile?.selected && {
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdAttachFile size={'1.3vw'} />
                      </InputAdornment>
                    ),
                  }
                }
              />
              <label htmlFor="contained-button-file">
                <Input
                  accept={accept}
                  id="contained-button-file"
                  type="file"
                  onChange={(event) => onChangeSelectedFile(event)}
                />
                {codeDocument && (
                  <Button
                    width="13vw"
                    height="3.2vw"
                    backgroundcolor={theme.colors.buttonConfirm}
                    hoverbgcolor={theme.colors.buttonConfirmHover}
                    mycolor={theme.colors.buttonConfirmHoverText}
                    hovercolor={theme.colors.buttonConfirmText}
                    component="span"
                  >
                    <span>Fazer Upload</span>
                  </Button>
                )}
              </label>
              {!codeDocument && (
                <Button
                  width="13vw"
                  height="3.2vw"
                  backgroundcolor={theme.colors.buttonConfirm}
                  hoverbgcolor={theme.colors.buttonConfirmHover}
                  mycolor={theme.colors.buttonConfirmHoverText}
                  hovercolor={theme.colors.buttonConfirmText}
                  component="span"
                  cursor="default"
                >
                  <span>Fazer Upload</span>
                </Button>
              )}
            </ContainerUploadFiles>
          </ContainerSendDocumentField>

          <ContainerButtons>
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="100%"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              margin={'2.083vw 0 0 0'}
              className={'without-margin'}
              onClick={sendFile}
            >
              <span>Enviar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width="100%"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={closeModal}
              margin={'0.694vw 0 2.083vw 0'}
              outlined
            >
              <span>Cancelar</span>
            </Button>
          </ContainerButtons>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default SendDocuments;
