import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingBankSlipDetail: {},
  messageBankSlipDetail: '',
  dataBankSlipDetail: [],
  errorBankSlipDetail: false,
};

export const bankSlipDetailActionLoading = createAction('BANK_SLIP_DETAIL_SLIP_ACTION_LOADING');
export const bankSlipDetailActionSuccess = createAction('BANK_SLIP_DETAIL_SLIP_ACTION_SUCCESS');
export const bankSlipDetailActionError = createAction('BANK_SLIP_DETAIL_SLIP_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [bankSlipDetailActionLoading.type]: (state) => {
    return {
      ...state,
      loadingBankSlipDetail: true,
      errorBankSlipDetail: false,
      dataBankSlipDetail: [],
    };
  },
  [bankSlipDetailActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBankSlipDetail: false,
      errorBankSlipDetail: false,
      messageBankSlipDetail: payload?.mensagem,
      dataBankSlipDetail: payload.data,
    };
  },
  [bankSlipDetailActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBankSlipDetail: false,
      errorBankSlipDetail: true,
      messageBankSlipDetail: payload?.mensagem,
      dataBankSlipDetail: [],
    };
  },
});
