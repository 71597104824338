import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  approvedScrowsData: [],
  paginationApprovedScrows: { totalResult: 0 },
  errorMessageApprovedScrows: '',
};
export const approvedScrowsActionLoading = createAction('APPROVED_SCROWS_ACTION_LOADING');
export const approvedScrowsActionSuccess = createAction('APPROVED_SCROWS_ACTION_SUCCESS');
export const approvedScrowsActionError = createAction('APPROVED_SCROWS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [approvedScrowsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingApprovedScrows: true,
      errorApprovedScrows: false,
      approvedScrowsData: [],
      paginationApprovedScrows: {},
    };
  },
  [approvedScrowsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedScrows: false,
      errorApprovedScrows: false,
      approvedScrowsData: payload.data,
      paginationApprovedScrows: payload.pagination,
    };
  },
  [approvedScrowsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedScrows: false,
      errorApprovedScrows: true,
      errorMessageApprovedScrows: payload?.mensagem,
      approvedScrowsData: [],
      paginationApprovedScrows: {},
    };
  },
});
