import React from 'react';
import formatCNPJ from '../../../../../utils/functions/formatCNPJ';
import formatPhoneNumber from '../../../../../utils/functions/formatPhoneNumber';
import formatMoneyBRL from '../../../../../utils/functions/formatMoneyBRL';
import formatCEP from '../../../../../utils/functions/formatCEP';
import * as Styled from './InformationColum.styles';

export default function Information({ data }) {
  return (
    <Styled.ContainerStyled>
      <Styled.PrimaryContainer>
        <Styled.TitleStyled>{'Informações empresariais'}</Styled.TitleStyled>
        <div>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'Razão Social'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NOME || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'CNPJ'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCNPJ(data?.CPFCNPJ)}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Data de Abertura'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data.DATA_FUND_NASC || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'E-mail'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.EMAIL || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'Telefone'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatPhoneNumber(data?.FONECEL) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid10">
              <Styled.ParagraphName>{'Faturamento'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{'R$ ' + formatMoneyBRL(data?.RENDA_FATURAMENTO)}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid6">
              <Styled.ParagraphName>{'Código e descrição da natureza jurídica'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.JURIDICA}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid7">
              <Styled.ParagraphName>{'Situação cadastral'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.SIT_CADASTRO}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid8">
              <Styled.ParagraphName>{'Porte'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.PORTE}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid9">
              <Styled.ParagraphName>{'Código e descrição da atividade econômica principal'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.ATIVIDADE}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </div>
        <Styled.SecondContainer>
          <Styled.TitleStyled marginTop={'2.764vw'}>{'Endereço'}</Styled.TitleStyled>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'CEP'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCEP(data?.CEP) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'Logradouro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.ENDERECO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Número'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NUMERO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'Complemento'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.COMPLEMENTO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'Bairro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.BAIRRO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid10">
              <Styled.ParagraphName>{'Cidade'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.CIDADE || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid11">
              <Styled.ParagraphName>{'Estado'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.UF || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </Styled.SecondContainer>
      </Styled.PrimaryContainer>
    </Styled.ContainerStyled>
  );
}
