import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingSaldoAdmin: false,
  messageSaldoAdmin: '',
  dataSaldoAdmin: [],
  errorSaldoAdmin: false,
};

export const saldoAdminActionLoading = createAction('SALDO_ADMIN_ACTION_LOADING');
export const saldoAdminActionSuccess = createAction('SALDO_ADMIN_ACTION_SUCCESS');
export const saldoAdminActionError = createAction('SALDO_ADMIN_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [saldoAdminActionLoading.type]: (state) => {
    return {
      ...state,
      loadingSaldoAdmin: true,
      errorSaldoAdmin: false,
      dataSaldoAdmin: [],
    };
  },
  [saldoAdminActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSaldoAdmin: false,
      errorSaldoAdmin: false,
      dataSaldoAdmin: payload,
    };
  },
  [saldoAdminActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSaldoAdmin: false,
      errorSaldoAdmin: true,
      messageSaldoAdmin: payload?.mensagem,
      dataSaldoAdmin: [],
    };
  },
});
