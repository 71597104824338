import styled from 'styled-components';

export const ImgWrapper = styled.div`
  margin-top: 1.042vw;
  max-height: 75%;
  align-self: center;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ImgSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
