import Styled from 'styled-components';

export const ContainerDetailOcrFaceMatch = Styled.div`
  font-family: Inter;
  font-style: normal;

  & > h2,
  h4 {
    margin: 0.764vw 0;
    font-weight: 600;
    font-size: 1.389vw;
  }

  & > div {
    display: flex;
    flex-direction: column;

    & h4 {
      margin: 20px 0 0 20px;
      font-size: 18px;
    }

    & .card {
      border-radius: 0.8rem;
    }
  }

  .box-legal-detail-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;
    border-bottom: 1px solid ${(props) => props.theme.colors.darkGrayishBlue};
    flex-wrap: wrap;
  }

  .box-legal-detail-data-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    .MuiRadio-colorSecondary.Mui-checked {
      color: ${(props) => props.theme.colors.brown} !important;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 16px;
    padding: 10px;
    font-weight: bold;
  }

  .box-legal-detail-data > p {
    font-size: 14px;
  }

  .box-legal-detail-title-data {
    text-transform: uppercase;
    font-weight: bolder;
  }

  .box-legal-detail-actions {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 30px 20px 10px;
  }
`;

export const Title = Styled.h1`
  font-size: 16px;
  padding: 1.2vw 0 0.4vw 0;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const MsgErro = Styled.h6`
  margin: 1vw 0 ;
`;

export const ColorOcrMatch = Styled.p`
  color: ${(props) => props?.color} !important;
`;
