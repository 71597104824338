import Styled from 'styled-components';

export const Container = Styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SectionInput = Styled.div`
  flex: 0 0 33.333333%; 
  padding: 1vw 0 0 0;
`;

export const SectionButton = Styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2vw 0 0 0;
`;
