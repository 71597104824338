import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = { loadingEditUser: false };

export const editUserActionLoading = createAction('EDIT_USER_ACTION_LOADING');
export const editUserActionSuccess = createAction('EDIT_USER_ACTION_SUCCESS');
export const editUserActionError = createAction('EDIT_USER_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [editUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingEditUser: true,
      errorEditUser: false,
    };
  },
  [editUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditUser: false,
      errorEditUser: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [editUserActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingEditUser: false,
      errorEditUser: true,
      message: payload?.mensagem,
    };
  },
});
