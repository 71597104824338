/* eslint-disable no-undef */
import Axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const baseIMG = process.env.REACT_APP_BASE_IMG;

const api = Axios.create({ baseURL: process.env.REACT_APP_API });
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('is_authenticate');
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Application = 2;
  return config;
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.data.endpoint !== 'admin/refresh/session' && error.response.status === 401) {
        navigate('/login');
        localStorage.clear();
      }

      return Promise.reject(error);
    };

    return () => api.interceptors.response.use(resInterceptor, errInterceptor);
  }, [navigate]);
  return children;
};

export const baseURL = process.env.REACT_APP_API;
export default api;
export { AxiosInterceptor };
