import api from '../../utils/services/api';

import { bankSlipsActionError, bankSlipsActionLoading, bankSlipsActionSuccess } from '../ducks/bankSlips';

export const bankSlipsFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(bankSlipsActionLoading());
    try {
      const response = await api.post('boleto/adm/listar', filtro);
      dispatch(bankSlipsActionSuccess(response.data));
    } catch (error) {
      dispatch(bankSlipsActionError(error?.response?.data));
    }
  };
};
