import api from '../../utils/services/api';
import { showAlert } from '../ducks/sweetAlert';

import {
          creditTransactionLoading,
          creditTransactionSuccess,
          creditTransactionError,
          debitTransactionLoading,
          debitTransactionSuccess,
          debitTransactionError
} from '../ducks/spreadTransaction';

import { userBalanceFetchAction } from './usersActions';

export const performCreditTransaction = ( param, callback, CPFCNPJ_CLIENTE ) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(
        userBalanceFetchAction({
          ID_CONTA: param?.ID_CONTA,
          CPFCNPJ_CLIENTE,
          LIMIT: 10,
          PAGE: 1,
          ID_TIPO_VALOR: param?.ID_TIPO_VALOR,
          SPREAD: param?.SPREAD
        }),
      );
    };
    dispatch(creditTransactionLoading());
    try {
      const response = await api.post(`/transacao/credito-manutencao/adm`, param);
      dispatch(creditTransactionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Crédito realizado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(creditTransactionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
  };

  export const performDebitTransaction = (param, callback, CPFCNPJ_CLIENTE) => {
    return async (dispatch) => {
      const closeModals = () => {
        callback();
        dispatch(
          userBalanceFetchAction({
            ID_CONTA: param?.ID_CONTA,
            CPFCNPJ_CLIENTE,
            LIMIT: 10,
            PAGE: 1,
            ID_TIPO_VALOR: param?.ID_TIPO_VALOR,
            SPREAD: param?.SPREAD
          }),
        );
      };
      dispatch(debitTransactionLoading());
      try {
        const response = await api.post(`/transacao/debito-manutencao/adm`, param);
        dispatch(debitTransactionSuccess(response.data));
        dispatch(
          showAlert({
            title: 'Sucesso!',
            text: 'Débito realizado com sucesso!',
            icon: 'success',
            func: closeModals,
          }),
        );
      } catch (error) {
        dispatch(debitTransactionError(error?.response?.data));
        dispatch(
          showAlert({
            title: 'Ops...',
            text: error?.response?.data?.mensagem,
            icon: 'error',
            func: closeModals,
          }),
        );
      }
    };
  };