import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  waitingBankUsersData: [],
  paginationWaitingBankUsers: { totalResult: 0 },
  errorMessageWaitingBankUsers: '',
};

export const waitingBankUsersActionLoading = createAction('WAITING_BANK_USERS_ACTION_LOADING');
export const waitingBankUsersActionSuccess = createAction('WAITING_BANK_USERS_ACTION_SUCCESS');
export const waitingBankUsersActionError = createAction('WAITING_BANK_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [waitingBankUsersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingWaitingBankUsers: true,
      errorWaitingBankUsers: false,
      waitingBankUsersData: [],
      paginationWaitingBankUsers: {},
    };
  },
  [waitingBankUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingWaitingBankUsers: false,
      errorWaitingBankUsers: false,
      waitingBankUsersData: payload.DATA,
      paginationWaitingBankUsers: payload.PAGINATION,
    };
  },
  [waitingBankUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingWaitingBankUsers: false,
      errorWaitingBankUsers: true,
      errorMessageWaitingBankUsers: payload,
      waitingBankUsersData: [],
      paginationWaitingBankUsers: {},
    };
  },
});
