import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import ScrowsPendentes from './TabContent/ScrowsPendentes';
import Tabs from './components/Tabs/Tabs';
import ScrowsAprovados from './TabContent/ScrowsAprovados';
import ScrowsNegados from './TabContent/ScrowsNegados';

const labels = ['Pendentes', 'Aprovados', 'Negados'];

export default function GerenciarScrow() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Wrapper title="Gerenciar Escrows" margin="4.1vw 0">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" />
      {tabIndex == 0 && <ScrowsPendentes value={tabIndex} index={0} />}
      {tabIndex == 1 && <ScrowsAprovados value={tabIndex} index={1} />}
      {tabIndex == 2 && <ScrowsNegados value={tabIndex} index={2} />}
    </Wrapper>
  );
}
