import { useSelector } from 'react-redux';
import * as Styled from '../../styles/UsuariosAprovados.styles';
import { useEffect, useState, useMemo } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { imageAction } from '../../../../store/fetchActions/imageActions';
import { Skeleton } from '@mui/material';
import convertBase64 from '../../../../utils/functions/convertBase64';

export default function DocumentCollum() {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const [imageName, setImageName] = useState('');
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  const { detailAccountDocumentsData, detailAccountsData } = useSelector((state) => state.account);

  const changeImage = (newURL, name, index) => {
    setSelectedImage(index);
    setImageURL(newURL);
    setImageName(name);
  };

  useEffect(() => {
    detailAccountsData?.documentos?.length > 0 && setImageURL(detailAccountsData?.documentos[0]?.link);
  }, []);

  useMemo(() => {
    imageURL && dispatch(imageAction(imageURL));
  }, [imageURL]);

  const { typeImage, imgData, loadingImage } = useSelector((selector) => selector.image);

  const urlImg = useMemo(() => convertBase64(imgData, typeImage), [imgData]);

  return (
    <Styled.ContainerDoc>
      <Styled.TitleStyled>Documentos</Styled.TitleStyled>
      <Styled.DocContainer>
        {detailAccountsData?.documentos?.map((documento, index) => {
          return (
            <Styled.Taxa key={index}>
              <Styled.ImageButton
                index={index}
                selectedImage={selectedImage}
                onClick={() => {
                  changeImage(documento.CAMINHO_ARQUIVO, documento.CATEGORIA, index), setIsModalImageOpen(true);
                }}
                type="button"
              >
                {documento.CATEGORIA}
              </Styled.ImageButton>
            </Styled.Taxa>
          );
        })}
      </Styled.DocContainer>

      {isModalImageOpen && (
        <Modal
          open={isModalImageOpen}
          close={() => setIsModalImageOpen(false)}
          title={imageName}
          width={typeImage === 'application/pdf' && !loadingImage ? '1000px' : 'auto'}
        >
          <>
            {loadingImage ? (
              <Skeleton variant="rounded" width={'550px'} height={350} />
            ) : typeImage === 'application/pdf' ? (
              <object data={urlImg} type="application/pdf" width="100%" height="800px"></object>
            ) : (
              <img src={`data:${typeImage};base64,${imgData}`} style={{ width: '100%' }} />
            )}
          </>
        </Modal>
      )}
    </Styled.ContainerDoc>
  );
}
