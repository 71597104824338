import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationPendingPixs: { totalResult: 0 },
  errorMessagePendingPixs: '',
};

export const pendingPixsActionLoading = createAction('PENDING_PIX_ACTION_LOADING');
export const pendingPixsActionSuccess = createAction('PENDING_PIX_ACTION_SUCCESS');
export const pendingPixsActionError = createAction('PENDING_PIX_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pendingPixsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPendingPixs: true,
      errorPendingPixs: false,
      paginationPendingPixs: {},
    };
  },
  [pendingPixsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPendingPixs: false,
      errorPendingPixs: false,
      paginationPendingPixs: payload.data,
    };
  },
  [pendingPixsActionError.type]: (state) => {
    return {
      ...state,
      loadingPendingPixs: false,
      errorPendingPixs: true,
      paginationPendingPixs: {},
    };
  },
});
