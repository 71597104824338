import React from 'react';
import { ThemeProvider } from 'styled-components';
import GeneralTheme from './styles/GeneralTheme';
import { GlobalStyles } from './styles/global-styles';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './modules/Dashboard/Dashboard';
import GerenciarClientes from './modules/GerenciarClientes/GerenciarClientes';
import GerenciarContas from './modules/GerenciarContas/GerenciarContas';
import Logs from './modules/Logs/Logs';
import Transferencias from './modules/Transferencias/Transferencias';
import GerenciarBoletos from './modules/TransacoesBoletos/GerenciarBoletos';
import TransferenciaTed from './modules/TransferenciaTed/TransferenciaTed';
import Login from './modules/Login/Login';
import Pagamentos from './modules/Pagamentos/Pagamentos';
import PerfisUsuarios from './modules/PerfisUsuarios/PerfisUsuarios';
import Sidebar from './components/Sidebar';
import SweetAlert from './components/SweetAlert/SweetAlert';
import RequireAuth from './RequireAuth';
import NotFound from './modules/NotFound/NotFound';
import ServicosDigitais from './modules/ServicosDigitais/ServicosDigitais';
import Reports from './modules/Reports/Reports';
import Pix from './modules/Pix/Pix';
import Notificacao from './modules/Notificacao/Notificacao';
import { AxiosInterceptor } from './utils/services/api';
import RelatorioAntifraude from './modules/Antifraude/RelatorioAntifraude';
import GerenciarScrow from './modules/GerenciarScrow/GerenciarScrow';
import {Helmet} from "react-helmet";

const rotas = [
  {
    path: '*',
    element: (
      <RequireAuth>
        <NotFound />
      </RequireAuth>
    ),
  },
  { path: '/login', element: <Login /> },
  {
    path: '/',
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: '/gerenciador',
    element: (
      <RequireAuth>
        <PerfisUsuarios />
      </RequireAuth>
    ),
  },
  {
    path: '/gerenciar-clientes',
    element: (
      <RequireAuth>
        <GerenciarClientes />
      </RequireAuth>
    ),
  },
  {
    path: '/gerenciar-contas',
    element: (
      <RequireAuth>
        <GerenciarContas />
      </RequireAuth>
    ),
  },
  {
    path: '/antifraude',
    element: (
      <RequireAuth>
        <RelatorioAntifraude />
      </RequireAuth>
    ),
  },
  {
    path: '/transferencias',
    element: (
      <RequireAuth>
        <Transferencias />
      </RequireAuth>
    ),
  },
  {
    path: '/transferencia-ted',
    element: (
      <RequireAuth>
        <TransferenciaTed />
      </RequireAuth>
    ),
  },
  {
    path: '/pix',
    element: (
      <RequireAuth>
        <Pix />
      </RequireAuth>
    ),
  },
  {
    path: '/pagamentos',
    element: (
      <RequireAuth>
        <Pagamentos />
      </RequireAuth>
    ),
  },
  {
    path: '/boletos',
    element: (
      <RequireAuth>
        <GerenciarBoletos />
      </RequireAuth>
    ),
  },
  {
    path: '/recarga',
    element: (
      <RequireAuth>
        <ServicosDigitais />
      </RequireAuth>
    ),
  },
  {
    path: '/relatorios',
    element: (
      <RequireAuth>
        <Reports />
      </RequireAuth>
    ),
  },
  {
    path: '/logs',
    element: (
      <RequireAuth>
        <Logs />
      </RequireAuth>
    ),
  },
  {
    path: '/notificacao',
    element: (
      <RequireAuth>
        <Notificacao />
      </RequireAuth>
    ),
  },
  {
    path: '/gerenciar-scrows',
    element: (
      <RequireAuth>
        <GerenciarScrow />
      </RequireAuth>
    ),
  },
];

const App = () => {
  const name = process.env.REACT_APP_NAME ?? 'Hbi';
  const title = `${name} - Admin`;
  return (
    <>
    <Helmet
      title={title}
      link={[{ rel: 'icon', href: `/${name}/favicon.ico` }]} 
    />
    <ThemeProvider theme={GeneralTheme?.Default}>
      <GlobalStyles />
      <AxiosInterceptor>
        <Sidebar />
        <SweetAlert />
        <Routes>
          {rotas.map((val) => (
            <Route path={val.path} element={val.element} />
          ))}
        </Routes>
      </AxiosInterceptor>
    </ThemeProvider>
  </>
  );
};

export default App;
