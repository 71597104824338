import { createAction, createReducer } from '@reduxjs/toolkit';

// Action Types
export const typesFetchLoading = createAction('TYPES_FETCH_LOADING');
export const typesFetchSuccess = createAction('TYPES_FETCH_SUCCESS');
export const typesFetchError = createAction('TYPES_FETCH_ERROR');

// Initial State
const INITIAL_STATE = {
    loading: false,
    error: null,
    typesData: [],
  };

// Reducer
export default createReducer(INITIAL_STATE, {
    [typesFetchLoading.type]: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    [typesFetchSuccess.type]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: null,
        typesData: payload.tipo_taxas, // Ensure that typesData is updated correctly
      };
    },
    [typesFetchError.type]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload,
        typesData: [], 
      };
    },
  });