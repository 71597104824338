import Styled from 'styled-components';

export const DescribeSection = Styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

export const RowSection = Styled.div`
  width: 49%;
  padding-right: 1rem;

  .form-control {
    color: ${(props) => props.theme.colors.black} !important;
  }
`;

export const ConteinerBottom = Styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;
`;

export const CheckContainer = Styled.div`
  display: flex; 
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const CheckGrid = Styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 30px;
  overflow-y: scroll;
  height: 280px;
`;
