import api from '../../utils/services/api';

import {
  retrievePasswordActionError,
  retrievePasswordActionLoading,
  retrievePasswordActionSuccess,
} from '../ducks/retrievePassword';
import { hideAlert, showAlert } from '../ducks/sweetAlert';

export const retrievePasswordFetchAction = (param) => {
  return async (dispatch) => {
    dispatch(retrievePasswordActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/usuario/liberar/recuperacao-senha`, param);
      dispatch(retrievePasswordActionSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
        }),
      );
    } catch (error) {
      dispatch(retrievePasswordActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};
