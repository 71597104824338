import api from '../../utils/services/api';

import {
  approvedScrowsActionError,
  approvedScrowsActionLoading,
  approvedScrowsActionSuccess,
} from '../ducks/approvedScrows';

export const approvedScrowsFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  return async (dispatch) => {
    dispatch(approvedScrowsActionLoading());
    try {
      const response = await api.post(`admin/scrow/listar/aprovados`, filtro);
      dispatch(approvedScrowsActionSuccess(response.data));
    } catch (error) {
      dispatch(approvedScrowsActionError(error?.response?.data));
    }
  };
};
