import React, { useEffect } from 'react';
import formatCPF from '../../../../../utils/functions/formatCPF';
import formatPhoneNumber from '../../../../../utils/functions/formatPhoneNumber';
import formatCEP from '../../../../../utils/functions/formatCEP';
import formatDateHora from '../../../../../utils/functions/formatDateHora';
import * as Styled from './InformationColum.styles';
import { listTypeSignatoriesAction } from '../../../../../store/fetchActions/approveUserActions';
import { useDispatch, useSelector } from 'react-redux';

export default function Information({ data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listTypeSignatoriesAction());
  }, []);

  const { listSignatories } = useSelector((state) => state.approveUser);

  const nameSignatories = (val) => {
    const listS = listSignatories?.filter((e) => e.ID === val);
    return listS?.length > 0 && listS[0]?.DESCRICAO;
  };

  return (
    <Styled.ContainerStyled>
      <Styled.PrimaryContainer>
        <div>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NOME || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'CPF'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCPF(data?.CPFCNPJ)}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Data de Nascimento'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatDateHora(data?.NASCIMENTO || '-----')}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'Nome da mãe'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NOME_MAE || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'E-mail'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.EMAIL || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid10">
              <Styled.ParagraphName>{'Celular'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatPhoneNumber(data?.CELULAR) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid6">
              <Styled.ParagraphName>{'Tipo do representante'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.TIPO_REPRESENTANTE}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid7">
              <Styled.ParagraphName>{'Signatário'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{nameSignatories(data?.SIGNATARIO) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </div>
        <Styled.SecondContainer>
          <Styled.TitleStyled marginTop={'2.764vw'}>{'Endereço'}</Styled.TitleStyled>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'CEP'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCEP(data?.CEP) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'Logradouro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.ENDERECO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Número'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NUMENDERECO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'Complemento'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.COMPLEMENTO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'Bairro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.BAIRRO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid10">
              <Styled.ParagraphName>{'Cidade'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.CIDADE || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid11">
              <Styled.ParagraphName>{'Estado'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.UF || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </Styled.SecondContainer>
      </Styled.PrimaryContainer>
    </Styled.ContainerStyled>
  );
}
