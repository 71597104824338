import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paymentDetailData: [],
  errorPaymentDetail: false,
  messageError: '',
  loadingPaymentDetail: true,
  paginationPaymentDetail: { totalResult: 0 },
};

export const paymentDetailActionLoading = createAction('PAYMENT_DETAIL_ACTION_LOADING');
export const paymentDetailActionSuccess = createAction('PAYMENT_DETAIL_ACTION_SUCCESS');
export const paymentDetailActionError = createAction('PAYMENT_DETAIL_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [paymentDetailActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingPaymentDetail: true,
      errorPaymentDetail: false,
      paginationPaymentDetail: {},
    };
  },
  [paymentDetailActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPaymentDetail: false,
      errorPaymentDetail: false,
      paymentDetailData: payload.DATA,
      paginationPaymentDetail: payload.pagination,
    };
  },
  [paymentDetailActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPaymentDetail: false,
      errorPaymentDetail: true,
      messageError: payload?.mensagem,
      paymentDetailData: [],
      paginationPaymentDetail: {},
    };
  },
});
