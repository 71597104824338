import styled from 'styled-components';
export const SmallerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4,
  h5 {
    margin: 0.5vw;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  h3 {
    margin: 1.5vw 0 0.5vw 0;
    font-size: 18px;
  }
  h4 {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3vw 0;
  width: 28.572vw;
`;
