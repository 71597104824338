import React from 'react';
import formatCNPJ from '../../../../../utils/functions/formatCNPJ';
import formatPhoneNumber from '../../../../../utils/functions/formatPhoneNumber';
import formatMoneyBRL from '../../../../../utils/functions/formatMoneyBRL';
import formatCEP from '../../../../../utils/functions/formatCEP';
import * as Styled from './InformationColum.styles';

export default function Information({ data }) {
  return (
    <Styled.ContainerStyled>
      <Styled.PrimaryContainer>
        <Styled.TitleStyled>{'Informações empresariais'}</Styled.TitleStyled>
        <div>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'Razão Social'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NOME || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'CNPJ'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCNPJ(data?.CPFCNPJ)}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Data de Abertura'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.DATA_FUND_NASC || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'E-mail'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.EMAIL || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'Telefone'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatPhoneNumber(data?.FONECEL) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </div>
      </Styled.PrimaryContainer>
    </Styled.ContainerStyled>
  );
}
