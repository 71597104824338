import api from '../../utils/services/api';

import { denyUserActionError, denyUserActionLoading, denyUserActionSuccess } from '../ducks/denyUser';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { pendingUsersFetchAction } from './pendingUsersActions';

export const denyUserFetchAction = (param, callback) => {
  return async (dispatch) => {
    dispatch(denyUserActionLoading());

    const closeModals = () => {
      callback();
      dispatch(pendingUsersFetchAction());
    };
    dispatch(showAlert({}));
    try {
      const response = await api.post(`/usuario/recusar`, param);
      dispatch(denyUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Usuário negado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(denyUserActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
