import styled from 'styled-components';
import LoginImage from '../../assets/Images/Hbi/LoginImage.png';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
`;

export const BackgroundImage = styled.div`
  background-image: url(${LoginImage});
  min-height: 100vh;
  min-width: 40vw;
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
`;

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: ${(props) => props.theme.colors.veryDark_3}; */
  background-color: #f9fafb;

  input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.lightGray} inset;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root.MuiTextField-root {
    background-color: ${(props) => props.theme.colors.lightGray} !important;
  }
`;

export const TokenModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => props.width || 'auto'};
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }

  .imageLogo {
    width: 16.561458vw;
    height: auto;
  }
`;

export const LoginInfo = styled.div`
  width: 31.25vw;
  display: flex;
  height: 5.347vw;
  justify-content: space-evenly;
  flex-direction: column;
  color: ${(props) => props.theme.colors.black};
  .MuiTypography-h4 {
    font-weight: 600;
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  }
`;

export const LoginInfoToken = styled.div`
  width: 31.25vw;
  display: flex;
  height: 5.347vw;
  justify-content: space-evenly;
  flex-direction: column;
  .MuiTypography-h4 {
    font-weight: 600;
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  }
`;

export const ResendLink = styled.b`
  cursor: pointer;
  padding: 0 5px;
  border-radius: 5px;
`;

export const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
    border: ${(props) => '1px solid ' + props.theme.colors.brown};
    border-radius: 4px;
    box-shadow: none;
    margin-bottom: 0.8rem;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: ${(props) => '1px solid ' + props.theme.colors.black};
  }
`;

export const SectionInputs = styled.div`
  margin-top: 1vw;
`;

export const StyledRecaptcha = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1vw;
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 50%;
    height: auto;
  }
`;
