import api from '../../utils/services/api';

import {
  pendingScrowsActionError,
  pendingScrowsActionSuccess,
  pendingScrowsActionLoading,
} from '../ducks/pendingScrows';

export const pendingScrowsFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pendingScrowsActionLoading());
    try {
      const response = await api.post(`/admin/scrow/listar/pendentes`, filtro);
      dispatch(pendingScrowsActionSuccess(response.data));
    } catch (error) {
      dispatch(pendingScrowsActionError(error?.response?.data));
    }
  };
};
