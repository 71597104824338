import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationListUsers: { totalResult: 0 },
  loadingListUsers: false,
  messageListUsers: '',
  dataListUsers: [],
  dataListNewApprovedUsers: [],
  dataListNewPendingUsers: [],
  errorListUsers: false,
  loadingListNewUsersTable: true,
  errorListNewUsersTable: false,
  messageListNewUsersTable: '',
  dataListNewUsersTable: [],
};

export const listNewUsersActionLoading = createAction('LIST_NEW_USERS_ACTION_LOADING');
export const listNewUsersActionSuccess = createAction('LIST_NEW_USERS_ACTION_SUCCESS');
export const listNewUsersActionError = createAction('LIST_NEW_USERS_ACTION_ERROR');
export const listNewUsersTableActionLoading = createAction('LIST_NEW_USERS_TABLE_ACTION_LOADING');
export const listNewUsersTableActionSuccess = createAction('LIST_NEW_USERS_TABLE_ACTION_SUCCESS');
export const listNewUsersTableActionError = createAction('LIST_NEW_USERS_TABLE_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [listNewUsersActionLoading.type]: (state) => {
    return {
      ...state,
      loadingListNewUsers: true,
      errorListNewUsers: false,
      paginationListNewUsers: {},
      dataListNewUsers: [],
    };
  },
  [listNewUsersActionSuccess.type]: (state, { payload }) => {
    const { aprovados, pendentes } = payload;

    return {
      ...state,
      loadingListNewUsers: false,
      errorListNewUsers: false,
      dataListNewUsers: payload.retorno,
      dataListNewApprovedUsers: Object.keys(aprovados).map((user) => aprovados[user]),
      dataListNewPendingUsers: Object.keys(pendentes).map((user) => pendentes[user]),
    };
  },
  [listNewUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListNewUsers: false,
      errorListNewUsers: true,
      messageListNewUsers: payload?.mensagem,
      paginationListNewUsers: {},
      dataListNewUsers: [],
    };
  },
  [listNewUsersTableActionLoading.type]: (state) => {
    return {
      ...state,
      loadingListNewUsersTable: true,
      errorListNewUsersTable: false,
      dataListNewUsersTable: [],
    };
  },
  [listNewUsersTableActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListNewUsersTable: false,
      errorListNewUsersTable: false,
      dataListNewUsersTable: payload,
    };
  },
  [listNewUsersTableActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListNewUsersTable: false,
      errorListNewUsersTable: true,
      messageListNewUsersTable: payload?.mensagem,
      dataListNewUsersTable: [],
    };
  },
});
