const removeSpecialChars = (arrayChars, data) => {
  var newData = data;

  for (let i = 0; i < arrayChars.length; i++) {
    newData = newData.replaceAll(arrayChars[i], '');
  }

  return newData;
};

export default removeSpecialChars;
