import Styled from 'styled-components';

export const LoadingContainerCard = Styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 90px;
  color: ${(props) => props.color};
`;

export const CardInfo = Styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 1.5rem;
  border-radius: 10px;
`;

export const SectionTitle = Styled.span`
  margin: 0.764vw 0 0.4vw 0;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const Form = Styled.form`
  display:flex;
  justify-content: space-around;
  padding: 0 0 2rem 0;
`;

export const ContainerRadioGroup = Styled.div`
  margin: 1.4vw 0 0 0;
`;

export const ContainerOptions = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataValidation = Styled.div`
  padding: 0 0 1.5rem 0;
  display: flex;
  justify-content: space-between;
`;

export const ContainerFaceMatch = Styled.div`
  width: 45%;
`;

export const ContainerOCR = Styled.div`
  width: 53%;
`;

export const Flex = Styled.div`
  display: flex;
`;

export const ConteinerSearch = Styled.div`
  padding-right: 2vw;
`;

export const SpanButtonTitle = Styled.span`
  font-size: 12px;
`;

export const ConteinerResult = Styled.div`
  width: 100%;
`;

export const SectionLabel = Styled.div`
  display: flex;
  align-items: center;
`;

export const StyleH3 = Styled.h3`
  padding-right: 0.8vw;
`;

export const CompareDocument = Styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SectionResult = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ResultOCR = Styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ColorMatch = Styled.h4`
  color: ${(props) => props.color};
`;

export const ColorProbability = Styled.h4`
  color: ${(props) => props.color};
`;

export const ContainerResultOcrDetail = Styled.div`
  color = ${(props) => props?.color};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 0 0.6vw 0;

  & h6{
    color: ${(props) => props.theme.colors.vividRed_2};
    font-size: 12px;
    margin-left: 0.6vw;
  }
`;

export const DetailInfoOcr = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoOcr = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0.6vw ;

  & h4{
    padding: 1vw 0 0.6vw 0;
  }
`;
