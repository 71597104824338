import styled from 'styled-components';
import Error404 from '../../assets/Images/Hbi/Error_404.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${Error404});
  min-height: 100vh;
  min-width: 50vw;
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 2vw 0;

  h4 {
    font-family: Inter;
    font-size: 2.431vw;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
  }

  h5 {
    font-family: Inter;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
  }
`;
