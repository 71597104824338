import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  systemUserData: [],
  pagination: { totalResult: 0 },
  errorMessage: '',
};

export const deleteSystemUserActionLoading = createAction('DELETE_SYSTEM_USER_ACTION_LOADING');
export const deleteSystemUserActionSuccess = createAction('DELETE_SYSTEM_USER_ACTION_SUCCESS');
export const deleteSystemUserActionError = createAction('DELETE_SYSTEM_USER_ACTION_ERROR');

export const editSystemUserActionLoading = createAction('EDIT_SYSTEM_USER_ACTION_LOADING');
export const editSystemUserActionSuccess = createAction('EDIT_SYSTEM_USER_ACTION_SUCCESS');
export const editSystemUserActionError = createAction('EDIT_SYSTEM_USER_ACTION_ERROR');

export const createSystemUserActionLoading = createAction('CREATE_SYSTEM_USER_ACTION_LOADING');
export const createSystemUserActionSuccess = createAction('CREATE_SYSTEM_USER_ACTION_SUCCESS');
export const createSystemUserActionError = createAction('CREATE_SYSTEM_USER_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [deleteSystemUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSystemUser: true,
      error: false,
    };
  },
  [deleteSystemUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: false,
    };
  },
  [deleteSystemUserActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
  [editSystemUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSystemUser: true,
      error: false,
    };
  },
  [editSystemUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: false,
    };
  },
  [editSystemUserActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
  [createSystemUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSystemUser: true,
      error: false,
    };
  },
  [createSystemUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: false,
    };
  },
  [createSystemUserActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSystemUser: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
});
