import styled from 'styled-components';
import { TextField as InputMaterial } from '@material-ui/core';

export const Input = styled(InputMaterial)`
  width: ${(props) => props.width};

  & label {
    margin-left: 1.111vw;
  }

  &.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputBase-input.MuiInput-input {
    margin-left: 1.111vw;
  }
`;
