import { useTheme } from 'styled-components';

export default function styleSideBar(theme) {
  const myTheme = useTheme();
  const drawerWidth = '15vw';

  const style = {
    root: {
      display: 'block',
    },
    hidden: {
      display: 'none',
    },
    appBar: {
      backgroundColor: myTheme.colors.white,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '1.667vw',
      borderRadius: '0px 9px 9px 0px',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: '2.5vw',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: myTheme.colors.white,
    },
    drawerOpen: {
      width: '18vw',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: myTheme.colors.menuOpen,
      color: myTheme.colors.white,
      borderRadius: '0px 9px 9px 0px',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { backgroundColor: 'transparent', width: '0' },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: myTheme.colors.menuClose,
      color: `transparent`,
      overflowX: 'hidden',
      width: '5.856515373352855vw',
      borderRadius: '0px 9px 9px 0px',
      '&::-webkit-scrollbar': { backgroundColor: 'transparent', width: '0' },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0',
      paddingLeft: '1.171303074670571vw',

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    logo: {
      marginLeft: '7.067vw',
      cursor: 'pointer',
      width: '2em',
    },
    barIconsContainer: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icons: {
      color: `${myTheme.colors.white} !important`,
      minWidth: '1.5vw',
    },
    barIcons: {
      color: 'rgba(38, 36, 36, 1) !important',
      fontSize: '1.351vw',
      marginLeft: '1.042vw',
      cursor: 'pointer',
    },
    mainIcon: {
      color: `${myTheme.colors.white} !important`,

      '@media (max-width: 1300px)': {
        marginLeft: '-0.3vw',
      },
      '@media (max-width: 768px)': {
        marginLeft: '-0.77vw',
      },
      '@media (min-width: 1700px)': {
        marginLeft: '3px',
      },
    },
  };

  return style;
}
