import Styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { FormControl, FormLabel } from '@mui/material';

export const LoadingContainer = Styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  color: ${(props) => props.theme.colors.loader};
`;

export const ContainerStatus = Styled.div`
  background-color: ${(props) => props.background ?? props.theme.colors.vividOrangeTransparent};
  border-radius: 5px;
  padding: 2px 0.833vw;
  margin-left: 10px;
  display: inline-flex;
  color: ${(props) => props.color ?? props.theme.colors.vividOrange};
  text-transform: uppercase;
`;

export const Footer = Styled.footer`
  display: flex;
  justify-content: space-evenly;
  margin: 2.083vw 0;
`;

export const SectionsButton = Styled.div`
  width: 15%;
`;

export const SectionsBody = Styled.div`
  display: flex;
`;

export const ContainerAccordion = Styled.div`
  padding: 1rem 0 0 0;
`;

export const TitleAccordion = Styled.div`
  display: flex;
`;

export const TitleRepresentative = Styled.div`
  padding: 2rem 0 0 0;
`;

export const InputDate = Styled.div`
  margin-left: 2rem;
`;

export const InputSelect = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerRadioGroup = Styled.div`
  margin: 0 0 0 2vw;
  display: flex;
  align-items: flex-end;
`;

export const InsertContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const InfoContainer = Styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioContainer = Styled(FormControl)`
  margin-top: 2rem !important;
  width: max-content;

  & .rContainer.Mui-focused{
    color: ${(props) => props.theme.colors.brown} !important;
  }
`;

export const RadioLabel = Styled(FormLabel)`
  font-weight: 800 !important;
`;

export const Tooltips = Styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: ${(props) => props.theme.colors.veryPaleRed};
    color: ${(props) => props.theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
  & .MuiTooltip-tooltip {
    max-width: 500px;
  }
`;
