import React, { useEffect } from 'react';
import formatCPF from '../../../../../utils/functions/formatCPF';
import formatPhoneNumber from '../../../../../utils/functions/formatPhoneNumber';
import * as Styled from './InformationColum.styles';
import { listTypeSignatoriesAction } from '../../../../../store/fetchActions/approveUserActions';
import { useDispatch, useSelector } from 'react-redux';

export default function Information({ data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listTypeSignatoriesAction());
  }, []);

  return (
    <Styled.ContainerStyled>
      <Styled.PrimaryContainer>
        <div>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NOME || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'CPF'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCPF(data?.CPFCNPJ_USUARIO)}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'E-mail'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.EMAIL || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'Celular'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatPhoneNumber(data?.FONECEL) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </div>
      </Styled.PrimaryContainer>
    </Styled.ContainerStyled>
  );
}
