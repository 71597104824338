import api from '../../utils/services/api';

import { scrowActionError, scrowActionLoading, scrowActionSuccess, scrowActionInfo } from '../ducks/scrow';
import { hideAlert, showAlert } from '../ducks/sweetAlert';

export const scrowFetchAction = (id, willBeLoaded, status, callback = () => undefined) => {
  return async (dispatch) => {
    if (willBeLoaded === 'willBeLoaded') {
      dispatch(scrowActionLoading());
    }
    try {
      const response = await api.get(`/admin/scrow/listar/representantes?CNPJ_EMPRESA=${id}&status=${status}`);
      dispatch(scrowActionSuccess(response.data));
      callback();
    } catch (error) {
      dispatch(scrowActionError(error?.response?.data));
      callback();
    }
  };
};

export const scrowFetchActionByCnpj = (cnpj, willBeLoaded, callback = () => undefined) => {
  return async (dispatch) => {
    if (willBeLoaded === 'willBeLoaded') {
      dispatch(scrowActionLoading());
    }
    try {
      const response = await api.get(`/admin/scrow/empresa?CNPJ_EMPRESA=${cnpj}`);
      dispatch(scrowActionInfo(response.data));
      callback();
    } catch (error) {
      dispatch(scrowActionError(error?.response?.data));
      callback();
    }
  };
};
