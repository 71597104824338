import { useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal/Modal';
import Loading from '../../../../components/Loading';
import Tabs from '../../../../components/Tabs/TabsAccount';
import { useSelector } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';
import * as Styled from '../../styles/ContasAprovados.styles';
import DropDown from '../../../../components/DropDown/DropDown';
import DocumentCollum from '../Collums/DocumentCollum';
import AddressCollum from '../Collums/AddressCollum';
import GraphicSheet from '../GraphicSheet';
import Permission from '../../../../components/Permission/Permission';
import ModalManageBalance from './ManageBalance';
import ModalPassword from '../../../../components/ModalPassword/ModalPassword';
import ModalBlockAccount from './BlockAccount';
import { accountsApprovedFetchAction } from '../../../../store/fetchActions/userAccounts';
import ModalSendDocuments from './SendDocuments';
import {detailAccountsFetchAction } from '../../../../store/fetchActions/accountActions';

const ContasAprovadas = ({ open, close, info, accountId }) => {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexContas, setTabIndexContas] = useState('');
  const [manageBalance, setManageBalance] = useState(false);
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [nameSelectedItem, setNameSelectedItem] = useState('');
  const [functionSelectedItem, setFunctionSelectedItem] = useState(() => {});
  const [isModalBlockUserOpen, setIsModalBlockUserOpen] = useState(false);
  const [documentTypeToSend, setDocumentTypeToSend] = useState('');
  const [isModalSendDocumentsOpen, setIsModalSendDocumentsOpen] = useState(false);

  const { CONTAS } = useSelector((state) => state.user.usersData);
  const { loadingUser, usersBalance } = useSelector((state) => state.user);

  const arrayContas = useMemo(() => CONTAS?.filter((e) => e.ID_STATUS != 5 && e.ID_STATUS != 1), [CONTAS]);

  useEffect(() => {
    if (arrayContas != undefined) {
      const indexSelectedAccount = arrayContas.findIndex((e) => e.ID_CONTA == accountId);

      if (tabIndexContas === '') {
        setTabIndex(indexSelectedAccount);
      }

      setTabIndexContas(arrayContas[tabIndex]);
    }
  }, [arrayContas, tabIndex]);
  

  const options = useMemo(
    () => [
      {
        label: tabIndexContas?.STATUS === 'ATIVO' ? 'Bloquear conta' : 'Desbloquear conta',
        action: 'blockUser',
      },
      {
        label: 'Enviar imagens',
        action: 'sendImages',
      },
      {
        label: 'Enviar documentos',
        action: 'sendDocuments',
      },
    ],
    [tabIndexContas],
  );

  const balanceDispatch = () => {
    dispatch(detailAccountsFetchAction(tabIndexContas?.ID_CONTA))
    setManageBalance(true)

  }

  const callFunction = () => {
    const callFunction = {
      editData: () => edit(),
      blockUser: () => setIsModalBlockUserOpen(true),
      unlockUser: () => setIsModalBlockUserOpen(true),
      sendImages: () => {
        setDocumentTypeToSend('image/*');
        setIsModalSendDocumentsOpen(true);
      },
      sendDocuments: () => {
        setDocumentTypeToSend('.pdf');
        setIsModalSendDocumentsOpen(true);
      },
      manageBalance: () => balanceDispatch(),
    };

    setFunctionSelectedItem(callFunction[nameSelectedItem]);
  };

  useEffect(() => {
    localStorage.getItem('nivelAcesso') === '1' &&
      options?.push({
        label: 'Gerenciar Histórico',
        action: 'manageBalance',
      });
  }, [tabIndexContas]);

  return (
    <Modal open={open} close={close} title="Detalhes da conta" width={'96.222vw'}>
      {loadingUser ? (
        <Loading />
      ) : (
        <>
          <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={arrayContas} variant="fullWidth" />
          <Styled.EditButtonContainer>
            <h4>Informações do cliente</h4>
            <DropDown
              openModalPassword={() => setIsModalPasswordOpen(true)}
              options={options}
              setNameSelectedItem={(e) => setNameSelectedItem(e)}
            />
          </Styled.EditButtonContainer>

          <Styled.FirstBlock>
            <AddressCollum contas={tabIndexContas} info={info} />
            <DocumentCollum contas={tabIndexContas} />
          </Styled.FirstBlock>
          <Styled.Line />

          <Styled.ThirdBlock>{<GraphicSheet contas={tabIndexContas} />}</Styled.ThirdBlock>
        </>
      )}

      {isModalPasswordOpen && (
        <ModalPassword
          openModal={isModalPasswordOpen}
          closeModal={() => setIsModalPasswordOpen(false)}
          nextFunction={callFunction}
        />
      )}

      {isModalBlockUserOpen && (
        <ModalBlockAccount
          openModal={isModalBlockUserOpen}
          closeModal={() => {
            setIsModalBlockUserOpen(false);
            close();
            dispatch(accountsApprovedFetchAction({ PAGE: 1, LIMIT: 10 }));
          }}
          blockUser={tabIndexContas?.STATUS === 'ATIVO' ? true : false}
          idConta={tabIndexContas?.ID_CONTA}
        />
      )}

      {isModalSendDocumentsOpen && (
        <ModalSendDocuments
          openModal={isModalSendDocumentsOpen}
          closeModal={() => setIsModalSendDocumentsOpen(false)}
          accept={documentTypeToSend}
          conta={tabIndexContas}
        />
      )}

      <Permission permissions={[1]}>
        {manageBalance && (
          <ModalManageBalance
            openModal={manageBalance}
            closeModal={() => setManageBalance(false)}
            idConta={tabIndexContas?.ID_CONTA}
          />
        )}
      </Permission>
    </Modal>
  );
};

export default ContasAprovadas;
