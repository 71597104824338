const convertBase64 = (imgData, typeImage) => {
  const byteCharacters = atob(imgData ?? '');
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  let imagetr = new Blob([byteArray], { type: typeImage });

  const url = URL.createObjectURL(imagetr);
  return url;
};

export default convertBase64;
