import api from '../../utils/services/api';

import {
  approveUserActionError,
  approveUserActionLoading,
  approveUserActionSuccess,
  listTypeSignatoriesError,
  listTypeSignatoriesLoading,
  listTypeSignatoriesSuccess,
  listTypeRepresentativeError,
  listTypeRepresentativeLoading,
  listTypeRepresentativeSuccess,
  listDocumentsRepresentativeError,
  listDocumentsRepresentativeLoading,
  listDocumentsRepresentativeSuccess,
  approveComiteActionError,
  approveComiteActionLoading,
  approveComiteActionSuccess,
  negadeComiteActionError,
  negadeComiteActionLoading,
  negadeComiteActionSuccess,
  insertRepresentativeActionError,
  insertRepresentativeActionLoading,
  insertRepresentativeActionSuccess,
  sendDocumentsAllActionError,
  sendDocumentsAllActionLoading,
  sendDocumentsAllActionSuccess,
} from '../ducks/approveUser';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { pendingUsersFetchAction } from './pendingUsersActions';
import { userFetchAction } from './usersActions';

export const approveUserFetchAction = (param, callback, status = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(pendingUsersFetchAction());
    };
    dispatch(approveUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/usuario/aprovar`, param);
      dispatch(approveUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Usuário aprovado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
      status(true);
    } catch (error) {
      dispatch(approveUserActionError(error?.response?.data));
      status(false);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const listTypeSignatoriesAction = () => {
  return async (dispatch) => {
    dispatch(listTypeSignatoriesLoading());

    try {
      const response = await api.get(`admin/webpj/usuario/listar-tipos-signatarios`);
      dispatch(listTypeSignatoriesSuccess(response.data));
    } catch (error) {
      dispatch(listTypeSignatoriesError(error?.response?.data));
    }
  };
};

export const approveComiteAction = (param, validation, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(pendingUsersFetchAction());
      dispatch(userFetchAction(param?.representantes[0]?.CPFCNPJ_EMPRESA, 'willBeLoaded'));
    };

    dispatch(approveComiteActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`admin/comite/aceite-admin`, param);
      dispatch(approveComiteActionSuccess(response.data));
      validation &&
        dispatch(
          showAlert({
            title: 'Sucesso',
            text: 'Cliente e comitê aprovados com sucesso!',
            icon: 'success',
            func: closeModals,
          }),
        );
      validation === 'comite' &&
        dispatch(
          showAlert({
            title: 'Sucesso',
            text: 'Usuário do comitê aprovado pelo admin com sucesso!',
            icon: 'success',
            func: closeModals,
          }),
        );
    } catch (error) {
      dispatch(approveComiteActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const negadeComiteAction = (param) => {
  return async (dispatch) => {
    dispatch(negadeComiteActionLoading());

    try {
      const response = await api.post(`admin/comite/reprova-admin`, param);
      dispatch(negadeComiteActionSuccess(response.data));
    } catch (error) {
      dispatch(negadeComiteActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const listTypeRepresentative = () => {
  return async (dispatch) => {
    dispatch(listTypeRepresentativeLoading());

    try {
      const response = await api.get(`webpj/tipo_representante`);
      dispatch(listTypeRepresentativeSuccess(response.data));
    } catch (error) {
      dispatch(listTypeRepresentativeError(error?.response?.data));
    }
  };
};

export const listDocumentsRepresentative = (param) => {
  return async (dispatch) => {
    dispatch(listDocumentsRepresentativeLoading());

    try {
      const response = await api.post(`admin/usuario/documentos_obrigatorios_PJ`, param);
      dispatch(listDocumentsRepresentativeSuccess(response.data));
    } catch (error) {
      dispatch(listDocumentsRepresentativeError(error?.response?.data));
    }
  };
};

export const resetListDocumentsRepresentative = () => {
  return async (dispatch) => {
    dispatch(listDocumentsRepresentativeLoading());

    try {
      dispatch(listDocumentsRepresentativeSuccess([]));
    } catch (error) {
      dispatch(listDocumentsRepresentativeError([]));
    }
  };
};

export const insertRepresentativeAction = (param, callback) => {
  return async (dispatch) => {
    dispatch(insertRepresentativeActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/admin/user/legal-representative`, param);
      dispatch(insertRepresentativeActionSuccess(response.data));
      callback(true);
    } catch (error) {
      callback(false);
      dispatch(insertRepresentativeActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const SendDocumentsAllAction = (param, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(userFetchAction(param?.CPFCNPJCLIENTE, 'willBeLoaded'));
    };

    dispatch(sendDocumentsAllActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`cliente/documentos_lote/`, param);
      dispatch(sendDocumentsAllActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Representante adicionado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(sendDocumentsAllActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
