import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import * as S from '../../../components/InputFormatValue/InputFormatValue.styles';
import { NumericFormat } from 'react-number-format';

const InputFormat = ({
  disabled,
  value,
  width,
  height,
  required,
  name,
  control,
  formatType,
  variant,
  label,
  backcolor,
  color,
  labelcolor,
  labelfocuscolor,
  borderfocuscolor,
  error,
  spacelabelinput,
  decimalPlaces = 2
}) => {
  
  function PlaceholderFormatting(formatType, decimalPlaces) {
    if (formatType === 'percent') {
       return `0.${'0'.repeat(decimalPlaces)} %`;
    } else if (formatType === 'currency') {
       return `R$00,00`;
    } else {
       return '';
    }
   }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <NumericFormat
        {...field}
        width={width}
        height={height}
        disabled={disabled}
        customInput={S.Input}
        thousandSeparator='.'
        suffix={formatType === 'percent' ? '%' : ''}
        prefix={formatType ===  'currency'? 'R$' : ''}
        decimalSeparator=','
        decimalScale={formatType === 'percent' ? decimalPlaces : 2}
        fixedDecimalScale
        variant={variant}
        label={label}
        allowEmptyFormating={false}
        placeholder={PlaceholderFormatting(formatType,decimalPlaces)}
        required={required}
        color={color}
        backcolor={backcolor}
        labelcolor={labelcolor}
        labelfocuscolor={labelfocuscolor}
        borderfocuscolor={borderfocuscolor}
        error={error}
        spacelabelinput={spacelabelinput}
      />
      )}
    />
  );
};

InputFormat.displayName = 'InputFormatComponent';
export default InputFormat;

InputFormat.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  formatType: PropTypes.bool,
  width: PropTypes.string,
  color: PropTypes.string,
  backcolor: PropTypes.string,
  labelcolor: PropTypes.string,
  labelfocuscolor: PropTypes.string,
  borderfocuscolor: PropTypes.string,
};
