import Styled from 'styled-components';

export const MensagemTable = Styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ContainerBottom = Styled.div`
  display: flex;
  justify-content: center;
`;

export const RowBottom = Styled.div`
  justify-content: center;
`;

export const ContainerLimit = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionLimit = Styled.div`
  width: 19rem;
`;

export const SearchButtom = Styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
`;

export const CreateButtonGroup = Styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconContainer = Styled.div`
  border-radius: 50%;
  width: 2.778vw;
  height: 2.778vw;
  margin: 0 1.389vw;
  background-color: ${(props) => props.backgroundcolor} !important;
  color: ${(props) => props.color} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
