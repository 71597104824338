import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationDeniedScrows: { totalResult: 0 },
  loadingDeniedScrows: false,
  messageDeniedScrows: '',
  deniedScrowsData: [],
  errorDeniedScrows: false,
};

export const deniedScrowsActionLoading = createAction('DENIED_SCROWS_ACTION_LOADING');
export const deniedScrowsActionSuccess = createAction('DENIED_SCROWS_ACTION_SUCCESS');
export const deniedScrowsActionError = createAction('DENIED_SCROWS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [deniedScrowsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDeniedScrows: true,
      errorDeniedScrows: false,
      paginationDeniedScrows: {},
      deniedScrowsData: [],
    };
  },
  [deniedScrowsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedScrows: false,
      errorDeniedScrows: false,
      deniedScrowsData: payload.data,
      paginationDeniedScrows: payload.pagination,
    };
  },
  [deniedScrowsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedScrows: false,
      errorDeniedScrows: true,
      messageDeniedScrows: payload?.mensagem,
    };
  },
});
