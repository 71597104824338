import api from '../../utils/services/api';

import { denyScrowActionError, denyScrowActionLoading, denyScrowActionSuccess } from '../ducks/denyScrow';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { pendingScrowsFetchAction } from './pendingScrowsActions';

export const denyScrowFetchAction = (param, callback) => {
  return async (dispatch) => {
    dispatch(denyScrowActionLoading());

    const closeModals = () => {
      callback();
      dispatch(pendingScrowsFetchAction());
    };
    dispatch(showAlert({}));
    try {
      const response = await api.post(`/admin/scrow/reprovar`, param);
      dispatch(denyScrowActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Escrow negado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(denyScrowActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
