import api from '../../utils/services/api';

import { imageError, imageLoading, imageSuccess } from '../ducks/image';

export const imageAction = (data) => {
  return async (dispatch) => {
    dispatch(imageLoading());
    try {
      const response = await api.get(data, {
        responseType: 'arraybuffer',
      });

      dispatch(
        imageSuccess({
          imgData: Buffer.from(response?.data, 'binary').toString('base64'),
          typeImage: response?.headers['content-type'],
        }),
      );
    } catch (error) {
      dispatch(
        imageError({
          imgData: '',
          typeImage: '',
        }),
      );
    }
  };
};
