import api from '../../utils/services/api';

import { logsActionError, logsActionLoading, logsActionSuccess } from '../ducks/logs';

export const logsFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(logsActionLoading());
    try {
      const response = await api.post('log/acesso/listar', filtro);
      dispatch(logsActionSuccess(response.data));
    } catch (error) {
      dispatch(logsActionError(error?.response?.data));
    }
  };
};
