import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

export const denyUserActionLoading = createAction('DENY_USER_ACTION_LOADING');
export const denyUserActionSuccess = createAction('DENY_USER_ACTION_SUCCESS');
export const denyUserActionError = createAction('DENY_USER_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [denyUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDenyUser: true,
      errorDenyUser: false,
    };
  },
  [denyUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDenyUser: false,
      errorDenyUser: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [denyUserActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDenyUser: false,
      errorDenyUser: true,
    };
  },
});
