import api from '../../utils/services/api';

import {
    typesFetchLoading,
    typesFetchError,
    typesFetchSuccess
} from '../ducks/spreadType';

export const fetchTypes = () => {
    return async (dispatch) => {
      dispatch(typesFetchLoading());
      try {
        const response = await api.get('/coban/tipoValor/listar');
        dispatch(typesFetchSuccess(response.data));
      } catch (error) {
        dispatch(typesFetchError(error.response.data));
      }
    };
  };