import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TextInputMaskCpfCnpj.styles';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

export default function TextInputMaskCpfCnpj({
  label,
  name,
  width,
  height,
  variant = 'standard',
  multiline,
  control,
  margin,
  type,
  inputProps,
  maxRows,
  minRows,
  required = false,
  spacelabelinput,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputMask mask={field?.value?.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'} maskChar="" {...field}>
          {() => (
            <Styled.Text
              type={type ? type : 'text'}
              label={label}
              width={width}
              height={height}
              variant={variant}
              multiline={multiline}
              maxRows={maxRows}
              minRows={minRows}
              required={required}
              margin={margin}
              inputProps={inputProps}
              spacelabelinput={spacelabelinput}
            />
          )}
        </InputMask>
      )}
    ></Controller>
  );
}

TextInputMaskCpfCnpj.propTypes = {
  setFilter: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  filter: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  control: PropTypes.any,
  margin: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
