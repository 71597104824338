import { Controller } from 'react-hook-form';
import PasswordChecklist from 'react-password-checklist';

const PasswordCheckList = ({ value, name, control, valueAgain, onChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <PasswordChecklist
          rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
          minLength={8}
          value={value}
          valueAgain={valueAgain}
          onChange={(isValid) => onChange(isValid)}
          messages={{
            minLength: 'A senha deve conter 8 caracteres.',
            specialChar: 'A senha deve conter caracteres especiais.',
            number: 'A senha deve conter número.',
            capital: 'A senha deve conter letra maiúscula',
            lowercase: 'A senha deve conter letra minúscula',
            match: 'As senhas são iguais.',
          }}
        />
      )}
    ></Controller>
  );
};

export default PasswordCheckList;
