import api from '../../utils/services/api';

import { paymentsActionLoading, paymentsActionSuccess, paymentsActionError } from '../ducks/payments';

export const paymentsFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(paymentsActionLoading());

    try {
      const response = await api.post(`transacao/buscar/pagamentos`, filtro);
      dispatch(paymentsActionSuccess(response.data));
    } catch (error) {
      dispatch(paymentsActionError(error?.response?.data));
    }
  };
};
