import { Button } from '../Button/Button.styles';
import Modal from '../Modal/Modal';
import * as Styled from './components/TwoFactor.styles';
import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useTheme } from 'styled-components';

const ModalTwoFactor = ({ open, onClose, handleToken, control, onSubmit }) => {
  const [token, setToken] = useState('');
  const [logo, setLogo] = useState('');
  const theme = useTheme();

  useEffect(async () => {
    const appName = process.env.REACT_APP_NAME;
    if (appName) {
      const Logo = await import(`../../assets/Images/${appName}/Logo.svg`);
      return setLogo(Logo.default);
    }

    const Logo = await import(`../../assets/Images/Hbi/Logo.svg`);
    setLogo(Logo.default);
  }, []);

  const sendToken = () => {
    event.preventDefault();
    onSubmit(token);
    onClose();
  };

  return (
    <Modal open={open} close={onClose} width={'38.889vw'}>
      <Styled.ModalContainer>
        <>
          <Styled.ImageContainer width="13.667vw" margin="0 0 2vw 0">
            <img src={logo} alt="Logo"></img>
          </Styled.ImageContainer>
          <Styled.LoginInfoToken>
            <Typography variant="h4">Token</Typography>
            <Typography variant="h6">Digite o código que se encontra no seu aplicativo de dois fatores</Typography>
          </Styled.LoginInfoToken>
          <div>
            <form onSubmit={sendToken}>
              <Styled.TokenModalContainer>
                <Styled.StyledReactInputVerificationCode>
                  <ReactInputVerificationCode
                    value={token}
                    placeholder={null}
                    length={6}
                    autoFocus
                    onChange={(newValue) => {
                      setToken(newValue);
                    }}
                    name={'TOKEN'}
                    control={control}
                  />
                </Styled.StyledReactInputVerificationCode>

                <Typography align="center"></Typography>
                <Button
                  backgroundcolor={theme.colors.buttonConfirm}
                  width="31.250vw"
                  hoverbgcolor={theme.colors.buttonConfirmHover}
                  hovercolor={theme.colors.buttonConfirmHoverText}
                  mycolor={theme.colors.buttonConfirmText}
                  type="submit"
                  margin={'5vw 0 2.847vw 0'}
                >
                  CONFIRMAR
                </Button>
              </Styled.TokenModalContainer>
            </form>
          </div>
        </>
      </Styled.ModalContainer>
    </Modal>
  );
};

export default ModalTwoFactor;
