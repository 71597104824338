import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        d="M17 15.22c0 .98-.8 1.78-1.78 1.78s-1.77-.8-1.77-1.78.79-1.77 1.77-1.77 1.78.79 1.78 1.77zM8.83 10.6c.98 0 1.77-.79 1.77-1.77 0-.99-.79-1.78-1.77-1.78-.99 0-1.78.79-1.78 1.78 0 .98.79 1.77 1.78 1.77zM7 15.53l1.5 1.5 8.53-8.53-1.5-1.5L7 15.53zM22 12c0 5.5-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2s10 4.5 10 10zm-2 0c0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8 8-3.59 8-8z"
      ></path>
    </svg>
  );
}

export default Icon;
