import { useEffect, useState, useMemo } from 'react';
import Table from '../../components/Table/Table';
import DateInput from '../../components/DateInput';
import Filter from '../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import TextField from '../../components/TextField';
import Wrapper from '../../components/Wrapper/Wrapper';
import Button from '../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import SelectOptions from '../../components/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import { digitalServicesFetchAction } from '../../store/fetchActions/digitalServicesActions';
import { getPaymentWayAndTransactionTypes } from '../../store/fetchActions/reportsActions';
import formatCPF from '../../utils/functions/formatCPF';
import formatDateHora from '../../utils/functions/formatDateHora';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import verifyEmptyValues from '../../utils/verifyEmptyValues';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../utils/constant';
import Loading from '../../components/Loading';

export default function ServicosDigitais() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
    STATUS: 0,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(digitalServicesFetchAction(filter));
  }, [filter.PAGE, dispatch]);

  const {
    dataDigitalServices,
    paginationDigitalServices,
    loadingDigitalServices,
    errorDigitalServices,
    messageDigitalServices,
  } = useSelector((state) => state.digitalServices);

  const { handleSubmit, control } = useForm({});

  const filterTable = (data) => {
    let Search = '';
    data.SEARCH != undefined && (Search = data.SEARCH.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''));
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;

    data.SEARCH = Search;
    dispatch(digitalServicesFetchAction(data));
  };

  useEffect(() => {
    dispatch(getPaymentWayAndTransactionTypes());
  }, []);

  const { status_recarga } = useSelector((state) => state?.reports);

  const arrayRecarga = useMemo(
    () =>
      status_recarga?.map((val) => {
        let array = [];
        array = {
          ID: val.ID,
          NOME: val.NOMECLATURA,
        };
        return array;
      }),
    [status_recarga],
  );

  const arrayStatus = [{ ID: '0', NOME: 'Todos' }, ...arrayRecarga];

  const header = [
    {
      id: 'data e hora',
      name: 'DATA/HORA',
      align: 'center',
    },
    { id: 'cpf', name: 'CPF', align: 'left' },
    { id: 'nome', name: 'NOME', align: 'left' },
    { id: 'telefone', name: 'Nº DA RECARGA', align: 'left' },
    { id: 'valor', name: 'VALOR PAGO', align: 'right' },
    { id: 'status', name: 'STATUS', align: 'center' },
  ];

  const formatCellphoneNumber = (cellphoneNumber) => {
    switch (cellphoneNumber?.length) {
      case 9:
        return cellphoneNumber?.slice(0, 5) + '-' + cellphoneNumber?.slice(5);
      case 11:
        return (
          '(' + cellphoneNumber?.slice(0, 2) + ') ' + cellphoneNumber?.slice(2, 7) + '-' + cellphoneNumber?.slice(7)
        );
      default:
        return cellphoneNumber;
    }
  };

  const body = dataDigitalServices?.map((digitalService) => {
    return {
      data: [
        {
          info: verifyEmptyValues(formatDateHora(digitalService.DATAHORA)),
          align: 'center',
        },
        {
          info: formatCPF(verifyEmptyValues(digitalService.CPFCNPJ)),
          align: 'left',
        },
        {
          info: verifyEmptyValues(digitalService.NOME),
          align: 'left',
        },
        {
          info: formatCellphoneNumber(verifyEmptyValues(digitalService.TELEFONE)),
          align: 'left',
        },
        {
          info: 'R$ ' + formatMoney(verifyEmptyValues(digitalService.VALOR)),
          align: 'right',
        },
        {
          info: digitalService.STATUS ? (
            <StatusContainer
              code={digitalService.STATUSTRANSACAO}
              description={digitalService.STATUS}
            ></StatusContainer>
          ) : (
            '-----'
          ),
          align: 'center',
        },
      ],
      // onClick: () => inspectDigitalService(digitalService),
    };
  });

  return (
    <Wrapper title="Recargas de Celular">
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF" name={'SEARCH'} width={'27.042vw'} control={control} />
              <SelectOptions
                label={'Status'}
                width={'12.528vw'}
                name={'ID_STATUS'}
                control={control}
                options={arrayStatus}
                initialValue={'0'}
              />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />

              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="12.153vw"
                margin={'0 1.389vw 0 0'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectOptions
                control={control}
                options={pagesOptions}
                name={'LIMIT'}
                margin={'0 0 0 1.875vw'}
                initialValue={10}
              />
              <h5>Total de resultados: {paginationDigitalServices?.totalResult}</h5>
            </SecondFilterLine>
          </form>
        </Filter>

        {loadingDigitalServices ? (
          <Loading />
        ) : (
          <Table
            loading={loadingDigitalServices}
            error={errorDigitalServices}
            hasPagination={true}
            filter={filter}
            pagination={paginationDigitalServices}
            setFilter={setFilter}
            errorMessage={messageDigitalServices}
            filterTable={filterTable}
            header={header}
            body={body}
            limit={filter.LIMIT}
          />
        )}
      </div>
    </Wrapper>
  );
}
