import styled from 'styled-components';

export const Container = styled.div`
  .swal-modal {
    .swal-text {
      background-color: ${(props) => props.theme.lightGrayishYellow};
      padding: 17px;
      border: 1px solid ${(props) => props.theme.verySoftYellow};
      display: block;
      margin: 22px;
      font-size: 40px;
      text-align: center;
      color: ${(props) => props.theme.veryDarkGrayishOrange};
    }
  }
`;
