import { memo, useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import DateInput from '../../components/DateInput';
import Filter from '../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import TextField from '../../components/TextField';
import Wrapper from '../../components/Wrapper/Wrapper';
import Button from '../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { logsFetchAction } from '../../store/fetchActions/logsActions';
import formatCPF from '../../utils/functions/formatCPF';
import useCallFilter from '../../utils/hooks/useCallFilter';
import { useTheme } from 'styled-components';
import moment from 'moment';
import SelectInput from '../../components/SelectOptions/SelectInput';
import { pagesOptions } from '../../utils/constant';
import Loading from '../../components/Loading';

function Logs() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { paginationLogs, loadingLogs, messageLogs, dataLogs, errorLogs } = useSelector((state) => state.logs);

  const { handleSubmit, control } = useForm({});

  useCallFilter(filterData, setFilter, logsFetchAction, 0, 0);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  useEffect(() => {
    dispatch(logsFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data e hora',
      name: 'DATA/HORA',
      align: 'center',
    },
    { id: 'cpf', name: 'CPF', align: 'left' },
    { id: 'nome', name: 'NOME', align: 'left' },
    { id: 'email', name: 'EMAIL', align: 'left' },
    { id: 'ip', name: 'IP', align: 'left' },
    { id: 'id dispositivo', name: 'ID DISPOSITIVO', align: 'left' },
  ];

  const body = dataLogs?.map((log) => {
    return {
      data: [
        {
          info: log.DATAHORA,
          align: 'center',
        },
        {
          info: formatCPF(log.CPFCNPJ),
          align: 'left',
        },
        {
          info: log.NOME,
          align: 'left',
        },

        {
          info: log.EMAIL,
          align: 'left',
        },
        {
          info: log.IP,
          align: 'left',
        },
        {
          info: log.ID_DISPOSITIVO,
          align: 'left',
        },
      ],
    };
  });

  return (
    <Wrapper title="Logs">
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF" name={'SEARCH'} width={'38.042vw'} control={control} />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />

              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="12.153vw"
                margin={'0 1.389vw 0 0'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectInput control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
              <h5>Total de resultados: {paginationLogs?.totalResult}</h5>
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingLogs ? (
          <Loading />
        ) : (
          <Table
            loading={loadingLogs}
            error={errorLogs}
            hasPagination={true}
            filter={filter}
            pagination={paginationLogs}
            setFilter={setFilter}
            errorMessage={messageLogs}
            filterTable={filterTable}
            header={header}
            body={body}
            limit={filter.LIMIT}
          />
        )}
      </div>
    </Wrapper>
  );
}

export default memo(Logs);
