import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 24 14">
      <path
        fill="#fff"
        d="M12 0a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 3A2.5 2.5 0 003 5.5c0 .94.53 1.75 1.29 2.18.36.2.77.32 1.21.32.44 0 .85-.12 1.21-.32.37-.21.68-.51.91-.87A5.42 5.42 0 016.5 3.5v-.28c-.3-.14-.64-.22-1-.22zm13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31.12.19.25.34.4.49a2.482 2.482 0 001.72.7c.44 0 .85-.12 1.21-.32C20.47 7.25 21 6.44 21 5.5A2.5 2.5 0 0018.5 3zM12 9c-2.34 0-7 1.17-7 3.5V14h14v-1.5c0-2.33-4.66-3.5-7-3.5zm-7.29.55C2.78 9.78 0 10.76 0 12.5V14h3v-1.93c0-1.01.69-1.85 1.71-2.52zm14.58 0c1.02.67 1.71 1.51 1.71 2.52V14h3v-1.5c0-1.74-2.78-2.72-4.71-2.95zM12 11c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1z"
      ></path>
    </svg>
  );
}

export default Icon;
