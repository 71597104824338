import Styled from 'styled-components';

export const SubTitle = Styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FormSectionInput = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 1vw 0 0 0;
`;

export const ConteinerInput = Styled.div`
  padding: 0 0 1vw 0;
`;
