import api from '../../utils/services/api';

import {
  faceMatchError,
  faceMatchLoading,
  faceMatchSuccess,
  faceMatchReset,
  faceMatchOcrRelatorioLoading,
  faceMatchOcrRelatorioSuccess,
  faceMatchOcrRelatorioError,
  faceMatchOcrRelatorioDetalheLoading,
  faceMatchOcrRelatorioDetalheSuccess,
  faceMatchOcrRelatorioDetalheError,
} from '../ducks/faceMatchServices';
import { showAlert } from '../ducks/sweetAlert';

export const faceMatchUpload = (params) => {
  return async (dispatch) => {
    dispatch(faceMatchLoading());
    try {
      const response = await api.post(`/faceMatch/upload`, params);

      dispatch(faceMatchStatus({ ID: response.data.resposta?.uuid, ...params }));
    } catch (error) {
      dispatch(faceMatchError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const faceMatchStatus = (params) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`faceMatch/status/`, params);
      dispatch(faceMatchSuccess(response.data));
    } catch (error) {
      dispatch(faceMatchError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const faceMatchStatusReset = (params) => {
  return async (dispatch) => {
    try {
      dispatch(faceMatchReset());
    } catch (error) {}
  };
};

export const faceMatchConsultaStatus = (params, callback = () => undefined) => {
  return async (dispatch) => {
    try {
      const response = await api.post('/faceMatch/existeConsultaFaceMatch', params);
      callback(response?.data);
    } catch (error) {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const faceMatchOcrRelatorio = (params) => {
  return async (dispatch) => {
    dispatch(faceMatchOcrRelatorioLoading());
    try {
      const response = await api.get(
        `/faceMatchOCR/dadosRelatorio?DATAINICIO=${params.DATA_INICIAL ?? ''}&DATAFIM=${
          params.DATA_FINAL ?? ''
        }&SEARCH=${params.SEARCH ?? ''}&TIPO=${params.TIPO ?? 0}&PAGE=${params.PAGE ?? 1}&LIMIT=${params.LIMIT ?? 10}`,
      );

      dispatch(faceMatchOcrRelatorioSuccess(response?.data?.resposta));
    } catch (error) {
      dispatch(faceMatchOcrRelatorioError(error?.response?.data?.mensagem));
    }
  };
};

export const faceMatchOcrRelatorioDetalhes = (params) => {
  return async (dispatch) => {
    dispatch(faceMatchOcrRelatorioDetalheLoading());
    try {
      const response = await api.post('/faceMatchOCR/dadosDetalhe', params);

      dispatch(faceMatchOcrRelatorioDetalheSuccess(response?.data?.resposta));
    } catch (error) {
      dispatch(faceMatchOcrRelatorioDetalheError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};
