import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  alert: false,
  message: '',
  func: null,
  loadingSpinner: false,
  close: false,
};

export const showAlert = createAction('SHOW_SWEET_ALERT');
export const hideAlert = createAction('HIDE_SWEET_ALERT');

export default createReducer(INITIAL_STATE, {
  [showAlert.type]: (state, action) => {
    return {
      ...state,
      alert: true,
      message: action.payload,
      func: action.payload.func,
      close: false,
      loadingSpinner: action.payload.loadingSpinner,
    };
  },
  [hideAlert]: (state, action) => {
    return {
      ...state,
      alert: false,
      message: '',
      func: null,
      loadingSpinner: false,
      close: true,
    };
  },
});
