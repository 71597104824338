import { useTimer } from 'react-timer-hook';
import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import RenovacaoJwt from './RenovacaoJwt';
import { useTheme } from 'styled-components';

const Timer = ({ expiryTimestamp }) => {
  const theme = useTheme();

  const [logout, setLogout] = useState(false);
  const [renovation, setRenovation] = useState(false);

  // Options useTimer
  // seconds, minutes, hours, days, isRunning, start, pause, resume, restart
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setRenovation(false);
      setLogout(true);
    },
  });

  const trueSeconds = seconds < 10 ? '0' + seconds : seconds;

  useEffect(() => {
    minutes == 0 && seconds == 59 && setRenovation(true);
  }, [minutes]);

  return (
    <div style={{ textAlign: 'center' }}>
      <span>{minutes + ':' + trueSeconds}</span>
      <RenovacaoJwt open={renovation} close={() => setRenovation(false)} />
      <Modal open={logout} noClose={true} close={() => setLogout(true)}>
        <>
          <div style={{ padding: '1rem' }}>
            <h1>{'SUA SESSÃO FOI EXPIRADA'}</h1>
          </div>
          <div style={{ padding: '1rem' }}>
            <Button
              backgroundcolor={theme.colors.vividRed_2}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.vividRed_2}
              hovercolor={theme.colors.white}
              mycolor={theme.colors.white}
              onClick={() => {
                window.location = '/login';
                localStorage.clear();
              }}
            >
              <span>OK</span>
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Timer;
