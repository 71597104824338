import formatCPF from '../../utils/functions/formatCPF';
import formatCNPJ from '../../utils/functions/formatCNPJ';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import verifyEmptyValues from '../../utils/verifyEmptyValues';

export default function convertObjToArrayPayments(paymentDetailData) {
  const {
    CODIGO_BOLETO,
    STATUSDESCRICAO,
    STATUSTRANSACAO,
    NOME,
    CPFCNPJ,
    DATA,
    VALOR,
    TAXA,
    SPREAD,
    MODPAGAMENTODESCRICAO,
    VALOR_PAGO,
  } = paymentDetailData;

  const paymentDetailArray = [
    {
      name: 'Código da Transação:',
      value: verifyEmptyValues(CODIGO_BOLETO),
    },
    {
      name: 'Status:',
      value: STATUSDESCRICAO ? (
        <StatusContainer code={STATUSTRANSACAO} description={STATUSDESCRICAO}></StatusContainer>
      ) : (
        '-----'
      ),
    },
    {
      name: 'Nome:',
      value: verifyEmptyValues(NOME),
    },
    {
      name: 'CPF/CNPJ:',
      value: CPFCNPJ ? (CPFCNPJ.length === 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)) : '-----',
    },
    { name: 'Data de Emissão:', value: verifyEmptyValues(DATA) },
    {
      name: 'Tipo de Pagamento:',
      value: verifyEmptyValues(MODPAGAMENTODESCRICAO),
    },
    { name: 'Valor:', value: VALOR ? 'R$ ' + formatMoney(VALOR) : '-----' },
    { name: 'Taxa:', value: TAXA ? 'R$ ' + formatMoney(TAXA) : '-----' },
    {
      name: 'Taxa (spread):',
      value: SPREAD ? 'R$ ' + formatMoney(SPREAD) : '-----',
    },
    {
      name: 'Valor Total:',
      value: VALOR ? 'R$ ' + formatMoney(VALOR_PAGO) : '-----',
    },
  ];

  return paymentDetailArray;
}
