import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationDeniedUsers: { totalResult: 0 },
  loadingDeniedUsers: false,
  messageDeniedUsers: '',
  deniedUsersData: [],
  errorDeniedUsers: false,
};

export const deniedUsersActionLoading = createAction('DENIED_USERS_ACTION_LOADING');
export const deniedUsersActionSuccess = createAction('DENIED_USERS_ACTION_SUCCESS');
export const deniedUsersActionError = createAction('DENIED_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [deniedUsersActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDeniedUsers: true,
      errorDeniedUsers: false,
      paginationDeniedUsers: {},
      deniedUsersData: [],
    };
  },
  [deniedUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedUsers: false,
      errorDeniedUsers: false,
      deniedUsersData: payload.data,
      paginationDeniedUsers: payload.pagination,
    };
  },
  [deniedUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedUsers: false,
      errorDeniedUsers: true,
      messageDeniedUsers: payload?.mensagem,
    };
  },
});
