import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      fill="none"
      viewBox="0 0 16 16"
      style={{ paddingRight: props.paddingRight }}
    >
      <path
        fill={props.color ? props.color : '#011C7B'}
        d="M8 8.667a.993.993 0 00-.667 1.74v.927a.667.667 0 101.334 0v-.927A.993.993 0 008 8.667zM11.333 6V4.667a3.333 3.333 0 00-6.666 0V6a2 2 0 00-2 2v4.667a2 2 0 002 2h6.666a2 2 0 002-2V8a2 2 0 00-2-2zM6 4.667a2 2 0 114 0V6H6V4.667zm6 8a.667.667 0 01-.667.667H4.667A.666.666 0 014 12.667V8a.667.667 0 01.667-.667h6.666A.667.667 0 0112 8v4.667z"
      ></path>
    </svg>
  );
}

export default Icon;
