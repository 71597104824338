import styled from 'styled-components';
import { InputLabel, Select, FormControl } from '@material-ui/core';

export const InputLabelStyled = styled(InputLabel)`
  &.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputBase-input.MuiInput-input {
    margin-left: 0.2vw;
  }

  &.Mui-disabled {
    font-size: 14px;
    font-weight: 800;
  }
`;

export const SelectStyled = styled(Select)`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height};

  .MuiInputBase-input {
    margin-left: 0.2vw;
  }
`;

export const TypeFormControl = styled(FormControl)`
  width: ${(props) => props.width ?? 'auto'};
`;
