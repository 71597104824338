import React, { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter/Filter';
import { useForm } from 'react-hook-form';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import TextField from '../../components/TextField';
import SelectOptions from '../../components/SelectOptions';
import DateInput from '../../components/DateInput';
import Button from '../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { FiFileText } from 'react-icons/fi';
import {
  reportsFetchAction,
  getPaymentWayAndTransactionTypes,
  reportsExcelFetchAction,
  updateFilterReportsAction,
} from '../../store/fetchActions/reportsActions';
import { LoadingContainer } from '../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import Table from '../../components/Table/Table';
import InputFormatValue from '../../components/InputFormatValue';
import InputMask from '../../components/InputMask';
import Wrapper from '../../components/Wrapper/Wrapper';
import useCallFilter from '../../utils/hooks/useCallFilter';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import formatCPF from '../../utils/functions/formatCPF';
import formatCNPJ from '../../utils/functions/formatCNPJ';
import * as S from './Reports.styles';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../utils/constant';

function Report() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    CPFCNPJ_CLIENT: '',
    SEARCH: '',
    STATUS: '',
    LIMIT: 10,
    PAGE: 1,
    VALOR_MAX: 0,
    VALOR_MIN: 0,
    TIPO_TRANSACAO: '',
    MODO_PAGAMENTO: '',
    SUB_MODO_PAGAMENTO: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    AGENCIA: '',
    CONTA: '',
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));
  const [teste, setTeste] = useState(false);

  const {
    loadingReports,
    errorLoading,
    paginationReports,
    errorMessageReports,
    paymentWays,
    subPaymentWays,
    transactionTypes,
    dataReports,
  } = useSelector((state) => state.reports);

  useCallFilter(filterData, setFilter, reportsFetchAction, 0, 0);
  
  const form = useForm({});
  const { handleSubmit, control, formState } = form;
  
  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial ?? '';
    data.DATA_FINAL = dateFinal ?? '';
    data.VALOR_MAX = data?.VALOR_MAX?.replace('R$', '')?.replace('.', '')?.replace(',', '.')?.trim();
    data.VALOR_MIN = data?.VALOR_MIN?.replace('R$', '')?.replace('.', '')?.replace(',', '.')?.trim();
    
    data.VALOR_MAX == '0.00' && delete data.VALOR_MAX;
    data.VALOR_MIN == '0.00' && delete data.VALOR_MIN;

    setTeste(true);
    
    setFilterData(data);
    };
    
    useEffect(() => {
      dispatch(getPaymentWayAndTransactionTypes());
      }, []);
      
    useEffect(() => {
      dispatch(reportsFetchAction(filter));
    }, [filter.PAGE]);
        
    const arrayTransactionTypes = transactionTypes?.map((type) => {
      return { ID: type.CODTIPO, NOME: type.NOMECLATURA };
    });
    arrayTransactionTypes.unshift({ ID: 0, NOME: 'TODOS' });

  const arrayPaymentWays = paymentWays?.map((way) => {
    if (way?.NOMECLATURA) {
      return { ID: way.CODTIPO, NOME: way.NOMECLATURA };
      }
  });
  arrayPaymentWays.unshift({ ID: 0, NOME: 'TODOS' });

  function filterArrayPaymentWays(way) {
    if (way) return way;
  }

  function filterArraySubPaymentWays(way) {
    if (way) return way;
  }

  const arraySubPaymentWays = subPaymentWays?.map((way) => {
    if (way?.NOMECLATURA) {
      return { ID: way.CODTIPO, NOME: way.NOMECLATURA };
      }
  });

  arraySubPaymentWays.unshift({ ID: 0, NOME: 'TODOS' });
    
  const newArrayPaymentWays    = arrayPaymentWays.filter(filterArrayPaymentWays);
  const newArraySubPaymentWays = arraySubPaymentWays.filter(filterArraySubPaymentWays);
    
  const header = [
      {
        id: 'data de registro',
        name: 'DATA/HORA',
        align: 'center',
        },
        {
          id: 'nome remetente',
          name: 'NOME REMETENTE',
          align: 'left',
          },
          {
            id: 'cpfcnpj remetente',
            name: 'CPF/CNPJ REMETENTE',
            align: 'left',
    },
    {
      id: 'conta remetente',
      name: 'CONTA REMETENTE',
    },
    {
      id: 'nome destinário',
      name: 'NOME DESTINATÁRIO',
      align: 'left',
    },
    {
      id: 'cpfcnpj destinatário',
      name: 'CPF/CNPJ DESTINATÁRIO',
      align: 'left',
    },
    {
      id: 'descricao',
      name: 'DESCRIÇÃO',
      align: 'left',
    },
    {
      id: 'tipo',
      name: 'TIPO',
      align: 'center',
    },
    {
      id: 'valor descontado',
      name: 'VALOR DESCONTADO',
      align: 'right',
    },
    {
      id: 'SPREAD',
      name: 'SPREAD',
      align: 'right',
    },
    {
      id: 'valor pago',
      name: 'VALOR PAGO',
      align: 'right',
    },
  ];

  const renderDocument = (document) => {
    return document ? (document?.length > 11 ? formatCNPJ(document) : formatCPF(document)) : '-----';
  };

  const body = dataReports?.map((report) => {
    return {
      data: [
        { info: report.DATAHORA, align: 'center' },
        { info: report.NOME_ORIGEM, align: 'left' },
        {
          info: renderDocument(report.DOCUMENTO_ORIGEM),
          align: 'left',
        },
        { info: report.CONTA, align: 'center' },
        { info: report.NOME_DESTINO, align: 'left' },
        {
          info: renderDocument(report.DOCUMENTO_DESTINO),
          align: 'left',
        },
        { info: report.DESCRICAO, align: 'left' },
        { info: report.TIPO, align: 'center' },
        { info: 'R$ ' + formatMoney(report.VALOR_DESCONTADO), align: 'right' },
        { info: 'R$ ' + formatMoney(report.SPREAD), align: 'right' },
        {
          info:
            report.TIPO === 'CRÉDITO' ? (
              <S.Color color={theme.colors.darklimeGreen}> {'R$ + ' + formatMoney(report.VALOR)}</S.Color>
            ) : (
              <S.Color color={theme.colors.vividRed_2}> {'R$ - ' + formatMoney(report.VALOR)}</S.Color>
            ),
          align: 'right',
        },
      ],
    };
  });

  const handleExport = () => {
    dispatch(reportsExcelFetchAction(filter))
    setTeste(false)
}


  return (
    <Wrapper title="Relatórios Parametrizados">
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="button"
                disabled={!teste}
                // onClick={() => (
                //   dispatch(reportsExcelFetchAction(filter))
                //   setTeste(false)
                // )}
                onClick={handleExport}
                // margin={'0 1.389vw 0 0'}
              >
                <FiFileText className="default-svg" size={'1.181vw'} />
                <span>Exportar</span>
              </Button>
            </FirstFilterLine>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'27.70vw'} control={control} />
              <InputMask label="Agência" name="AGENCIA" mask={'9999'} width={'10.00vw'} control={control} />
              <InputMask label="Conta" name="CONTA" mask="999999999999999" width={'21.042vw'} control={control} />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                onChange={(newValue) => {
                  console.log('newValue', newValue)
                  return newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }

                  
                }
              />
            </FirstFilterLine>
            <FirstFilterLine>
              <InputFormatValue name="VALOR_MIN" control={control} width={'13.021vw'} label="Valor Mínimo" />
              <InputFormatValue name="VALOR_MAX" control={control} width={'13.021vw'} label="Valor Máximo" />
              <SelectOptions
                label="Tipos de Transações"
                name={'TIPO_TRANSACAO'}
                width={'15.00vw'}
                control={control}
                options={arrayTransactionTypes}
                initialValue={0}
              />
              <SelectOptions
                label="Tipo Modo de Pagamento"
                name={'MODO_PAGAMENTO'}
                width={'15.00vw'}
                control={control}
                options={newArrayPaymentWays}
                initialValue={0}
              />
              <SelectOptions
                label="Tipo Sub Modo de Pagamento"
                name={'SUB_MODO_PAGAMENTO'}
                width={'15.00vw'}
                control={control}
                options={newArraySubPaymentWays}
                initialValue={0}
              />
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
                // margin={'0 1.389vw 0 0'}
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
              <h5>Total de resultados: {paginationReports?.totalResult ?? 0}</h5>
            </SecondFilterLine>
          </form>
        </Filter>

        {loadingReports ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingReports}
            error={errorLoading}
            hasPagination={true}
            filter={filter}
            pagination={paginationReports}
            setFilter={setFilter}
            errorMessage={errorMessageReports}
            filterTable={filterTable}
            header={header}
            body={body}
            limit={filter.LIMIT}
          />
        )}
      </div>
    </Wrapper>
  );
}

export default memo(Report);
