import { Aprovado, Bloqueado, Pendente, Negado, Encerrado } from '../../assets/icon';
import * as Styled from './WhiteStatusContainer.styles';

export const WhiteStatus = (props) => {
  return (
    <Styled.status>
      {props.status === 'ATIVO' ? (
        <>
          <Aprovado width={'1rem'} heigth={'1rem'} color={'#2DC071'} />
          <Styled.textStatus colors={'#2DC071'}>{'APROVADO'}</Styled.textStatus>
        </>
      ) : props.status === 'AGUARDANDO' ? (
        <>
          <Pendente width={'1rem'} heigth={'1rem'} color={'#F29305'} />
          <Styled.textStatus colors={'#F29305'}>{'PENDENTE'}</Styled.textStatus>
        </>
      ) : props.status === 'BLOQUEADO' ? (
        <>
          <Bloqueado width={'1rem'} heigth={'1rem'} color={'#011C7B'} />
          <Styled.textStatus colors={'#011C7B'}>{'BLOQUEADO'}</Styled.textStatus>
        </>
      ) : props.status === 'NEGADO' ? (
        <>
          <Negado width={'1rem'} heigth={'1rem'} color={'#B80000'} />
          <Styled.textStatus colors={'#B80000'}>{'NEGADO'}</Styled.textStatus>
        </>
      ) : (
        <>
          <Encerrado width={'1rem'} heigth={'1rem'} color={'#262424'} />
          <Styled.textStatus colors={'#262424'}>{'ENCERRADO'}</Styled.textStatus>
        </>
      )}
    </Styled.status>
  );
};

export default WhiteStatus;
