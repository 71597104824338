import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingTariffs: true,
};

export const tariffsActionLoading = createAction('TARIFFS_ACTION_LOADING');
export const tariffsActionSuccess = createAction('TARIFFS_ACTION_SUCCESS');
export const tariffsActionError = createAction('TARIFFS_ACTION_ERROR');
export const editTariffsActionLoading = createAction('EDIT_TARIFFS_ACTION_LOADING');
export const editTariffsActionSuccess = createAction('EDIT_TARIFFS_ACTION_SUCCESS');
export const editTariffsActionError = createAction('EDIT_TARIFFS_ACTION_ERROR');
export const tariffsCreditActionLoading = createAction('TARIFFS_CREDIT_ACTION_LOADING');
export const tariffsCreditActionSuccess = createAction('TARIFFS_CREDIT_ACTION_SUCCESS');
export const tariffsCreditActionError = createAction('TARIFFS_CREDIT_ACTION_ERROR');
export const tariffsDebitActionLoading = createAction('TARIFFS_DEBIT_ACTION_LOADING');
export const tariffsDebitActionSuccess = createAction('TARIFFS_DEBIT_ACTION_SUCCESS');
export const tariffsDebitActionError = createAction('TARIFFS_DEBIT_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [tariffsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTariffs: true,
      errorTariffs: false,
      tariffsData: [],
    };
  },
  [tariffsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTariffs: false,
      errorTariffs: false,
      tariffsData: payload?.TARIFAS,
    };
  },
  [tariffsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingTariffs: false,
      errorTariffs: true,
      taxesData: [],
    };
  },
  [editTariffsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingEditTariffs: true,
      errorEditTariffs: false,
    };
  },
  [editTariffsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditTariffs: false,
      errorEditTariffs: false,
    };
  },
  [editTariffsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingTariffs: false,
      errorEditTariffs: true,
    };
  },
  [tariffsCreditActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingCreditTariffs: true,
      errorCreditTariffs: false,
    };
  },
  [tariffsCreditActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCreditTariffs: false,
      errorCreditTariffs: false,
    };
  },
  [tariffsCreditActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingCreditTariffs: false,
      errorCreditTariffs: true,
    };
  },
  [tariffsDebitActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDebitTariffs: true,
      errorDebitTariffs: false,
    };
  },
  [tariffsDebitActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDebitTariffs: false,
      errorDebitTariffs: false,
    };
  },
  [tariffsDebitActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDebitTariffs: false,
      errorDebitTariffs: true,
    };
  },
});
