import api from '../../utils/services/api';

import { transactionsActionError, transactionsActionLoading, transactionsActionSuccess } from '../ducks/transactions';

export const transactionsFetchAction = (params) => {
  return async (dispatch) => {
    dispatch(transactionsActionLoading());
    try {
      const response = await api.get(
        `/transacoes-dashboard/grafico?DATA_INICIAL=${params.dataMenoSete}&DATA_FINAL=${params.dataHoje}`,
      );
      dispatch(transactionsActionSuccess(response.data));
    } catch (error) {
      dispatch(transactionsActionError(error?.response?.data));
    }
  };
};
