import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import Modal from '../../../../components/Modal/Modal';
import { ButtonContainer, SmallerModal } from '../../styles/GerenciarContas.styles';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button/Button';
import { useTheme } from 'styled-components';
import { deniedAccountsFetchAction } from '../../../../store/fetchActions/accountActions';

const ModalDenyAccount = ({ open, close, idConta }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const submitDenyForm = (data) => {
    const param = { ID_CONTA: idConta, OBSERVACOES: data.justificativa };
    dispatch(deniedAccountsFetchAction(param, close));
  };

  return (
    <Modal open={open} close={close}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>Negar abertura de conta</h3>
        <h5>Esta ação negará a abertura da conta solicitada</h5>
        <h4>Justificativa</h4>
        <form onSubmit={handleSubmit(submitDenyForm)}>
          <TextField
            variant={'outlined'}
            name={'justificativa'}
            width={'28.342vw'}
            multiline={true}
            minRows={3}
            required={true}
            size="large"
            control={control}
            inputProps={{ maxLength: 100 }}
          />
          <ButtonContainer>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={close}
            >
              <span>Voltar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonNegaded}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonNegadedHover}
              hovercolor={theme.colors.buttonNegadedHoverText}
              mycolor={theme.colors.buttonNegadedText}
              type="submit"
            >
              <span>Negar</span>
            </Button>
          </ButtonContainer>
        </form>
      </SmallerModal>
    </Modal>
  );
};

export default ModalDenyAccount;
