import React from 'react';
import Lottie from 'react-lottie-player';
import Button from '../../components/Button/Button';
import lottieJson from '../../assets/lottie/error404';
import * as Styled from './NotFound.styles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

export default function NotFound() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Styled.Container>
      <Styled.InfoContainer>
        <h4>Página não encontrada</h4>
        <h5>A página que tentou acessar está indisponível no momento ou não existe.</h5>
      </Styled.InfoContainer>
      <Button
        backgroundcolor={theme.colors.buttonSearch}
        width="20.153vw"
        hoverbgcolor={theme.colors.buttonSearchHover}
        hovercolor={theme.colors.buttonSearchHoverText}
        color={theme.colors.buttonSearchText}
        margin={'0 0 14.5rem 0'}
        type="submit"
        onClick={() => navigate('./dashboard')}
        roleName="button-ir-dashboard"
      >
        <span>IR PARA DASHBOARD</span>
      </Button>
    </Styled.Container>
  );
}
