import api from '../../utils/services/api';

import { ocrError, ocrLoading, ocrSuccess, ocrReset } from '../ducks/ocrServices';
import { showAlert } from '../ducks/sweetAlert';

export const ocrUpload = (params) => {
  return async (dispatch) => {
    dispatch(ocrLoading());
    try {
      const response = await api.post(`/ocr/documentosDadosValidar`, params);
      dispatch(ocrSuccess(response?.data?.resposta?.response?.externalApiResponse?.data));
    } catch (error) {
      dispatch(ocrError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const ocrStatusReset = (params) => {
  return async (dispatch) => {
    try {
      dispatch(ocrReset());
    } catch (error) {}
  };
};

export const ocrMatchConsultaStatus = (params, callback = () => undefined) => {
  return async (dispatch) => {
    try {
      const response = await api.post('/ocr/existeConsultaOCR', params);
      callback(response?.data);
    } catch (error) {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};
