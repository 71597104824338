import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import TextField from '../../../../components/TextField';
import { editPerfisFetchAction } from '../../../../store/fetchActions/perfisActions';
import * as Styled from '../../PerfisUsuarios.styles';

const EditPerfil = ({ open, close, info }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const callEditProfileAction = (data) => {
    data.NOME
      ? dispatch(editPerfisFetchAction(data.NOME, info.id, close))
      : dispatch(editPerfisFetchAction(info.name, info.id, close));
  };

  return (
    <Modal open={open} close={close} title="Editar Perfil">
      <form onSubmit={handleSubmit(callEditProfileAction)}>
        <Styled.ContainerCollumn>
          <TextField control={control} label={'Nome'} value={info?.name} width={'29.306vw'} name={'NOME'} />
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width={'29.306vw'}
            height={'3.125vw'}
            margin={'1vw 0'}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            type="submit"
          >
            <span>Salvar</span>
          </Button>
        </Styled.ContainerCollumn>
      </form>
    </Modal>
  );
};

export default EditPerfil;
