import { createAction, createReducer } from '@reduxjs/toolkit';
import api from '../../utils/services/api';
import { showAlert } from './sweetAlert';

// Action Types

export const creditTransactionLoading = createAction('CREDIT_TRANSACTION_LOADING');
export const creditTransactionSuccess = createAction('CREDIT_TRANSACTION_SUCCESS');
export const creditTransactionError = createAction('CREDIT_TRANSACTION_ERROR');

export const debitTransactionLoading = createAction('DEBIT_TRANSACTION_LOADING');
export const debitTransactionSuccess = createAction('DEBIT_TRANSACTION_SUCCESS');
export const debitTransactionError = createAction('DEBIT_TRANSACTION_ERROR');

// Initial State
const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducer
export default createReducer(INITIAL_STATE, {
  [creditTransactionLoading.type]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [creditTransactionSuccess.type]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [creditTransactionError.type]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      error: payload,
    };
  },
  [debitTransactionLoading.type]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [debitTransactionSuccess.type]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [debitTransactionError.type]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      error: payload,
    };
  },
});