import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';

export const PaginationComponent = styled(Pagination)`
  margin: 1vw 0;
  display: flex;
  justify-content: center;

  .MuiPaginationItem-page {
    background-color: ${(props) => props.theme.colors.paginationEvent};
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: ${(props) => props.theme.colors.pagination};
    color: ${(props) => props.theme.colors.paginationText} !important;
  }
`;
