import api from '../../utils/services/api';
import { blockUserActionLoading, blockUserActionSuccess, blockUserActionError } from '../ducks/blockUser';
import { showAlert } from '../ducks/sweetAlert';

export const blockUserFetchAction = (params, callback) => {
  return async (dispatch) => {
    dispatch(blockUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`usuario/bloquear`, params);
      dispatch(blockUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Usuário bloqueado com sucesso!',
          icon: 'success',
        }),
      );
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage,
          icon: 'error',
        }),
      );
      dispatch(blockUserActionError(error?.response?.data));
    }
  };
};

export const logOutUserFetchAction = (params, callback) => {
  return async (dispatch) => {
    dispatch(showAlert({}));
    try {
      const response = await api.post(`usuario/deslogar`, params);
      dispatch(blockUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
        }),
      );
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage,
          icon: 'error',
        }),
      );
    }
  };
};
