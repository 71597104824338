import styled from 'styled-components';
import { TextField as InputMaterial } from '@material-ui/core';

export const Input = styled(InputMaterial)`
  width: ${(props) => props.width};
  background-color: ${(props) => props.backcolor + ' !important'};
  border-radius: ${(props) => props.radius ?? '5px'};

  .MuiInputBase-input {
    color: ${(props) => props.color + '!important'};
  }

  input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.veryLightGray_4} inset;
  }

  & label {
    z-index: 1;
    color: ${(props) => props.labelcolor + ' !important'};
    margin-left: ${(props) => props.spacelabelinput ?? '0.2vw'};
  }

  & label.Mui-focused {
    color: ${(props) => props.labelfocuscolor + ' !important'};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${(props) => props.borderfocuscolor + ' !important'};
  }

  & .MuiFilledInput-underline:after {
    border-bottom-color: ${(props) => props.borderfocuscolor + ' !important'};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => props.borderfocuscolor + ' !important'};
    }
  }

  &.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputBase-input.MuiInput-input {
    margin-left: ${(props) => props.spacelabelinput ?? '0.2vw'};
  }

  .MuiFormLabel-root.Mui-disabled {
    font-size: 14px;
    font-weight: 800;
  }
`;
