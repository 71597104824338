import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import Filter from '../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import SelectOptions from '../../components/SelectOptions';
import Table from '../../components/Table/Table';
import Wrapper from '../../components/Wrapper/Wrapper';
import { faceMatchOcrRelatorio } from '../../store/fetchActions/faceMatchServicesActions';
import DetalhesOcrFaceMatch from './components/modals/DetalhesOcrFaceMatch';
import formatDateHora from '../../utils/functions/formatDateHora';
import { optionsTypeFaceOcr, pagesOptions } from '../../utils/constant';
import TextField from '../../components/TextField';
import Loading from '../../components/Loading';
import DatePicker from '../../components/DateInput';
import moment from 'moment';
import { useTheme } from 'styled-components';

const RelatorioAntifraude = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [detalhes, setDetalhes] = useState([]);
  const [openDetalhes, setOpenDetalhes] = useState(false);
  const [filter, setFilter] = useState({
    DATA_INICIAL: '',
    DATA_FINAL: '',
    SEARCH: '',
    TIPO: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial ?? '';
    data.DATA_FINAL = dateFinal ?? '';

    setFilter({ ...data, PAGE: 1 });
    dispatch(faceMatchOcrRelatorio(data));
  };

  useEffect(() => {
    dispatch(faceMatchOcrRelatorio(filter));
  }, [filter.PAGE]);

  const {
    loadingFaceMatchOcrRelatorio,
    dataFaceMatchOcrRelatorio,
    errorFaceMatchOcrRelatorio,
    paginationFaceMatchOcrRelatorio,
    errorMenssagemFaceMatchOcrRelatorio,
  } = useSelector((state) => state.faceMatch);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA/HORA',
      align: 'center',
    },
    {
      id: 'cpfcnpj',
      name: 'CPF/CNPJ CLIENTE',
      align: 'center',
    },
    {
      id: 'nome',
      name: 'NOME CLIENTE',
      align: 'center',
    },
    {
      id: 'tipo',
      name: 'TIPO',
      align: 'center',
    },
    {
      id: 'id',
      name: 'ID',
      align: 'center',
    },
  ];

  const body = dataFaceMatchOcrRelatorio?.map((report) => {
    return {
      data: [
        { info: formatDateHora(report.DATAHORA_CONSULTA), align: 'center' },
        { info: report.CPFCNPJ_CONSULTA, align: 'center' },
        { info: report.NOME, align: 'center' },
        { info: report.TIPO_SERVICO, align: 'center' },
        { info: report.ID, align: 'center' },
      ],
      onClick: () => inspect(report.ID, report.CPFCNPJ_CONSULTA),
    };
  });

  const inspect = (id, cpf) => {
    setOpenDetalhes(true);
    setDetalhes({ ID: id, CPFCNPJ_USUARIO: cpf });
  };

  return (
    <Wrapper title="Relatório Antifraude">
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'22.042vw'} control={control} />
            <SelectOptions
              label={'Tipo'}
              control={control}
              width={'18.042vw'}
              options={optionsTypeFaceOcr}
              name={'TIPO'}
              initialValue={0}
            />
            <DatePicker
              label="Data Inicial"
              value={dateInicial}
              name={'DATA_INICIAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDate('')
              }
            />
            <DatePicker
              label="Data Final"
              value={dateFinal}
              name={'DATA_FINAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDate('')
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions
              control={control}
              options={pagesOptions}
              name={'LIMIT'}
              margin={'0 0 0 1.875vw'}
              initialName={10}
              initialValue={10}
            />
            <h5>Total de resultados: {paginationFaceMatchOcrRelatorio?.totalResult ?? 0}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingFaceMatchOcrRelatorio ? (
        <Loading />
      ) : (
        <Table
          loading={loadingFaceMatchOcrRelatorio}
          error={errorFaceMatchOcrRelatorio}
          errorMessage={errorMenssagemFaceMatchOcrRelatorio}
          hasPagination={true}
          filter={filter}
          pagination={paginationFaceMatchOcrRelatorio}
          setFilter={setFilter}
          filterTable={filterTable}
          header={header}
          body={body}
          limit={filter.LIMIT}
        />
      )}

      {openDetalhes && (
        <DetalhesOcrFaceMatch open={openDetalhes} close={() => setOpenDetalhes(false)} info={detalhes} />
      )}
    </Wrapper>
  );
};

export default RelatorioAntifraude;
