import api from '../../utils/services/api';
import { showAlert } from '../ducks/sweetAlert';

import {
  tariffsActionError,
  tariffsActionLoading,
  tariffsActionSuccess,
  editTariffsActionError,
  editTariffsActionLoading,
  editTariffsActionSuccess,
  tariffsCreditActionError,
  tariffsCreditActionLoading,
  tariffsCreditActionSuccess,
  tariffsDebitActionError,
  tariffsDebitActionLoading,
  tariffsDebitActionSuccess,
} from '../ducks/tariffs';
import { userBalanceFetchAction } from './usersActions';

export const tariffsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(tariffsActionLoading());
    try {
      const response = await api.get(`/coban/tarifasServicos/listar?ID_COBAN=${id}`);
      dispatch(tariffsActionSuccess(response.data));
    } catch (error) {
      dispatch(tariffsActionError(error?.response?.data));
    }
  };
};

export const editTariffsFetchAction = (data, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch(editTariffsActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/coban/atualizar/tarifas`, data);
      dispatch(editTariffsActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Tarifas editadas com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editTariffsActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const tariffsCreditFetchAction = (param, callback, CPFCNPJ_CLIENTE) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(
        userBalanceFetchAction({
          ID_CONTA: param?.ID_CONTA,
          CPFCNPJ_CLIENTE,
          LIMIT: 10,
          PAGE: 1,
        }),
      );
    };
    dispatch(tariffsCreditActionLoading());
    try {
      const response = await api.post(`/transacao/credito-manutencao/adm`, param);
      dispatch(tariffsCreditActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Crédito realizado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(tariffsCreditActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const tariffsDebitFetchAction = (param, callback, CPFCNPJ_CLIENTE) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(
        userBalanceFetchAction({
          ID_CONTA: param?.ID_CONTA,
          CPFCNPJ_CLIENTE,
          LIMIT: 10,
          PAGE: 1,
        }),
      );
    };
    dispatch(tariffsDebitActionLoading());
    try {
      const response = await api.post(`/transacao/debito-manutencao/adm`, param);
      dispatch(tariffsDebitActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Débito realizado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(tariffsDebitActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
