import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  blockuserData: [],
  paginationBlockUsern: { totalResult: 0 },
  errorBlockUser: false,
  loadingBlockUser: true,
  errorMessageBlockUser: '',
};

export const blockUserActionLoading = createAction('BLOCK_USER_ACTION_LOADING');
export const blockUserActionSuccess = createAction('BLOCK_USER_ACTION_SUCCESS');
export const blockUserActionError = createAction('BLOCK_USER_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [blockUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingBlockUser: true,
      errorBlockUser: false,
      blockUserData: [],
      paginationBlockUser: {},
    };
  },
  [blockUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockUser: false,
      errorBlockUser: false,
      blockUserData: payload.DATA,
      paginationBlockUser: payload.PAGINATION,
    };
  },
  [blockUserActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockUser: false,
      errorBlockUser: true,
      blockUserData: [],
      paginationBlockUser: {},
      errorMessageBlockUser: payload?.mensagem,
    };
  },
});
