import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingValidatePassword: true,
  errorValidatePassword: false,
  errorMessageValidatePassword: '',
  validatePassword: {},
};

export const validatePasswordActionError = createAction('VALIDATE_PASSWORD_ACTION_ERROR');
export const validatePasswordActionLoading = createAction('VALIDATE_PASSWORD_ACTION_LOADING');
export const validatePasswordActionSuccess = createAction('VALIDATE_PASSWORD_ACTION_SUCCESS');

export default createReducer(INITIAL_STATE, {
  [validatePasswordActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingValidatePassword: true,
      errorValidatePassword: false,
      validatePassword: {},
    };
  },
  [validatePasswordActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingValidatePassword: false,
      errorValidatePassword: false,
      validatePassword: payload,
    };
  },
  [validatePasswordActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingValidatePassword: false,
      errorValidatePassword: true,
      errorMessageValidatePassword: payload?.mensagem,
      validatePassword: {},
    };
  },
});
