import Styled from 'styled-components';
import { Popper, Button, Grow } from '@material-ui/core';

const widthButtonsGroup = '15vw';

export const PopperButtonsGroup = Styled(Popper)`
  z-index: 1;
  width: ${widthButtonsGroup};

  li {
    background: ${(props) => props.theme.colors.dropDownLI};
    margin: 0.3vw;
    border-radius: 4px;
    font-weight: 500;
    height: 2.7vw;

    :hover {
      background:  ${(props) => props.theme.colors.dropDownLIHover};
      color: ${(props) => props.theme.colors.dropDownLIHoverText};
      transition: all 0.3s;
      transform: scale(1.1);
    }
  }

  .MuiButtonBase-root.MuiListItem-root {
    padding-left: 1vw;
  }

  .Mui-selected:hover {
    background: ${(props) => props.theme.colors.dropDownLIHover};
  }
`;

export const ButtonOptions = Styled(Button)`
  background-color: ${(props) => props.theme.colors.dropDownButton};
  color:${(props) => props.theme.colors.dropDownButtonText};
  width: ${widthButtonsGroup};
  height: 3.333vw;

  &:hover {
    background-color: ${(props) => props.theme.colors.dropDownButtonHover};
    color: ${(props) => props.theme.colors.dropDownButtonHoverText};
  }
`;

export const GrowButtonsGroup = Styled(Grow)`
  transform-origin: ${(props) => props.transformOrigin};
`;
