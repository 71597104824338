import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import TextField from '../../../../components/TextField';
import { createPerfisFetchAction } from '../../../../store/fetchActions/perfisActions';
import * as Styled from '../../PerfisUsuarios.styles';

const CreatePerfil = ({ open, close }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const callCreateProfileAction = (data) => {
    dispatch(createPerfisFetchAction(data.NOME, close));
  };

  return (
    <Modal open={open} close={close} title="Criar Perfil">
      <form onSubmit={handleSubmit(callCreateProfileAction)}>
        <Styled.ContainerCollumn>
          <TextField control={control} title={'Nome'} disabled={false} width={'29.306vw'} name={'NOME'} required />
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width={'29.306vw'}
            height={'3.125vw'}
            margin={'1vw 0 '}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            type="submit"
          >
            <span>CRIAR</span>
          </Button>
        </Styled.ContainerCollumn>
      </form>
    </Modal>
  );
};

export default CreatePerfil;
