import { useState, useEffect } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import TextField from '../../../../../components/TextField';
import { useForm } from 'react-hook-form';
import SelectOptions from '../../../../../components/SelectOptions';
import DateInput from '../../../../../components/DateInput';
import * as S from './AddRepresentativeModal.styles.';
import { useDispatch, useSelector } from 'react-redux';
import {
  listTypeSignatoriesAction,
  listTypeRepresentative,
  listDocumentsRepresentative,
  resetListDocumentsRepresentative,
  insertRepresentativeAction,
  approveComiteAction,
  SendDocumentsAllAction,
} from '../../../../../store/fetchActions/approveUserActions';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import InputMask from '../../../../../components/InputMask';
import Button from '../../../../../components/Button/Button';
import { MdAttachFile } from 'react-icons/md';
import convertFileToBase64 from '../../../../../utils/functions/convertFileToBase64';
import { CheckFullCircle, ExclamationTriagle } from '../../../../../assets/icon/index';
import { showAlert } from '../../../../../store/ducks/sweetAlert';
import getDateH from '../../../../../utils/functions/formatDateHora';
import { useTheme } from 'styled-components';
import moment from 'moment';
import Loading from '../../../../../components/Loading';

export default function AddRepresentativeModal({ openModal, closeModal }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [radio, setRadio] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [signatarioId, setSignatarioId] = useState();
  const [cpf, setCpf] = useState();
  const [phone, setPhone] = useState();
  const [cep, setCep] = useState();
  const [nascimento, setNascimento] = useState(null);

  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    dispatch(resetListDocumentsRepresentative());
    dispatch(listTypeSignatoriesAction());
    dispatch(listTypeRepresentative());
  }, []);

  const { listSignatories, listRepresentative, listDocumentRepresentative, loadingListDocumentsRepresentative } =
    useSelector((state) => state.approveUser);
  const { usersData } = useSelector((state) => state.user);

  const arrayListSignatorie = listSignatories?.map((val) => {
    return { ID: val.ID, NOME: val.DESCRICAO };
  });

  const arrayListRepresentative = listRepresentative?.map((val) => {
    return { ID: val.CODTIPOREPRESENTANTE, NOME: val.DESCRICAO };
  });

  const changeComite = (event) => {
    setRadio(event.target.value);
  };

  const myDocuments = (val) => {
    setSelectedFile([]);
    let param = {};
    val === '0'
      ? (param = {
          PARAMETRIZACAOCLIENTE: 'S',
          CODTIPOREPRESENTANTE: val,
        })
      : (param = {
          PARAMETRIZACAOCLIENTE: 'N',
          CODTIPOREPRESENTANTE: val,
        });
    dispatch(listDocumentsRepresentative(param));
  };

  const onChangeSelectedFile = (event, code) => {
    let deleteClone = selectedFile?.filter((e) => e.codeDocument !== code);

    setSelectedFile([
      ...deleteClone,
      {
        name: event?.target?.files[0].name,
        file: event?.target?.files[0],
        selected: true,
        codeDocument: code,
      },
    ]);
  };

  const activeDocument = (data) => {
    return selectedFile.filter((e) => e.codeDocument === data);
  };

  const convertImage = async (val) => {
    return await convertFileToBase64(val)?.then((result) => result);
  };

  const newRepresentative = async (data) => {
    data.CPFCNPJ_EMPRESA = usersData?.CPFCNPJ;
    data.CPFCNPJ_REPRESENTANTE = cpf;
    data.CELULAR = phone;
    data.CEP = cep;
    data.NASCIMENTO = getDateH(nascimento);

    const arrayDocs = await Promise.all(
      selectedFile?.map(async (val) => {
        try {
          const image = await convertImage(val?.file);
          return { TIPO: val.codeDocument, IMAGEM: image };
        } catch (error) {
          return { TIPO: undefined, IMAGEM: undefined };
        }
      }),
    );

    selectedFile.length !== listDocumentRepresentative.length
      ? dispatch(
          showAlert({
            title: 'Documento pendente',
            text: 'Faltou enviar algum dos documentos. Verifique e tente novamente.',
            icon: 'warning',
          }),
        )
      : dispatch(
          insertRepresentativeAction(data, (res) => {
            res &&
              radio &&
              dispatch(
                approveComiteAction(
                  {
                    representantes: [
                      {
                        CPFCNPJ_USUARIO: data.CPFCNPJ_REPRESENTANTE,
                        CPFCNPJ_EMPRESA: data.CPFCNPJ_EMPRESA,
                        EMAIL: data.EMAIL,
                      },
                    ],
                  },
                  false,
                ),
              );

            res &&
              dispatch(
                SendDocumentsAllAction(
                  {
                    CPFCNPJ: data.CPFCNPJ_REPRESENTANTE,
                    CODTIPOREPRESENTANTE: data.CODTIPOREPRESENTANTE,
                    CPFCNPJCLIENTE: data.CPFCNPJ_EMPRESA,
                    DOCS: arrayDocs,
                  },
                  closeModal(),
                ),
              );
          }),
        );
  };

  return (
    <Modal open={openModal} close={closeModal} title="Cadastrar novo representante" width={'56.222vw'}>
      <form onSubmit={handleSubmit(newRepresentative)}>
        <h1>Informações pessoais</h1>
        <S.Row>
          <S.InputContainer>
            <TextField control={control} label={'NOME'} width={'23.958vw'} name={'NOME'} />
          </S.InputContainer>
          <S.InputContainer>
            <DateInput
              name={'NASCIMENTO'}
              label={'Data de nascimento'}
              width={'23.958vw'}
              control={control}
              value={nascimento}
              required
              onChange={(newValue) =>
                newValue?.$d ? setNascimento(moment(newValue?.$d).format('YYYY-MM-DD')) : setNascimento('')
              }
            />
          </S.InputContainer>
          <S.InputContainer>
            <InputMask
              name="CPFCNPJ"
              mask="999.999.999-99"
              label={'CPF'}
              width={'23.958vw'}
              onChange={(e) => setCpf(e)}
              control={control}
            />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'E-mail'} type={'email'} width={'23.958vw'} name={'EMAIL'} />
          </S.InputContainer>
          <S.InputContainer>
            <InputMask
              name="CELULAR"
              label={'Celular'}
              mask="(99) 99999-9999"
              onChange={(e) => setPhone(e)}
              width={'23.958vw'}
              control={control}
            />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'Nome da mãe'} width={'23.958vw'} name={'NOME_MAE'} />
          </S.InputContainer>
          <S.InputContainer>
            <SelectOptions
              width={'23.958vw'}
              label={'Status do representante'}
              name={'CODTIPOREPRESENTANTE'}
              control={control}
              options={arrayListRepresentative}
              required
              onClick={(e) => {
                myDocuments(e?.ID);
              }}
            />
          </S.InputContainer>
          <S.InputContainer>
            <SelectOptions
              width={'23.958vw'}
              label={'Signatário'}
              name={'SIGNATARIO'}
              control={control}
              options={arrayListSignatorie}
              required
              onClick={(e) => setSignatarioId(e?.ID)}
            />
          </S.InputContainer>

          {signatarioId !== '1' && (
            <S.RadioContainer>
              <S.RadioLabel>O signatário faz parte do comitê?</S.RadioLabel>
              <RadioGroup row name="comite" value={radio} onChange={(e) => changeComite(e)}>
                <FormControlLabel value={true} control={<Radio />} label="SIM" />
                <FormControlLabel value={false} control={<Radio />} label="NÂO" />
              </RadioGroup>
            </S.RadioContainer>
          )}
        </S.Row>

        <S.Row>
          <S.InputContainer>
            <InputMask
              name="CEP"
              mask="99999-999"
              label={'CEP'}
              onChange={(e) => setCep(e)}
              width={'23.958vw'}
              control={control}
            />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'RUA'} width={'23.958vw'} name={'ENDERECO'} />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'Número'} width={'23.958vw'} name={'NUMENDERECO'} />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'Complemento'} width={'23.958vw'} name={'COMPLEMENTO'} />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'Bairro'} width={'23.958vw'} name={'BAIRRO'} />
          </S.InputContainer>
          <S.InputContainer>
            <TextField control={control} label={'Cidade'} width={'23.958vw'} name={'CIDADE'} />
          </S.InputContainer>
          <S.InputContainer>
            <TextField
              control={control}
              label={'Estado'}
              width={'23.958vw'}
              name={'UF'}
              inputProps={{ maxLength: 2 }}
            />
          </S.InputContainer>
        </S.Row>

        {listDocumentRepresentative?.length > 0 && (
          <S.AnexoContainer>
            <S.TitleAnexos>Anexar documentos</S.TitleAnexos>
            <S.SubTitleAnexos>Faça o upload dos documentos do representante para finalizar o cadastro</S.SubTitleAnexos>
            <hr />
            {loadingListDocumentsRepresentative ? (
              <Loading />
            ) : (
              listDocumentRepresentative?.map((val, indx) => (
                <>
                  <S.ContainerUploadFiles key={indx + '-SendDocuments'}>
                    <S.InfoAnexo>
                      {activeDocument(val.COD)[0]?.selected ? <CheckFullCircle /> : <ExclamationTriagle />}
                      <h2>{val?.DESCRICAO}</h2>
                    </S.InfoAnexo>

                    <S.ButtonAnexo htmlFor={'contained-button-file-' + indx}>
                      <S.Input
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id={'contained-button-file-' + indx}
                        type="file"
                        onChange={(event) => {
                          onChangeSelectedFile(event, val.COD);
                        }}
                      />

                      <Button
                        width="9vw"
                        height="2.2vw"
                        backgroundcolor={theme.colors.strongCyan}
                        hoverbgcolor={theme.colors.strongCyan}
                        mycolor={theme.colors.white}
                        hovercolor={theme.colors.white}
                        component="span"
                      >
                        <span>Selecionar</span>
                      </Button>
                    </S.ButtonAnexo>

                    <S.NameAnexoContainer>
                      {activeDocument(val.COD).length > 0 ? (
                        <S.NameAnexo>
                          <MdAttachFile size={'1.3vw'} />
                          <h2>{activeDocument(val.COD)[0]?.name}</h2>
                        </S.NameAnexo>
                      ) : (
                        <S.NameAnexo>
                          <MdAttachFile size={'1.3vw'} />
                          <h2>Selecione o documento</h2>
                        </S.NameAnexo>
                      )}
                    </S.NameAnexoContainer>
                  </S.ContainerUploadFiles>
                  <hr />
                </>
              ))
            )}
          </S.AnexoContainer>
        )}
        <S.ContainerButtom>
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width={'100%'}
            height={'3.333vw'}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            type="submit"
          >
            <span>ADICIONAR</span>
          </Button>
        </S.ContainerButtom>
      </form>
    </Modal>
  );
}
