import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import UsuariosPendentes from './TabContent/UsuariosPendentes';
import Tabs from '../../components/Tabs/Tabs';
import UsuariosAprovados from './TabContent/UsuariosAprovados';
import UsuariosNegados from './TabContent/UsuariosNegados';
import UsuariosBloqueados from './TabContent/UsuariosBloqueados';

const labels = ['Pendentes', 'Aprovados', 'Negados', 'Bloqueados'];

export default function GerenciarClientes() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Wrapper title="Gerenciar Clientes" margin="4.1vw 0">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" />
      {tabIndex == 0 && <UsuariosPendentes value={tabIndex} index={0} />}
      {tabIndex == 1 && <UsuariosAprovados value={tabIndex} index={1} />}
      {tabIndex == 2 && <UsuariosNegados value={tabIndex} index={2} />}
      {tabIndex == 3 && <UsuariosBloqueados value={tabIndex} index={3} />}
    </Wrapper>
  );
}
