import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core/';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'center'} !important;
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 2.778vw;
  height: 2.778vw;
  margin: 0 1.389vw;
  background-color: ${(props) => props.backgroundcolor} !important;
  color: ${(props) => props.color} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
`;

export const ModalLabel = styled(Typography)`
  width: 100%;
  font-weight: 600;
  margin: 0.5vw;
`;

export const ButtonStatusUsers = styled(Button)`
  background-color: ${(props) => props.backgroundcolor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.styledcolor};
  &:hover {
    background-color: ${(props) => props.hoverbgcolor};
    color: ${(props) => props.hovercolor};
  }
  position: absolute;

  top: 64px;
  margin-top: 4.1vw;

  & .plus-span {
    font-size: 1.6rem;
    margin-right: 10px;
  }
`;

export const Label = styled.p`
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
`;

export const SectionInputs = styled.div`
  margin: 0 0 1vw 0;
`;

export const Line = styled.hr`
  margin: 0 0 1vw 0;
`;

export const ContainerCollumn = styled.div`
  display: flex;
  flex-direction: column;
`;
