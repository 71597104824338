import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import * as Styled from './Modal.styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ open, close, title, width, height, roleName, children, noClose }) => {
  const onClose = () => close();

  return (
    <Styled.ModalStyled
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
      width={width}
      height={height}
      role={roleName}
    >
      <MuiDialogTitle>
        <Styled.ModalHeader>
          <Styled.TitleStyled>{title}</Styled.TitleStyled>
          {!noClose && (
            <Styled.IconStyled aria-label="close" role={'close-modal'} onClick={onClose}>
              <CloseIcon />
            </Styled.IconStyled>
          )}
        </Styled.ModalHeader>
      </MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
    </Styled.ModalStyled>
  );
};

export default Modal;

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  height: PropTypes.string,
  width: PropTypes.string,
  close: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  roleName: PropTypes.string,
};
