import api from '../../utils/services/api';

import {
    limitBalanceActionError,
    limitBalanceActionLoading,
    limitBalanceActionSuccess,
} from '../ducks/limitBalance';

export const limitBalanceFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
    return async (dispatch) => {
        dispatch(limitBalanceActionLoading());
        try {
            const response = await api.post(`usuario/listar/aprovado`, filtro);
            dispatch(limitBalanceActionSuccess(response.data));
        } catch (error) {
            dispatch(limitBalanceActionError(error?.response?.data));
        }
    };
};
