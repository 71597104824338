import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';
import { CheckCircle, DeniCircle } from '../../../../assets/icon';
import { useState } from 'react';
import formatPhoneNumber from '../../../../utils/functions/formatPhoneNumber';
import formatCEP from '../../../../utils/functions/formatCEP';
import formatCNPJ from '../../../../utils/functions/formatCNPJ';
import formatCPF from '../../../../utils/functions/formatCPF';
import Information from '../../../../components/Information/Information';
import DocumentRow from '../Collums/DocumentRow';
import { useSelector } from 'react-redux';
import { approvedAccountsFetchAction } from '../../../../store/fetchActions/accountActions';
import ModalDenyAccount from './ModalDenyAccount';

const ModalContasPendentes = ({ open, close, idConta }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.buttonNegadedText);
  const [isModalDenyOpen, setIsModalDenyOpen] = useState(false);

  const { handleSubmit } = useForm({});

  const { conta, contato, endereco, documentos, cliente } = useSelector((state) => state.account.detailAccountsData);

  const { loadingDetailAccounts } = useSelector((state) => state.account);

  const infoData = [
    cliente?.CPFCNPJ?.length === 11
      ? { name: 'CPF', value: formatCPF(cliente?.CPFCNPJ) }
      : { name: 'CNPJ', value: formatCNPJ(cliente?.CPFCNPJ) },
    { name: 'Nome', value: cliente?.NOME || '-----' },
    { name: 'E-mail', value: contato?.EMAIL || '-----' },
    { name: 'Data de Criação', value: conta?.DT_INSERT || '-----' },
    {
      name: 'Telefone',
      value: formatPhoneNumber(contato?.FONECEL) || '-----',
    },
    { name: 'CEP', value: formatCEP(endereco?.CEP) || '-----' },
    { name: 'Endereço', value: endereco?.ENDERECO || '-----' },
    { name: 'Bairro', value: endereco?.BAIRRO || '-----' },
    { name: 'Complemento', value: endereco?.COMPLEMENTO || '-----' },
    { name: 'Cidade', value: endereco?.CIDADE || '-----' },
    { name: 'UF', value: endereco?.UF || '-----' },
  ];

  const submitApprovation = () => {
    const param = { ID_CONTA: idConta };
    dispatch(approvedAccountsFetchAction(param, close));
  };

  return (
    <Modal open={open} close={close} title="Abertura de nova conta" width={'39.889vw'}>
      {loadingDetailAccounts ? (
        <Loading />
      ) : (
        <div role="modal-dados-cliente">
          <Information title="Informações Pessoais" data={infoData} />
          <DocumentRow document={documentos} />
          <form onSubmit={handleSubmit(submitApprovation)}>
            <Button
              backgroundcolor={theme.colors.buttonApproved}
              width="35.111vw"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonApprovedHover}
              hovercolor={theme.colors.buttonApprovedHoverText}
              mycolor={theme.colors.buttonApprovedText}
              type="submit"
              margin={'2.083vw 0 0 0'}
            >
              <CheckCircle />
              <span>Aprovar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonNegaded}
              width="35.111vw"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonNegadedHover}
              hovercolor={theme.colors.buttonNegadedHoverText}
              mycolor={theme.colors.buttonNegadedText}
              onClick={() => setIsModalDenyOpen(true)}
              margin={'0.694vw 0 2.083vw 0'}
              onMouseOver={() => setHoverColorDeni(theme.colors.buttonNegadedHoverText)}
              onMouseOut={() => setHoverColorDeni(theme.colors.buttonNegadedText)}
            >
              <DeniCircle color={hoverColorDeni} />
              <span>Negar</span>
            </Button>
          </form>
        </div>
      )}

      {isModalDenyOpen && (
        <ModalDenyAccount open={isModalDenyOpen} close={() => setIsModalDenyOpen(false)} idConta={idConta} />
      )}
    </Modal>
  );
};

export default ModalContasPendentes;
