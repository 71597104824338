import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as Styled from './TextField.styles';

const TextField = ({
  label,
  disabled,
  value,
  width,
  backcolor,
  required,
  name,
  control,
  error,
  type,
  InputProps,
  inputProps,
  multiline,
  minRows,
  fullWidth,
  variant,
  mycolor,
  onInput,
  onKeyPress,
  labelcolor,
  labelfocuscolor,
  borderfocuscolor,
  InputLabelProps,
  spacelabelinput,
  autofill,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <Styled.Input
          {...field}
          width={width}
          error={error}
          defaultValue={field.value}
          disabled={disabled}
          required={required}
          InputProps={InputProps}
          inputProps={inputProps}
          type={type}
          multiline={multiline}
          minRows={minRows}
          fullWidth={fullWidth}
          label={label}
          variant={variant ?? 'standard'}
          styledcolor={mycolor}
          backcolor={backcolor}
          labelcolor={labelcolor}
          labelfocuscolor={labelfocuscolor}
          borderfocuscolor={borderfocuscolor}
          onInput={onInput}
          onChange={field.onChange}
          onKeyPress={onKeyPress}
          InputLabelProps={InputLabelProps}
          ref={field.ref}
          spacelabelinput={spacelabelinput}
          autofill={autofill}
        />
      )}
    ></Controller>
  );
};

export default TextField;

TextField.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  backcolor: PropTypes.string,
  labelcolor: PropTypes.string,
  labelfocuscolor: PropTypes.string,
  borderfocuscolor: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onKeyPress: PropTypes.func,
};
