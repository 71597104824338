export default function getDateH(param) {
  const arrayDH = param.split(' ');
  const arrayDate = arrayDH[0].split('-');

  if (arrayDH[1]) {
    return arrayDate[2] + '/' + arrayDate[1] + '/' + arrayDate[0] + ' ' + arrayDH[1];
  } else {
    return arrayDate[2] + '/' + arrayDate[1] + '/' + arrayDate[0];
  }
}
