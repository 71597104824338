import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingOcr: false,
  dataOcr: [],
};

export const ocrLoading = createAction('OCR_LOADING');
export const ocrSuccess = createAction('OCR_SUCCESS');
export const ocrError = createAction('OCR_ERROR');
export const ocrReset = createAction('OCR_RESET');

export default createReducer(INITIAL_STATE, {
  [ocrLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingOcr: true,
      error: false,
    };
  },
  [ocrSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingOcr: false,
      dataOcr: [payload],
      error: false,
    };
  },
  [ocrError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingOcr: false,
      error: true,
      errorMenssagem: payload?.mensagem,
    };
  },
  [ocrReset.type]: (state, { payload }) => {
    return {
      ...state,
      loadingOcr: false,
      dataOcr: [],
    };
  },
});
