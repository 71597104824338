import api from '../../utils/services/api';

import {
  bankSlipDetailActionError,
  bankSlipDetailActionLoading,
  bankSlipDetailActionSuccess,
} from '../ducks/bankSlipDetail';

export const bankSlipDetailFetchAction = (ID) => {
  return async (dispatch) => {
    dispatch(bankSlipDetailActionLoading());
    try {
      const response = await api.get(`boleto/detalhes/boleto?ID=${ID}`);
      dispatch(bankSlipDetailActionSuccess(response));
    } catch (error) {
      dispatch(bankSlipDetailActionError(error?.response?.data));
    }
  };
};
