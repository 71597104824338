import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      fill="none"
      viewBox="0 0 18 18"
      style={{ paddingRight: props.paddingRight }}
    >
      <path
        fill={props.color ? props.color : '#262424'}
        d="M5.775 12.225a.711.711 0 00.525.206.711.711 0 00.525-.206L9 10.05l2.194 2.194c.137.137.31.203.516.196a.731.731 0 00.515-.215.711.711 0 00.206-.525.711.711 0 00-.206-.525L10.05 9l2.194-2.194a.668.668 0 00.196-.516.731.731 0 00-.215-.515.711.711 0 00-.525-.206.711.711 0 00-.525.206L9 7.95 6.806 5.756a.67.67 0 00-.515-.197.735.735 0 00-.516.216.711.711 0 00-.206.525c0 .213.069.388.206.525L7.95 9l-2.194 2.194a.667.667 0 00-.196.515.732.732 0 00.215.516zM9 16.5a7.3 7.3 0 01-2.925-.591 7.569 7.569 0 01-2.381-1.603 7.568 7.568 0 01-1.603-2.381A7.3 7.3 0 011.5 9a7.3 7.3 0 01.591-2.925 7.569 7.569 0 011.603-2.381A7.58 7.58 0 016.075 2.09 7.307 7.307 0 019 1.5c1.037 0 2.012.197 2.925.59a7.579 7.579 0 012.381 1.604 7.569 7.569 0 011.603 2.381A7.3 7.3 0 0116.5 9a7.3 7.3 0 01-.591 2.925 7.569 7.569 0 01-1.603 2.381 7.569 7.569 0 01-2.381 1.603A7.3 7.3 0 019 16.5zM9 15c1.662 0 3.078-.584 4.247-1.753S15 10.662 15 9s-.584-3.078-1.753-4.247S10.662 3 9 3s-3.078.584-4.247 1.753S3 7.338 3 9s.584 3.078 1.753 4.247S7.338 15 9 15z"
      ></path>
    </svg>
  );
}

export default Icon;
