export const tipos_signatarios = [
  {
    ID: '1',
    DESCRICAO: 'NÃO ASSINA',
  },
  {
    ID: '2',
    DESCRICAO: 'ASSINA SOZINHO',
  },
  {
    ID: '3',
    DESCRICAO: 'ASSINA EM CONJUNTO',
  },
];

export const pagesOptions = [
  { ID: 10, NOME: 10 },
  { ID: 25, NOME: 25 },
  { ID: 50, NOME: 50 },
  { ID: 100, NOME: 100 },
];

export const optionsClientsAprovedFunctions = [
  {
    label: 'Bloquear cliente',
    action: 'blockUser',
  },
  {
    label: 'Recuperar senha',
    action: 'recoverPassword',
  },
  {
    label: 'Deslogar usuário',
    action: 'logOutUser',
  },
  {
    label: 'Editar informações',
    action: 'editData',
  },
];

export const optionsClientsAprovedLegalRepresentation = [
  {
    label: 'Editar informações',
    action: 'editData',
  },
  {
    label: 'Inativar',
    action: 'deactivate',
  },
  {
    label: 'Enviar imagens',
    action: 'sendImages',
  },
  {
    label: 'Enviar documentos',
    action: 'sendDocuments',
  },
];

export const optionsClientsAprovedActiveComite = [
  {
    label: 'Editar informações',
    action: 'editData',
  },
  {
    label: 'Inativar',
    action: 'deactivate',
  },
  {
    label: 'Adicionar ao comite',
    action: 'comite',
  },
];

export const brasilStateOptions = [
  { ID: 'AC', NOME: 'AC' },
  { ID: 'AL', NOME: 'AL' },
  { ID: 'AP', NOME: 'AP' },
  { ID: 'AM', NOME: 'AM' },
  { ID: 'BA', NOME: 'BA' },
  { ID: 'CE', NOME: 'CE' },
  { ID: 'DF', NOME: 'DF' },
  { ID: 'ES', NOME: 'ES' },
  { ID: 'GO', NOME: 'GO' },
  { ID: 'MA', NOME: 'MA' },
  { ID: 'MT', NOME: 'MT' },
  { ID: 'MS', NOME: 'MS' },
  { ID: 'MG', NOME: 'MG' },
  { ID: 'PA', NOME: 'PA' },
  { ID: 'PB', NOME: 'PB' },
  { ID: 'PR', NOME: 'PR' },
  { ID: 'PE', NOME: 'PE' },
  { ID: 'PI', NOME: 'PI' },
  { ID: 'RJ', NOME: 'RJ' },
  { ID: 'RN', NOME: 'RN' },
  { ID: 'RS', NOME: 'RS' },
  { ID: 'RG', NOME: 'RG' },
  { ID: 'RR', NOME: 'RR' },
  { ID: 'SC', NOME: 'SC' },
  { ID: 'SP', NOME: 'SP' },
  { ID: 'SE', NOME: 'SE' },
  { ID: 'TO', NOME: 'TO' },
];

export const arrayTipoTransacao = [
  { ID: 0, NOME: 'TODAS' },
  { ID: 1, NOME: 'DÉBITO' },
  { ID: 2, NOME: 'CRÉDITO' },
];

export const arrayStatus = [
  { ID: 0, NOME: 'Todos' },
  { ID: 1, NOME: 'Aguardando' },
  { ID: 2, NOME: 'Aprovado' },
  { ID: 3, NOME: 'Cancelado' },
  { ID: 4, NOME: 'Vencido' },
];

export const optionsTypeFaceOcr = [
  { nome: 'Todos', id: 0 },
  { nome: 'Face Match', id: 'FaceMatch' },
  ,
  { nome: 'OCR', id: 'OCR' },
];
