import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  blockedUsersData: [],
  paginationBlockedUsers: { totalResult: 0 },
  errorMessageBlockedUsers: '',
  loadingBlockedUsers: false,
  errorBlockedUsers: false,
};

export const blockedUsersActionLoading = createAction('BLOCKED_USERS_ACTION_LOADING');
export const blockedUsersActionSuccess = createAction('BLOCKED_USERS_ACTION_SUCCESS');
export const blockedUsersActionError = createAction('BLOCKED_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [blockedUsersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingBlockedUsers: true,
      errorBlockedUsers: false,
    };
  },
  [blockedUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockedUsers: false,
      errorBlockedUsers: false,
      blockedUsersData: payload.data,
      paginationBlockedUsers: payload.pagination,
    };
  },
  [blockedUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockedUsers: false,
      errorBlockedUsers: true,
      errorMessageBlockedUsers: payload?.mensagem,
    };
  },
});
