import React from 'react';
import PropTypes from 'prop-types';
import TabsMaterial from '@material-ui/core/Tabs';
import * as Styled from './Tabs.styles';
import { useSelector } from 'react-redux';
export default function Tabs({ labels, setTabIndex, tabIndex, width, margin, variant, account }) {
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { paginationPendingScrows } = useSelector((state) => state.pendingScrows);
  const { userAccounts } = useSelector((state) => state);

  const renderTab = (label) => {
    if (label === 'Pendentes') {
      return (
        <Styled.UserNotificationContainer>
          <Styled.Badge
            color="default"
            badgeContent={account ? userAccounts.pagination.totalResult : paginationPendingScrows?.totalResult}
          >
            {label}
          </Styled.Badge>
        </Styled.UserNotificationContainer>
      );
    }
    return label;
  };

  return (
    <div>
      <Styled.AppBar position="static" width={width} margin={margin}>
        <TabsMaterial value={tabIndex} onChange={handleChange} variant={variant}>
          {labels.map((label, index) => {
            return <Styled.TabUsers label={renderTab(label)} key={index} />;
          })}
        </TabsMaterial>
      </Styled.AppBar>
    </div>
  );
}

Tabs.propTypes = {
  labels: PropTypes.array,
  setTabIndex: PropTypes.func,
  tabIndex: PropTypes.number,
  width: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
};
