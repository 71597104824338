import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '26'}
      height={props.height ? props.height : '24'}
      fill="none"
      viewBox="0 0 26 24"
      style={{ paddingRight: props.paddingRight }}
    >
      <path
        fill={props.color ? props.color : '#2DC071'}
        fillRule="evenodd"
        d="M24.63 2.926a.888.888 0 010 1.259L12.184 16.629a.889.889 0 01-1.259 0l-5.333-5.333a.889.889 0 111.259-1.258l4.704 4.705L23.37 2.926a.888.888 0 011.259 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill={props.color ? props.color : '#2DC071'}
        fillRule="evenodd"
        d="M11.556 2.667a9.778 9.778 0 109.777 9.777.889.889 0 111.778 0 11.555 11.555 0 11-5.778-10.008.89.89 0 11-.888 1.537 9.728 9.728 0 00-4.89-1.306z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
