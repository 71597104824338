import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  detailAccountsData: [],
  detailAccountDocumentsData: [],
  detailAccountBalanceData: [],
  approvedAccountsData: [],
  deniedAccountsData: [],
  blockAccountsData: [],
  unlockAccountsData: [],
  pagination: { totalResult: 0, totalPages: 0, page: 1 },
  loadingApprovedAccounts: true,
};

export const detailAccountsActionLoading = createAction('DETAIL_ACCOUNTS_ACTION_LOADING');
export const detailAccountsActionSuccess = createAction('DETAIL_ACCOUNTS_ACTION_SUCCESS');
export const detailAccountsActionError = createAction('DETAIL_ACCOUNTS_ACTION_ERROR');
export const approvedAccountsPendingActionLoading = createAction('APPROVED_ACCOUNTS_PENDING_ACTION_LOADING');
export const approvedAccountsPendingActionSuccess = createAction('APPROVED_ACCOUNTS_PENDING_ACTION_SUCCESS');
export const approvedAccountsPendingActionError = createAction('APPROVED_ACCOUNTS_PENDING_ACTION_ERROR');
export const deniedAccountsActionLoading = createAction('DENIED_ACCOUNTS_ACTION_LOADING');
export const deniedAccountsActionSuccess = createAction('DENIED_ACCOUNTS_ACTION_SUCCESS');
export const deniedAccountsActionError = createAction('DENIED_ACCOUNTS_ACTION_ERROR');
export const blockAccountActionLoading = createAction('BLOCK_ACCOUNTS_ACTION_LOADING');
export const blockAccountActionSuccess = createAction('BLOCK_ACCOUNTS_ACTION_SUCCESS');
export const blockAccountActionError = createAction('BLOCK_ACCOUNTS_ACTION_ERROR');
export const unlockAccountActionLoading = createAction('UNLOCK_ACCOUNTS_ACTION_LOADING');
export const unlockAccountActionSuccess = createAction('UNLOCK_ACCOUNTS_ACTION_SUCCESS');
export const unlockAccountActionError = createAction('UNLOCK_ACCOUNTS_ACTION_ERROR');
export const detailAccountsDocumentLoading = createAction('DETAIL_ACCOUNTS_DOCUMENT_ACTION_LOADING');
export const detailAccountsDocumentSuccess = createAction('DETAIL_ACCOUNTS_DOCUMENT_ACTION_SUCCESS');
export const detailAccountsDocumentError = createAction('DETAIL_ACCOUNTS_DOCUMENT_ACTION_ERROR');
export const balanceAccountsActionLoading = createAction('DETAIL_ACCOUNTS_BALANCE_ACTION_LOADING');
export const balanceAccountsActionSuccess = createAction('DETAIL_ACCOUNTS_BALANCE_ACTION_SUCCESS');
export const balanceAccountsActionError = createAction('DETAIL_ACCOUNTS_BALANCE_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [detailAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailAccounts: true,
      errorDetailAccounts: false,
      detailAccountsData: [],
      pagination: {},
    };
  },
  [detailAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDetailAccounts: false,
      errorDetailAccounts: false,
      detailAccountsData: payload,
      pagination: payload?.pagination,
    };
  },
  [detailAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingDetailAccounts: false,
      errorDetailAccounts: true,
      // errorMessagePendingAccounts: payload.mensagem,
      detailAccountsData: [],
      pagination: {},
    };
  },
  [approvedAccountsPendingActionLoading.type]: (state) => {
    return {
      ...state,
      loadingApprovedAccounts: true,
      errorApprovedAccounts: false,
      approvedAccountsData: [],
    };
  },
  [approvedAccountsPendingActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedAccounts: false,
      errorApprovedAccounts: false,
      approvedAccountsData: payload,
    };
  },
  [approvedAccountsPendingActionError.type]: (state) => {
    return {
      ...state,
      loadingApprovedAccounts: false,
      errorApprovedAccounts: true,
      // errorMessagePendingAccounts: payload?.mensagem,
      approvedAccountsData: [],
    };
  },
  [deniedAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDeniedAccounts: true,
      errorDeniedAccounts: false,
      deniedAccountsData: [],
    };
  },
  [deniedAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedAccounts: false,
      errorDeniedAccounts: false,
      deniedAccountsData: payload,
    };
  },
  [deniedAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingDeniedAccounts: false,
      errorDeniedAccounts: true,
    };
  },
  [blockAccountActionLoading.type]: (state) => {
    return {
      ...state,
      loadingBlockAccounts: true,
      errorBlockAccounts: false,
      blockAccountsData: [],
      pagination: {},
    };
  },
  [blockAccountActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockAccounts: false,
      errorBlockAccounts: false,
      blockAccountsData: payload,
      pagination: payload.pagination,
    };
  },
  [blockAccountActionError.type]: (state) => {
    return {
      ...state,
      loadingBlockAccounts: false,
      errorBlockAccounts: true,
      blockAccountsData: [],
      pagination: {},
    };
  },
  [unlockAccountActionLoading.type]: (state) => {
    return {
      ...state,
      loadingUnlockAccounts: true,
      errorUnlockAccounts: false,
      unlockAccountsData: [],
      pagination: {},
    };
  },
  [unlockAccountActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUnlockAccounts: false,
      errorUnlockAccounts: false,
      unlockAccountsData: payload,
      pagination: payload.pagination,
    };
  },
  [unlockAccountActionError.type]: (state) => {
    return {
      ...state,
      loadingUnlockAccounts: false,
      errorUnlockAccounts: true,
      unlockAccountsData: [],
      pagination: {},
    };
  },
  [detailAccountsDocumentLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailAccountDocuments: true,
      errorDetailAccountDocuments: false,
      detailAccountDocumentsData: [],
      paginationDocuments: {},
    };
  },
  [detailAccountsDocumentSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDetailAccountDocuments: false,
      errorDetailAccountDocuments: false,
      detailAccountDocumentsData: payload,
      paginationDocuments: payload.pagination,
    };
  },
  [detailAccountsDocumentError.type]: (state) => {
    return {
      ...state,
      loadingDetailAccountDocuments: false,
      errorDetailAccountDocuments: true,
      detailAccountDocumentsData: [],
      paginationDocuments: {},
    };
  },
  [balanceAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailAccountBalance: true,
      errorDetailAccountBalance: false,
      detailAccountBalanceData: [],
      paginationBalance: {},
    };
  },
  [balanceAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDetailAccountBalance: false,
      errorDetailAccountBalance: false,
      detailAccountBalanceData: payload,
      paginationBalance: payload.pagination,
    };
  },
  [balanceAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingDetailAccountBalance: false,
      errorDetailAccountBalance: true,
      detailAccountBalanceData: [],
      paginationBalance: {},
    };
  },
});
