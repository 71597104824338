import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="none" viewBox="0 0 19 18">
      <path
        fill="#262424"
        d="M2 0a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-2.28A2 2 0 0019 12V6a2 2 0 00-1-1.72V2a2 2 0 00-2-2H2zm0 2h14v2h-6a2 2 0 00-2 2v6a2 2 0 002 2h6v2H2V2zm8 4h7v6h-7V6zm3 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
      ></path>
    </svg>
  );
}

export default Icon;
