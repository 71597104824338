import api from '../../utils/services/api';

import {
  perfisActionError,
  perfisActionLoading,
  perfisActionSuccess,
  deletePerfisActionError,
  deletePerfisActionLoading,
  deletePerfisActionSuccess,
  editPerfisActionError,
  editPerfisActionLoading,
  editPerfisActionSuccess,
  createPerfisActionError,
  createPerfisActionLoading,
  createPerfisActionSuccess,
  listProfilesUsersActionsLoading,
  listProfilesUsersActionsError,
  listProfilesUsersActionsSuccess,
} from '../ducks/perfis';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { cobansFetchAction } from './cobansActions';

export const perfisFetchAction = () => {
  return async (dispatch) => {
    dispatch(perfisActionLoading());
    try {
      const response = await api.get(`coban/listar/perfil`);
      dispatch(perfisActionSuccess(response.data));
    } catch (error) {
      dispatch(perfisActionError(error?.response?.data));
    }
  };
};

export const deletePerfisFetchAction = (name, id, callback) => {
  const params = { ID: id, NOME: name, STATUS: true };
  return async (dispatch) => {
    const refreshTable = () => {
      callback();
      dispatch(cobansFetchAction());
    };
    dispatch(deletePerfisActionLoading());
    dispatch(
      showAlert({
        title: 'Loading',
        text: '',
        icon: '',
        loadingSpinner: true,
      }),
    );
    try {
      const response = await api.post(`coban/perfil/atualiza`, params);
      dispatch(hideAlert());
      dispatch(deletePerfisActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Perfil excluído com sucesso!',
          icon: 'success',
          func: refreshTable,
        }),
      );
    } catch (error) {
      dispatch(deletePerfisActionError(error?.response?.data));
      dispatch(hideAlert());
      dispatch(
        showAlert({
          title: 'Erro',
          text: error?.response?.data.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const editPerfisFetchAction = (name, id, callback) => {
  const params = { ID: id, NOME: name };
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
    };
    dispatch(editPerfisActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/perfil/atualiza`, params);
      dispatch(editPerfisActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Perfil editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editPerfisActionError(error?.response?.data));
    }
  };
};

export const createPerfisFetchAction = (name, callback) => {
  const params = { NOME: name };
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
    };
    dispatch(createPerfisActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/perfil/inserir`, params);
      dispatch(createPerfisActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Perfil criado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(createPerfisActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const profilesUsersFetchAction = () => {
  return async (dispatch) => {
    dispatch(listProfilesUsersActionsLoading());
    try {
      const response = await api.get(`coban/listar/perfilUsuario`);
      dispatch(listProfilesUsersActionsSuccess(response?.data));
    } catch (error) {
      dispatch(listProfilesUsersActionsError(error?.response?.data));
    }
  };
};
