import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

export const denyScrowActionLoading = createAction('DENY_SCROW_ACTION_LOADING');
export const denyScrowActionSuccess = createAction('DENY_SCROW_ACTION_SUCCESS');
export const denyScrowActionError = createAction('DENY_SCROW_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [denyScrowActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDenyScrow: true,
      errorDenyScrow: false,
    };
  },
  [denyScrowActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDenyScrow: false,
      errorDenyScrow: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [denyScrowActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDenyScrow: false,
      errorDenyScrow: true,
    };
  },
});
