import { memo, useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import DateInput from '../../../../components/DateInput';
import Filter from '../../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../../components/Filter/Filter.styles';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import SelectOptions from '../../../../components/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import convertObjToArrayBoletos from '../../ConvertObjToArrayBoletos';
import { reversedBillsFetchAction } from '../../../../store/fetchActions/reversedBillsActions';
import { bankSlipDetailFetchAction } from '../../../../store/fetchActions/bankSlipDetailActions';
import formatCPF from '../../../../utils/functions/formatCPF';
import formatMoney from '../../../../utils/functions/formatMoneyBRL';
import { translateMessage } from '../../../../utils/functions/translateMessage';
import Information from '../../../../components/Information/Information';
import useCallFilter from '../../../../utils/hooks/useCallFilter';
import formatDateHora from '../../../../utils/functions/formatDateHora';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../../../utils/constant';
import Loading from '../../../../components/Loading';

function BoletosEstornados() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: '10',
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const { paginationReversedBills, loadingReversedBills, messageReversedBills, dataReversedBills, errorReversedBills } =
    useSelector((state) => state.reversedBills);
  const { dataBankSlipDetail, loadingBankSlipDetail, messageBankSlipDetail, errorBankSlipDetail } = useSelector(
    (state) => state.bankSlipDetail,
  );

  const dataBankSlipDetailArray = convertObjToArrayBoletos(dataBankSlipDetail);

  useCallFilter(filterData, setFilter, reversedBillsFetchAction, 0, 0);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  useEffect(() => {
    dispatch(reversedBillsFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'cpf/cnpj remetente',
      name: 'CPF/CNPJ REMETENTE',
      align: 'left',
    },
    {
      id: 'nome remetente',
      name: 'NOME REMETENTE',
      align: 'left',
    },
    {
      id: 'data da transacao',
      name: 'DATA DA TRANSAÇÃO',
      align: 'center',
    },
    {
      id: 'descricao',
      name: 'DESCRIÇÃO',
      align: 'left',
    },
    {
      id: 'valor',
      name: 'VALOR',
      align: 'right',
    },
  ];

  const body = dataReversedBills?.map((reversedBills) => {
    return {
      data: [
        {
          info: formatCPF(reversedBills.CPFCNPJ),
          align: 'left',
        },
        {
          info: reversedBills.nome,
          align: 'left',
        },
        {
          info: formatDateHora(reversedBills?.DATA ?? '----'),
          align: 'center',
        },
        {
          info: reversedBills.DESCRICAO,
          align: 'left',
        },
        {
          info: `R$ ${formatMoney(reversedBills.VALOR)}`,
          align: 'right',
        },
      ],
      onClick: () => inspectUser(reversedBills.ID),
    };
  });

  const inspectUser = (id) => {
    dispatch(bankSlipDetailFetchAction(id));
    setIsModalInfoOpen(true);
  };

  const closeModalInfo = () => setIsModalInfoOpen(false);

  return (
    <>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'37.5vw'} control={control} />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
              margin={'0 1.389vw 0 0'}
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationReversedBills?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>
      {loadingReversedBills ? (
        <Loading />
      ) : (
        <Table
          loading={loadingReversedBills}
          error={errorReversedBills}
          hasPagination={true}
          filter={filter}
          pagination={paginationReversedBills}
          setFilter={setFilter}
          errorMessage={messageReversedBills}
          filterTable={filterTable}
          header={header}
          body={body}
          limit={filter.LIMIT}
        />
      )}

      {isModalInfoOpen && (
        <Modal open={isModalInfoOpen} close={closeModalInfo} title="Dados do Boleto" width={'45.889vw'}>
          {loadingBankSlipDetail ? (
            <Loading />
          ) : errorBankSlipDetail ? (
            <h1>{translateMessage(messageBankSlipDetail)}</h1>
          ) : (
            <Information data={dataBankSlipDetailArray} />
          )}
        </Modal>
      )}
    </>
  );
}

export default memo(BoletosEstornados);
