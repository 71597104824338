import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  listDocumentRepresentative: [],
};

export const approveScrowActionLoading = createAction('APPROVE_SCROW_ACTION_LOADING');
export const approveScrowActionSuccess = createAction('APPROVE_SCROW_ACTION_SUCCESS');
export const approveScrowActionError = createAction('APPROVE_SCROW_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [approveScrowActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveScrow: true,
      errorApproveScrow: false,
    };
  },
  [approveScrowActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApproveScrow: false,
      errorApproveScrow: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [approveScrowActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveScrow: false,
      errorApproveScrow: true,
    };
  },
});
