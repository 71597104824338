import api from '../../utils/services/api';

import {
  pendingAccountsActionError,
  pendingAccountsActionLoading,
  pendingAccountsActionSuccess,
  approvedAccountsActionError,
  approvedAccountsActionLoading,
  approvedAccountsActionSuccess,
  deniedAccountsActionError,
  deniedAccountsActionLoading,
  deniedAccountsActionSuccess,
  blockedAccountsActionError,
  blockedAccountsActionLoading,
  blockedAccountsActionSuccess,
  closedAccountsActionError,
  closedAccountsActionLoading,
  closedAccountsActionSuccess,
} from '../ducks/userAccounts';

export const pendingAccountsFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  filtro.DATA_INICIAL = filtro.DATA_INICIAL != undefined ? filtro.DATA_INICIAL : '';
  filtro.DATA_FINAL = filtro.DATA_FINAL != undefined ? filtro.DATA_FINAL : '';
  filtro.SEARCH = filtro.SEARCH != undefined ? filtro.SEARCH : '';

  return async (dispatch) => {
    dispatch(pendingAccountsActionLoading());
    try {
      const response = await api.get(
        `/conta-bacen/aguardando?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro?.SEARCH}&DATA_INICIAL=${filtro?.DATA_INICIAL}&DATA_FINAL=${filtro?.DATA_FINAL}`,
      );
      dispatch(pendingAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(pendingAccountsActionError(error?.response?.data));
    }
  };
};

export const accountsApprovedFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  filtro.DATA_INICIAL = filtro.DATA_INICIAL != undefined ? filtro.DATA_INICIAL : '';
  filtro.DATA_FINAL = filtro.DATA_FINAL != undefined ? filtro.DATA_FINAL : '';
  filtro.SEARCH = filtro.SEARCH != undefined ? filtro.SEARCH : '';

  return async (dispatch) => {
    dispatch(approvedAccountsActionLoading());
    try {
      const response = await api.get(
        `/conta-bacen/ativas?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro?.SEARCH}&DATA_INICIAL=${filtro?.DATA_INICIAL}&DATA_FINAL=${filtro?.DATA_FINAL}`,
      );
      dispatch(approvedAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(approvedAccountsActionError(error?.response?.data));
    }
  };
};

export const accountsDeniedFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  filtro.DATA_INICIAL = filtro.DATA_INICIAL != undefined ? filtro.DATA_INICIAL : '';
  filtro.DATA_FINAL = filtro.DATA_FINAL != undefined ? filtro.DATA_FINAL : '';
  filtro.SEARCH = filtro.SEARCH != undefined ? filtro.SEARCH : '';

  return async (dispatch) => {
    dispatch(deniedAccountsActionLoading());
    try {
      const response = await api.get(
        `/conta-bacen/negadas?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro?.SEARCH}&DATA_INICIAL=${filtro?.DATA_INICIAL}&DATA_FINAL=${filtro?.DATA_FINAL}`,
      );
      dispatch(deniedAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(deniedAccountsActionError(error?.response?.data));
    }
  };
};

export const blockedAccountsFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  filtro.DATA_INICIAL = filtro.DATA_INICIAL != undefined ? filtro.DATA_INICIAL : '';
  filtro.DATA_FINAL = filtro.DATA_FINAL != undefined ? filtro.DATA_FINAL : '';
  filtro.SEARCH = filtro.SEARCH != undefined ? filtro.SEARCH : '';

  return async (dispatch) => {
    dispatch(blockedAccountsActionLoading());
    try {
      const response = await api.get(
        `/conta-bacen/bloqueadas?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro?.SEARCH}&DATA_INICIAL=${filtro?.DATA_INICIAL}&DATA_FINAL=${filtro?.DATA_FINAL}`,
      );
      dispatch(blockedAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(blockedAccountsActionError(error?.response?.data));
    }
  };
};

export const closedAccountsFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  filtro.DATA_INICIAL = filtro.DATA_INICIAL != undefined ? filtro.DATA_INICIAL : '';
  filtro.DATA_FINAL = filtro.DATA_FINAL != undefined ? filtro.DATA_FINAL : '';
  filtro.SEARCH = filtro.SEARCH != undefined ? filtro.SEARCH : '';

  return async (dispatch) => {
    dispatch(closedAccountsActionLoading());
    try {
      const response = await api.get(
        `/conta-bacen/encerradas?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro?.SEARCH}&DATA_INICIAL=${filtro?.DATA_INICIAL}&DATA_FINAL=${filtro?.DATA_FINAL}`,
      );
      dispatch(closedAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(closedAccountsActionError(error?.response?.data));
    }
  };
};
