import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  scrowsData: [],
  errorMessageScrow: '',
  errorScrow: false,
  loadingScrow: true,
  scrowsInfo: [],
};

export const scrowActionLoading = createAction('SCROW_ACTION_LOADING');
export const scrowActionSuccess = createAction('SCROW_ACTION_SUCCESS');
export const scrowActionError = createAction('SCROW_ACTION_ERROR');
export const scrowActionInfo = createAction('SCROW_ACTION_INFO');

export default createReducer(INITIAL_STATE, {
  [scrowActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingScrow: true,
      errorScrow: false,
      scrowsData: [],
    };
  },
  [scrowActionInfo.type]: (state, { payload }) => {
    return {
      ...state,
      loadingScrow: false,
      errorScrow: false,
      scrowsInfo: payload,
    };
  },
  [scrowActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingScrow: false,
      errorScrow: false,
      scrowsData: payload,
    };
  },
  [scrowActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingScrow: false,
      errorScrow: true,
      errorMessageScrow: payload?.mensagem,
      scrowsData: [],
    };
  },
});
