// import { data } from 'cypress/types/jquery';
import api from '../../utils/services/api';

import {
  digitalServicesActionError,
  digitalServicesActionLoading,
  digitalServicesActionSuccess,
} from '../ducks/digitalServices';

export const digitalServicesFetchAction = (
  data = {
    limit: 10,
    page: 1,
  },
) => {
  return async (dispatch) => {
    dispatch(digitalServicesActionLoading());
    try {
      const response = await api.post(`/recarga/listar_recargas`, data);
      dispatch(digitalServicesActionSuccess(response.data));
    } catch (error) {
      dispatch(digitalServicesActionError(error?.response?.data));
    }
  };
};
