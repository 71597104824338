import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  pendingScrowsData: [],
  paginationPendingScrows: { totalResult: 0 },
  errorMessagePendingScrows: '',
};

export const pendingScrowsActionLoading = createAction('PENDING_SCROWS_ACTION_LOADING');
export const pendingScrowsActionSuccess = createAction('PENDING_SCROWS_ACTION_SUCCESS');
export const pendingScrowsActionError = createAction('PENDING_SCROWS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pendingScrowsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPendingScrows: true,
      errorPendingScrows: false,
      pendingScrowsData: [],
      paginationPendingScrows: {},
    };
  },
  [pendingScrowsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPendingScrows: false,
      errorPendingScrows: false,
      pendingScrowsData: payload.data,
      paginationPendingScrows: payload.pagination,
    };
  },
  [pendingScrowsActionError.type]: (state) => {
    return {
      ...state,
      loadingPendingScrows: false,
      errorPendingScrows: true,
      pendingScrowsData: [],
      paginationPendingScrows: {},
    };
  },
});
