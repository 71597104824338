import { memo, useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import { pendingUsersFetchAction } from '../../../store/fetchActions/pendingUsersActions';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter/Filter';
import { userFetchAction } from '../../../store/fetchActions/usersActions';
import SelectOptions from '../../../components/SelectOptions';
import { ImSearch } from 'react-icons/im';
import Button from '../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import { CircularProgress } from '@material-ui/core';
import { LoadingContainer } from '../styles/GerenciarClientes.styles';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { cobansFetchAction } from '../../../store/fetchActions/cobansActions';
import {
  detailAccountsFetchAction,
  detailAccountsDocumentFetchAction,
} from '../../../store/fetchActions/accountActions';
import ModalPendentesPj from '../components/Modals/ClientesPendentesPj';
import ModalPendentesPf from '../components/Modals/ClientesPendentesPf';
import { useTheme } from 'styled-components';
import DatePicker from '../../../components/DateInput';
import TextField from '../../../components/TextField';
import moment from 'moment';
import { pagesOptions } from '../../../utils/constant/index';
import ModalDeniedClient from '../components/Modals/DeniedClient';

function UsuariosPendentes({ value, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [isModalDenyOpen, setIsModalDenyOpen] = useState(false);
  const [filterData, setFilterData] = useState('');
  const [userID, setUserID] = useState();
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });

  const { handleSubmit, control, reset } = useForm({});

  useCallFilter(filterData, setFilter, pendingUsersFetchAction, value, index);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  const { usersData, loadingUser } = useSelector((state) => state.user);

  useEffect(() => {
    let idConta = !loadingUser && usersData?.CONTAS?.length > 0 ? usersData?.CONTAS[0]?.ID_CONTA : '';
    idConta &&
      (dispatch(detailAccountsFetchAction(idConta)), dispatch(detailAccountsDocumentFetchAction(usersData.CPFCNPJ)));
  }, [userID, loadingUser, usersData]);

  useEffect(() => {
    dispatch(pendingUsersFetchAction(filter));
  }, [filter.PAGE]);

  const { pendingUsersData, pagination, loadingPendingUsers, errorPendingUsers, errorMessagePendingUsers } =
    useSelector((state) => state.pendingUsers);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'center',
    },
    {
      id: 'CPFCNPJ',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
  ];

  const body = pendingUsersData?.map((user) => {
    return {
      data: [
        {
          info: user.DATA_INSERT,
          align: 'center',
        },
        {
          info: user.CPFCNPJ?.length === 11 ? formatCPF(user.CPFCNPJ) : formatCNPJ(user.CPFCNPJ),
          align: 'left',
        },
        {
          info: user.NOME,
          align: 'left',
        },
      ],
      onClick: () => inspectUser(user.CPFCNPJ),
    };
  });

  function inspectUser(id) {
    setUserID(id);
    dispatch(userFetchAction(id, 'willBeLoaded'));
    dispatch(cobansFetchAction());
    setIsModalInfoOpen(true);
  }

  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'38.042vw'} control={control} />
            <DatePicker
              label="Data Inicial"
              value={dateInicial}
              name={'DATA_INICIAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDate('')
              }
            />
            <DatePicker
              label="Data Final"
              value={dateFinal}
              name={'DATA_FINAL'}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDate('')
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5 style={{ padding: '0 0.4vw 0 0' }}>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {pagination?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingPendingUsers ? (
        <LoadingContainer>
          <CircularProgress size={'5vw'} color="inherit" />
        </LoadingContainer>
      ) : (
        <Table
          error={errorPendingUsers}
          hasPagination={true}
          filter={filter}
          pagination={pagination}
          setFilter={setFilter}
          errorMessage={errorMessagePendingUsers}
          filterTable={filterTable}
          header={header}
          body={body}
          limit={filter.LIMIT}
        />
      )}

      {isModalInfoOpen &&
        (userID?.length === 14 ? (
          <ModalPendentesPj
            open={isModalInfoOpen}
            close={() => setIsModalInfoOpen(false)}
            info={usersData}
            deny={() => setIsModalDenyOpen(true)}
          />
        ) : (
          <ModalPendentesPf
            open={isModalInfoOpen}
            close={() => setIsModalInfoOpen(false)}
            deny={() => setIsModalDenyOpen(true)}
            userID={userID}
          />
        ))}

      {isModalDenyOpen && (
        <ModalDeniedClient open={isModalDenyOpen} close={() => setIsModalDenyOpen(false)} info={userID} />
      )}
    </div>
  );
}

export default memo(UsuariosPendentes);
