import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import Modal from '../../../../components/Modal/Modal';
import { ButtonContainer, SmallerModal } from '../../styles/GerenciarContas.styles';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button/Button';
import { blockAccountFetchAction, unlockAccountFetchAction } from '../../../../store/fetchActions/accountActions';
import { useTheme } from 'styled-components';

const BlockUser = ({ openModal, closeModal, blockUser, idConta }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const blockOrUnlockUser = ({ justificativa }) => {
    let id = idConta;
    let param = { OBSERVACOES: justificativa };
    if (blockUser) {
      dispatch(blockAccountFetchAction(id, param, closeModal));
    } else {
      dispatch(unlockAccountFetchAction(id, param, closeModal));
    }

    reset({
      justificativa: '',
    });
  };

  const { handleSubmit: handleBlockUser, control: controlBlockUser, reset } = useForm({});

  return (
    <Modal open={openModal} close={closeModal}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>{blockUser ? 'Bloquear conta' : 'Desbloquear conta'}</h3>
        <h5>Tem certeza que deseja {blockUser ? 'bloquear' : 'desbloquear'} a conta?</h5>
        <h4>Justificativa</h4>
        <form onSubmit={handleBlockUser(blockOrUnlockUser)}>
          <TextField
            variant={'outlined'}
            name={'justificativa'}
            width={'28.342vw'}
            multiline={true}
            minRows={3}
            required={true}
            size="large"
            control={controlBlockUser}
            inputProps={{ maxLength: 100 }}
          />
          <ButtonContainer>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={closeModal}
            >
              <span>Voltar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonNegaded}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={blockUser ? theme.colors.buttonNegadedHover : theme.colors.veryDarkCyan}
              hovercolor={theme.colors.buttonNegadedHoverText}
              mycolor={theme.colors.buttonNegadedText}
              type={'submit'}
            >
              <span>{blockUser ? 'Bloquear' : 'Desbloquear'}</span>
            </Button>
          </ButtonContainer>
        </form>
      </SmallerModal>
    </Modal>
  );
};

export default BlockUser;
