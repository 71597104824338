import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 16 20">
      <path
        fill="#fff"
        d="M2 0a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V6l-6-6H2zm0 2h7v5h5v11H2V2zm2 8v2h8v-2H4zm0 4v2h5v-2H4z"
      ></path>
    </svg>
  );
}

export default Icon;
