import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const Input = styled('input')({
  display: 'none',
});

export const Label = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
`;

export const ContainerUploadFiles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
  align-items: center;

  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    height: 3.2vw;
  }

  svg {
    transform: rotate(30deg);
  }
`;

export const ContainerSendDocumentField = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

export const ContainerButtons = styled.div`
  button:first-child {
    margin-top: 0.5vw;
  }
`;

export const DocumentField = styled(TextField)`
  width: 60%;
  height: 3.2vw !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.veryDarkGrayTransparent};

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    height: 3.2vw;
  }

  .css-1v4ccyo.Mui-disabled {
    height: 3.3vw;
  }
`;
