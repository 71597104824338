import api from '../../utils/services/api';

import { deniedScrowsActionError, deniedScrowsActionLoading, deniedScrowsActionSuccess } from '../ducks/deniedScrows';

export const deniedScrowsFetchAction = (filtro = { limit: 20, page: 1 }) => {
  return async (dispatch) => {
    dispatch(deniedScrowsActionLoading());
    try {
      const response = await api.post(`/admin/scrow/listar/negados`, filtro);
      dispatch(deniedScrowsActionSuccess(response.data));
    } catch (error) {
      dispatch(deniedScrowsActionError(error?.response?.data));
    }
  };
};
