import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import TextField from '../../../../components/TextField';
import { denyScrowFetchAction } from '../../../../store/fetchActions/denyScrowActions';
import { ButtonContainer, SmallerModal } from '../../styles/GerenciarScrows.styles';

const DeniedScrow = ({ open, close, info, infoByCnpj }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm({});

  const submitDenyForm = (data) => {
    const param = { CNPJ_EMPRESA: infoByCnpj?.CPFCNPJ, USUARIOS_SCROWS: info?.REPRESENTANTES };
    dispatch(denyScrowFetchAction(param, close));
  };

  return (
    <Modal open={open} close={close}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>Negar Cliente</h3>
        <h5>Tem certeza que deseja negar o Escrow?</h5>
        <ButtonContainer>
          <Button
            backgroundcolor={theme.colors.buttonBack}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonBackHover}
            hovercolor={theme.colors.buttonBackHoverText}
            mycolor={theme.colors.buttonBackText}
            onClick={() => {
              closeModalDeny();
            }}
          >
            <span>Voltar</span>
          </Button>
          <Button
            backgroundcolor={theme.colors.buttonNegaded}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonNegadedHover}
            hovercolor={theme.colors.buttonNegadedHoverText}
            mycolor={theme.colors.buttonNegadedText}
            type="submit"
            onClick={handleSubmit(submitDenyForm)}
          >
            <span>Negar</span>
          </Button>
        </ButtonContainer>
      </SmallerModal>
    </Modal>
  );
};

export default DeniedScrow;
