import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationPendingTeds: { totalResult: 0 },
  errorMessagePendingTeds: '',
};

export const pendingTedsActionLoading = createAction('PENDING_TED_ACTION_LOADING');
export const pendingTedsActionSuccess = createAction('PENDING_TED_ACTION_SUCCESS');
export const pendingTedsActionError = createAction('PENDING_TED_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pendingTedsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPendingTeds: true,
      errorPendingTeds: false,
      paginationPendingTeds: {},
    };
  },
  [pendingTedsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPendingTeds: false,
      errorPendingTeds: false,
      paginationPendingTeds: payload.data,
    };
  },
  [pendingTedsActionError.type]: (state) => {
    return {
      ...state,
      loadingPendingTeds: false,
      errorPendingTeds: true,
      paginationPendingTeds: {},
    };
  },
});
