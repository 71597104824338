import { Divider } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Popper, Button, Grow } from '@material-ui/core';
import { Input as InputMaterial } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const widthButtonsGroup = '15vw';

export const FirstBlock = styled.div`
  display: flex;
  margin-top: 1.736vw;
  form {
    width: 70.4vw;
    display: flex;
    justify-content: space-between;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20.139vw;
  h4 {
    font-family: Inter;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2.083vw;
    letter-spacing: 1.5px;
    text-align: center;
  }
  h3 {
    font-family: Inter;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2.083vw;
    letter-spacing: 0px;
    text-align: center;
  }
  h5 {
    font-family: Inter;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const InformationContainer = styled.div`
  h3 {
    font-family: Inter;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 600;
    line-height: 1.667vw;
    letter-spacing: 1px;
    text-align: left;
    color: ${(props) => props.theme.colors.darkGrayishBlue};
  }
  .MuiInputBase-root.Mui-disabled {
    background: ${(props) => props.theme.colors.white};
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
`;

export const ButtonContainer = styled(EditButtonContainer)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: flex-end !important;
  justify-self: flex-end;
  margin-top: 2.7vw;
`;

export const Line = styled(Divider)`
  width: 82.444vw;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
  margin-top: 2.222vw;
`;

export const AccountContainer = styled.div`
  display: flex;
  font-family: Inter;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.667vw;
  letter-spacing: 0px;
  text-align: right;

  font-family: Inter;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
  letter-spacing: 0px;
  text-align: right;

  align-items: center;
  justify-content: space-between;
  b {
    margin-left: 0.4vw;
  }
`;

export const ContainerCobanProfile = styled.div`
  margin-top: 1.389vw;

  h4 {
    font-family: Inter;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.667vw;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const SecondBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
`;

export const ThirdBlock = styled.div`
  display: flex;
`;

export const Taxa = styled.div`
  display: flex;
`;

export const TaxaContainer = styled.div`
  width: 40.069vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const GraphicSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    font-family: Inter;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2.083vw;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
`;

export const SecondaryDot = styled.div`
  fill: ${(props) => props.theme.colors.vividRed_2};
`;

export const BalanceInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
  img {
    margin-left: 3.75vw;
    margin-top: 0.1vw;
  }
  h4,
  h3 {
    font-family: Inter;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 1.5px;
    text-align: left;
  }

  h3 {
    font-weight: 600;
  }
`;

export const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageCollum = styled.div`
  width: 33vw;
  height: 33vw;
  display: flex !important;
  justify-content: center !important;
`;

export const ImageButton = styled.button`
  padding: 0 0.5rem;
  height: 2.083vw;
  background-color: ${(props) => props.theme.colors.veryLightGray};
  border-radius: 100px;
  border: none;
  font-family: Inter;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
  letter-spacing: 0.15000000596046448px;
  color: ${(props) => props.theme.colors.black};
  margin: 0.278vw;
  cursor: pointer;
  :hover {
    font-weight: 600;
    color: ${(props) => props.theme.colors.black};
  }
  ${(props) =>
    props.index === props.selectedImage &&
    css`
      background-color: ${(props) => props.theme.colors.veryLightGray};
      color: ${(props) => props.theme.colors.black};
      font-weight: 600;
    `}
`;

export const TitleStyled = styled.h3`
  font-family: Inter;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.667vw;
  letter-spacing: 1px;
  text-align: left;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
  padding-bottom: 0.8rem;
`;

export const ButtonOptions = styled(Button)`
  background-color: ${(props) => props.theme.colors.brown};
  color: ${(props) => props.theme.colors.white};
  width: ${widthButtonsGroup};
  height: 3.333vw;

  &:hover {
    background-color: ${(props) => props.theme.colors.brown};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const PopperButtonsGroup = styled(Popper)`
  z-index: 1;
  width: ${widthButtonsGroup};

  li {
    background: ${(props) => props.theme.colors.veryLightGray};
    margin: 0.3vw;
    border-radius: 4px;
    font-weight: 500;
    height: 2.7vw;

    :hover {
      background: ${(props) => props.theme.colors.brown};
      color: ${(props) => props.theme.colors.white};
      transition: all 0.3s;
      transform: scale(1.1);
    }
  }

  .MuiButtonBase-root.MuiListItem-root {
    padding-left: 1vw;
  }

  .Mui-selected:hover {
    background: ${(props) => props.theme.colors.brown};
  }
`;

export const GrowButtonsGroup = styled(Grow)`
  transform-origin: ${(props) => props.transformOrigin};
`;

export const labelPerfil = styled.div`
  display: flex;

  h3.saldo {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  h4.saldo {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    padding-left: 0.4rem;
    font-size: 15px;
    color: ${(props) => props.theme.colors.veryDark};
  }
  h3.cpf {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }
  h4.cpf {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    padding-left: 0.4rem;
    font-size: 16px;
    color: ${(props) => props.theme.colors.veryDark};
  }
  h4.ponto {
    display: flex;
    font-size: 40px;
    font-weight: bold;
    margin-top: -0.6rem;
    padding: 0 0.4rem;
  }
`;

export const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

export const Input = styled(InputMaterial)`
  font-family: Inter;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.667vw;
  letter-spacing: 0em;
  text-align: left;
  width: ${(props) => props.width};
  height: 3.333vw;

  .MuiInputBase-input {
    background: ${(props) => (props.disabled ? props.theme.colors.white : props.theme.colors.veryLightGray)};
    padding: 0.833vw 0 0.833vw 1.215vw;
    font-family: Inter;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 0em;
    text-align: left;
  }
  .MuiInput-underline {
    &:before {
      background-color: ${(props) => props.theme.colors.veryLightGray};
    }
  }
`;

export const SelectStyled = styled(Select)`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height};
  background: ${(props) => (props.disabled ? props.theme.colors.white : props.theme.colors.veryLightGray)};

  .MuiSelect-select.MuiSelect-select {
    padding: ${(props) => props.padding};
    padding-left: 12px;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.667vw;
  letter-spacing: 0em;
  text-align: left;
`;

export const CenterAlign = styled.div`
  text-align: center;
`;

export const ContainerDoc = styled.div`
  width: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
