import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import verifyEmptyValues from '../../../utils/verifyEmptyValues';

const ConvertObjectToArrayCreditDetails = (detailsArray, idTransaction) => {
  if (idTransaction == 1) {
    const {
      DATA_PAGAMENTO,
      NOME_PAGADOR,
      NOME_RECEBEDOR,
      CPFNPJ_PAGADOR,
      CPFCNPJ_RECEBEDOR,
      VALOR,
      AGENCIA_PAGADOR,
      CONTA_PAGADOR,
      CHAVE,
      AGENCIA_RECEBEDOR,
      CONTA_RECEBEDOR,
      DESCRICAO
    } = detailsArray;

    const arrayPixTransaction = [
      {
        name: 'Data Pagamento',
        value: verifyEmptyValues(DATA_PAGAMENTO),
      },
      {
        name: 'Nome Pagador',
        value: verifyEmptyValues(NOME_PAGADOR),
      },
      {
        name: 'Documento Pagador',
        value: CPFNPJ_PAGADOR?.length > 11 ? formatCNPJ(CPFNPJ_PAGADOR) : formatCPF(CPFNPJ_PAGADOR),
      },
      {
        name: 'Nome Recebedor',
        value: verifyEmptyValues(NOME_RECEBEDOR),
      },
      {
        name: 'Documento Recebedor',
        value: CPFCNPJ_RECEBEDOR?.length > 11 ? formatCNPJ(CPFCNPJ_RECEBEDOR) : formatCPF(CPFCNPJ_RECEBEDOR),
      },
      {
        name: 'Valor',
        value: 'R$ ' + formatMoney(VALOR),
      },
      {
        name: 'Chave',
        value: verifyEmptyValues(CHAVE),
      },
      {
        name: 'Agência Pagador',
        value: verifyEmptyValues(AGENCIA_PAGADOR),
      },
      {
        name: 'Conta Pagador',
        value: verifyEmptyValues(CONTA_PAGADOR),
      },
      {
        name: 'Agência Recebedor',
        value: verifyEmptyValues(AGENCIA_RECEBEDOR),
      },
      {
        name: 'Conta Recebedor',
        value: verifyEmptyValues(CONTA_RECEBEDOR),
      },
      {
        name: 'Descrição',
        value: verifyEmptyValues(DESCRICAO),
      },
    ];

    return arrayPixTransaction;
  }

  if (idTransaction == 2) {
    const {
      DATA_PAGAMENTO,
      NOME_PAGADOR,
      NOME_RECEBEDOR,
      DOC_PAGADOR,
      CPFNPJ_RECEBEDOR,
      VALOR,
      AGENCIA_PAGADOR,
      CODBANCO,
      CONTA_PAGADOR,
    } = detailsArray;

    const arrayPixTransaction = [
      {
        name: 'Data Pagamento',
        value: verifyEmptyValues(DATA_PAGAMENTO),
      },
      {
        name: 'Nome Pagador',
        value: verifyEmptyValues(NOME_PAGADOR),
      },
      {
        name: 'Documento Pagador',
        value: DOC_PAGADOR?.length > 11 ? formatCNPJ(DOC_PAGADOR) : formatCPF(DOC_PAGADOR),
      },
      {
        name: 'Nome Recebedor',
        value: verifyEmptyValues(NOME_RECEBEDOR),
      },
      {
        name: 'Documento Recebedor',
        value: CPFNPJ_RECEBEDOR?.length > 11 ? formatCNPJ(CPFNPJ_RECEBEDOR) : formatCPF(CPFNPJ_RECEBEDOR),
      },
      {
        name: 'Valor',
        value: 'R$ ' + formatMoney(VALOR),
      },
      {
        name: 'Agência Pagador',
        value: verifyEmptyValues(AGENCIA_PAGADOR),
      },
      {
        name: 'Conta Pagador',
        value: verifyEmptyValues(CONTA_PAGADOR),
      },
      {
        name: 'Código Banco',
        value: verifyEmptyValues(CODBANCO),
      },
    ];

    return arrayPixTransaction;
  }
};

export { ConvertObjectToArrayCreditDetails };
