import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1vw" height="1vw" fill="none" viewBox="0 0 21 20">
      <path
        fill="#fff"
        d="M15.325 15.275c.465 0 .86-.163 1.186-.489.326-.326.489-.722.489-1.188 0-.465-.163-.856-.489-1.173a1.638 1.638 0 00-1.186-.475c-.458 0-.848.161-1.169.484-.32.322-.481.714-.481 1.175 0 .46.16.854.481 1.178.321.325.71.488 1.169.488zM15.32 18.6a3.25 3.25 0 001.555-.388 3.205 3.205 0 001.175-1.062 7.62 7.62 0 00-1.325-.563 4.634 4.634 0 00-1.4-.212c-.483 0-.95.07-1.4.212a7.62 7.62 0 00-1.325.563c.3.45.69.804 1.17 1.063.48.258.996.387 1.55.387zM1.5 18c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 010 16.5v-15C0 1.087.147.734.44.44.735.148 1.088 0 1.5 0h15c.413 0 .766.147 1.06.44.293.294.44.647.44 1.06v8.225a3.888 3.888 0 00-.738-.325 6.878 6.878 0 00-.762-.2V1.5h-15v15h7.725c.067.267.142.526.226.78.083.252.183.492.299.72H1.5zm0-2.675V16.5v-15 7.7-.1 6.225zM4 14h5.275c.067-.267.142-.525.225-.775.083-.25.192-.492.325-.725H4V14zm0-4.25h8.6c.233-.117.458-.213.675-.287.217-.075.458-.146.725-.213v-1H4v1.5zM4 5.5h10V4H4v1.5zm11.313 14.475c-1.292 0-2.396-.463-3.313-1.388-.917-.924-1.375-2.02-1.375-3.287 0-1.307.458-2.422 1.375-3.343.916-.921 2.025-1.382 3.325-1.382 1.283 0 2.387.46 3.313 1.382.924.921 1.387 2.036 1.387 3.343 0 1.267-.462 2.363-1.387 3.287-.926.925-2.034 1.388-3.326 1.388z"
      ></path>
    </svg>
  );
}

export default Icon;
